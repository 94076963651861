import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Color } from '../../../common/models/colors'
import Text from '../../Text'
import colors from '../../../styles/colors'

interface IProps {
  className?: string
  label?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  marginBottom?: number
  color?: Color
  defaultValue?: boolean
}

const Switch: React.FC<IProps> = ({ className, label, onChange, marginBottom, color, defaultValue }) => {
  const [value, setValue] = useState<boolean>(defaultValue || false)

  return (
    <SwitchWrapper className={className} marginBottom={marginBottom}>
      {label && (
        <Text marginRight={8} style="label3" color={color ? color : 'neutralWhite'}>
          {label}
        </Text>
      )}
      <SwitchInnerWrapper>
        <StyledSwitch
          defaultChecked={defaultValue}
          onChange={(e) => {
            onChange(e)
            setValue(e.target.checked)
          }}
          type="checkbox"
        ></StyledSwitch>
        <Slider borderColor={color} isChecked={value} />
      </SwitchInnerWrapper>
    </SwitchWrapper>
  )
}

const SwitchWrapper = styled.label<{ marginBottom?: number }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  width: 100%;
  cursor: pointer;
`

const SwitchInnerWrapper = styled.div`
  position: relative;
  display: flex;
`

const StyledSwitch = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

const Slider = styled.span<{ isChecked: boolean; borderColor?: Color }>`
  width: 36px;
  height: 18px;
  background-color: ${colors.transparent};
  border-radius: 9px;
  border: 2px solid ${({ borderColor }) => (borderColor ? colors[borderColor] : colors.neutralWhite)};

  &::before {
    content: '';
    position: absolute;
    transition: 0.4s;
    width: 8px;
    height: 8px;
    background-color: ${({ isChecked }) => (isChecked ? colors.tertiary1 : colors.tertiary4)};
    border-radius: 50%;
    top: 5px;
    left: 6px;
    ${({ isChecked }) => {
      if (isChecked) {
        return css`
          transform: translateX(16px);
        `
      }
    }}
  }
`

export default Switch
