import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import AppContainer from '../components/AppContainer'
import AppPage from '../components/AppPage'
import AppTop from '../components/AppTop'
import Avatar, { AvatarSize } from '../components/Avatar'
import Button, { ButtonVariant } from '../components/Button'
import Pagination from '../components/Pagination'
import Text from '../components/Text'
import { USERS_COUNT, USERS_PAGE } from '../constants/api'
import { useAppState } from '../context/AppContext'
import strapi from '../helpers/strapi.helper'
import colors from '../styles/colors'

interface IUserPage {
  users: User[]
  page: number
}

const MembersPage: React.FC = () => {
  const appState = useAppState()
  const [usersCount, setUsersCount] = useState<number>(0)
  const history = useHistory()
  const params: { company: string } = useParams()
  const isAuthenticated = !!appState.userAuthToken
  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login')
    }
  }, [isAuthenticated])

  const [currentPage, setCurrentPage] = useState(1)
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
  useEffect(() => {
    const getUsersCount = async () => {
      const usersCount = (await strapi.request('get', USERS_COUNT(params.company))) as number
      setUsersCount(usersCount)
    }
    getUsersCount()
  }, [])

  const [users, setUsers] = useState<IUserPage[]>([])

  const PageSize = 16

  useEffect(() => {
    if (!users?.find((calledPage) => calledPage.page === currentPage)) {
      const fetchUsers = async () => {
        setLoadingUsers(true)
        const usersRes = (await strapi.request('get', USERS_PAGE(currentPage, params.company))) as User[]
        if (usersRes.length > 0) {
          users?.push({ page: currentPage, users: usersRes })
          setUsers([...users])
        }
        setLoadingUsers(false)
      }
      fetchUsers()
    }
  }, [currentPage])

  return (
    <AppPage>
      <AppTop inverted={true}>
        <Text style="headline" color="primary2" marginBottom={4}>
          Members
        </Text>
        <Text style="heading2" color="primary1">
          A collection of all CDI users
        </Text>
      </AppTop>
      <StyledAppContainer>
        <MembersContainer>
          {users &&
            users.length > 0 &&
            users
              .find((usersPage) => usersPage.page === currentPage)
              ?.users?.map((user, index) => (
                <MemberContainer key={index}>
                  <StyledAvatar size={AvatarSize.Large} user={user}></StyledAvatar>
                  <Text marginBottom={10} textAlign="center" style="heading3">
                    {user.full_name || user.first_name + ' ' + user.last_name}
                  </Text>
                  {user.public_setting && (
                    <StyledButton
                      caption="See profile"
                      onClick={() => history.push(`/profile/${user.id}`)}
                      variant={ButtonVariant.Tertiary}
                      textColor="primary2"
                      icon="ArrowRight"
                      textStyle="label8"
                    />
                  )}
                </MemberContainer>
              ))}
          {(!users.find((usersPage) => usersPage.page === currentPage) || users.length === 0) && (
            <Text style="heading2">{loadingUsers ? 'Loading members...' : 'No members were found.'}</Text>
          )}
        </MembersContainer>

        <StyledPagination
          currentPage={currentPage}
          totalCount={usersCount}
          pageSize={PageSize}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      </StyledAppContainer>
    </AppPage>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-top: 40px;
`

const StyledPagination = styled(Pagination)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

const MembersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
`

const MemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 250px;
  background: ${colors.neutralWhite};
  border-radius: 10px;
  padding: 25px;
`

const StyledAvatar = styled(Avatar)`
  margin: 0 auto 10px auto;
`

const StyledButton = styled(Button)``

export default MembersPage
