import React, { useState } from 'react'
import Text from '../components/Text'
import styled from 'styled-components'
import JobCard from './cards/JobCard'
import colors from '../styles/colors'
import Button from './Button'
import Icon from './icon/Icon'
import SkillsContainer from './SkillsContainer'
import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../helpers/tools.js'
import formatSlug from '../helpers/formatSlug.helper'
interface IProps {
  isDialog?: boolean
  closeDialog?: () => void
  job?: Job
  hideApplyLink?: boolean
  border?: boolean
}

const JobView: React.FC<IProps> = ({ isDialog, closeDialog, job, hideApplyLink, border }) => {
  const ReactEditorJS = createReactEditorJS()
  let jobDescription = job?.job_description

  const editorCore = React.useRef(null)

  const [isReady, setIsReady] = useState(false)

  const handleInitialize = React.useCallback(async (instance) => {
    editorCore.current = await instance
    setTimeout(() => {
      setIsReady(true)
    }, 1000)
  }, [])

  if (jobDescription && typeof jobDescription !== 'object') {
    jobDescription = JSON.parse(jobDescription)
  }

  const openInNewTab = () => {
    window.gtag('event', 'Apply job', {
      event_label: job ? job.slug || formatSlug(job) : window.location.href,
    })

    const newWindow = window.open(job?.job_application_apply_link, '_self')
    if (newWindow) newWindow.opener = null
  }

  return (
    <Wrapper>
      <JobCardContainer>
        <StyledJobCard job={job}></StyledJobCard>
        {isDialog && <StyledCloseIcon size={24} color={colors.tertiary1} type="Close" onClick={closeDialog} />}
      </JobCardContainer>
      {!hideApplyLink && <Button caption="Apply" onClick={openInNewTab} />}
      <Text style="heading1" color="primary1" marginTop={18} marginBottom={3} forceStyle="mobile">
        Job Description
      </Text>
      <Text style="label5" color="primary1" marginBottom={-200}>
        {jobDescription && (
          <ReactEditorJS
            value={editorCore.current && isReady ? jobDescription : undefined}
            defaultValue={jobDescription}
            tools={EDITOR_JS_TOOLS}
            readOnly={true}
            holder="custom-editor"
            onInitialize={handleInitialize}
          >
            <div id="custom-editor"></div>
          </ReactEditorJS>
        )}
      </Text>
      <SkillsContainer border={border} job={job} />
      {!hideApplyLink && <ApplyButton caption="Apply" onClick={openInNewTab} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  #custom-editor {
    .ce-block__content {
      max-width: unset;
    }
  }
`

const JobCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledJobCard = styled(JobCard)`
  margin-bottom: 18px;
`

const ApplyButton = styled(Button)`
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 200;
`

const StyledCloseIcon = styled(Icon)`
  cursor: pointer;
`

export default JobView
