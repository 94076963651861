import React from 'react'
import ReactDOM from 'react-dom'
import Navigation from './components/Navigation'
import Notifications from './components/Notifications'
import HomePage from './pages/home'
import CoursesPage from './pages/courses'
import ProfilePage from './pages/profile'
import JobboardPage from './pages/jobboard'
import EnterprisePage from './pages/enterprise'
import JobPage from './pages/job'
import PostJobPage from './pages/post-job'
import LoginPage from './pages/login'
import RegisterPage from './pages/register'
import ForgotPasswordPage from './pages/forgot-password'
import PasswordResetPage from './pages/password-reset'
import NotFound from './pages/404'
import './styles/global.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './fonts/GT-America-Standard-Medium-Trial.woff2'
import './fonts/GT-America-Standard-Regular-Trial.woff2'
import './fonts/GT-America-Standard-Bold-Trial.woff2'
import './fonts/GT-America-Standard-Regular-Italic-Trial.woff2'
import { ContextTemplate } from './context/AppContext'
import DiscourseSSO from './pages/discourse-sso'
import TakeCourse from './pages/take-course'
import Tour from './components/Tour'
import LandingPage from './pages/landing-page'
// import ForumPage from './pages/forum'
import MembersPage from './pages/members'
import YourCoursesPage from './pages/your-courses'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
    gtag: any
    _hsp: any
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ContextTemplate>
      <Router>
        <Navigation logoLink="https://www.conversationdesigninstitute.com/" />
        <Notifications />
        <Tour />
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/enterprise" component={EnterprisePage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact={false} path="/password-reset/:code" component={PasswordResetPage} />
          <Route exact path="/shop" component={CoursesPage} />
          <Route exact={false} path="/shop/:course" component={CoursesPage} />
          <Route exact path="/jobboard" component={JobboardPage} />
          {/* <Route exact path="/forum" component={ForumPage} /> */}
          <Route exact path="/" component={LandingPage} />
          <Route exact={false} path="/enrollments/:name" component={YourCoursesPage} />

          {/* private  routes*/}
          <Route exact path="/jobboard/post-job" component={PostJobPage} />
          <Route exact={false} path="/profile/:user_id" component={ProfilePage} />
          <Route exact path="/dashboard" component={HomePage} />
          <Route exact path="/members" component={MembersPage} />
          <Route exact path="/members/:company" component={MembersPage} />

          {/* private  routes*/}
          <Route exact path="/discourse-sso" component={DiscourseSSO} />
          <Route exact={false} path="/jobboard/:slug" component={JobPage} />
          <Route exact path="/courses/take/:slug" component={TakeCourse} />

          <Route component={NotFound} />
        </Switch>
      </Router>
    </ContextTemplate>
  </React.StrictMode>,
  document.getElementById('root')
)
