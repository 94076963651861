import React from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'

interface IProps {
  className?: string
}

const Loader: React.FC<IProps> = ({ className }) => {
  return (
    <StyledLoader className={className}>
      <StyledLoader className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </StyledLoader>
    </StyledLoader>
  )
}

const StyledLoader = styled.div`
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  .lds-ring div {
    position: absolute;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 2px;
    border: 2px solid ${colors.neutralWhite};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${colors.neutralWhite} transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default Loader
