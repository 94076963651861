import { css } from 'styled-components'
import { ITextRules, ITextStyle, TextStyleType, FontWeight, FontFamily, FontStyle } from '../common/models/typography'
import { Breakpoint } from '../styles/responsive'
import { applyResponsive } from './responsive'

export type ForceStyle = 'mobile' | 'desktop'

/**
 * Configure the text style presets
 */
export const textStyles = {
  headline: {
    mobile: {
      fontSize: 40,
      lineHeight: 44,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -1.8,
    },
    desktop: {
      fontSize: 54,
      lineHeight: 68,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -1.8,
    },
  },
  headline2: {
    mobile: {
      fontSize: 54,
      lineHeight: 65,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -2.4,
    },
    desktop: {
      fontSize: 72,
      lineHeight: 83,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -2.4,
    },
  },
  headline3: {
    mobile: {
      fontSize: 45,
      lineHeight: 52,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -1.5,
      fontWeight: FontWeight.Medium,
    },
    desktop: {
      fontSize: 45,
      lineHeight: 52,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -1.5,
      fontWeight: FontWeight.Medium,
    },
  },
  heading1: {
    mobile: {
      fontSize: 18,
      lineHeight: 23,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -0.6,
    },
    desktop: {
      fontSize: 30,
      lineHeight: 38,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -0.6,
    },
  },
  heading2: {
    mobile: {
      fontSize: 18,
      lineHeight: 23,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Regular,
      letterSpacing: -0.8,
    },
    desktop: {
      fontSize: 24,
      lineHeight: 30,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Regular,
      letterSpacing: -0.8,
    },
  },
  heading3: {
    mobile: {
      fontSize: 21,
      lineHeight: 28,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -0.7,
    },
    desktop: {
      fontSize: 21,
      lineHeight: 28,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -0.7,
    },
  },
  heading4: {
    mobile: {
      fontSize: 21,
      lineHeight: 26,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Regular,
    },
    desktop: {
      fontSize: 21,
      lineHeight: 26,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Regular,
    },
  },
  heading5: {
    mobile: {
      fontSize: 18,
      lineHeight: 22,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Bold,
      letterSpacing: -0.19,
    },
    desktop: {
      fontSize: 18,
      lineHeight: 22,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Bold,
      letterSpacing: -0.19,
    },
  },
  heading6: {
    mobile: {
      fontSize: 24,
      lineHeight: 30,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
    },
    desktop: {
      fontSize: 24,
      lineHeight: 30,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
    },
  },
  heading7: {
    mobile: {
      fontSize: 28,
      lineHeight: 32,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -0.93,
    },
    desktop: {
      fontSize: 28,
      lineHeight: 32,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -0.93,
    },
  },
  heading8: {
    mobile: {
      fontSize: 19,
      lineHeight: 22,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -0.64,
    },
    desktop: {
      fontSize: 19,
      lineHeight: 22,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -0.64,
    },
  },
  heading9: {
    mobile: {
      fontSize: 19,
      lineHeight: 33,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Regular,
    },
    desktop: {
      fontSize: 19,
      lineHeight: 33,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Regular,
    },
  },
  heading10: {
    mobile: {
      fontSize: 28,
      lineHeight: 40,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -0.93,
    },
    desktop: {
      fontSize: 28,
      lineHeight: 40,
      fontFamily: FontFamily.GTAmericaTrial,
      fontWeight: FontWeight.Medium,
      letterSpacing: -0.93,
    },
  },
  label1: {
    mobile: {
      fontSize: 16,
      lineHeight: 20,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
    },
    desktop: {
      fontSize: 16,
      lineHeight: 20,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
    },
  },
  label2: {
    mobile: {
      fontSize: 16,
      lineHeight: 20,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.4,
    },
    desktop: {
      fontSize: 16,
      lineHeight: 20,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.4,
    },
  },
  label3: {
    mobile: {
      fontSize: 12,
      lineHeight: 14,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.17,
    },
    desktop: {
      fontSize: 12,
      lineHeight: 14,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.17,
    },
  },
  label4: {
    mobile: {
      fontSize: 14,
      lineHeight: 17,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.2,
    },
    desktop: {
      fontSize: 14,
      lineHeight: 17,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.2,
    },
  },
  label5: {
    mobile: {
      fontSize: 16,
      lineHeight: 19,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.53,
    },
    desktop: {
      fontSize: 16,
      lineHeight: 19,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.53,
    },
  },
  label6: {
    mobile: {
      fontSize: 14,
      lineHeight: 18,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.15,
    },
    desktop: {
      fontSize: 14,
      lineHeight: 18,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.15,
    },
  },
  label7: {
    mobile: {
      fontSize: 16,
      lineHeight: 24,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      textDecoration: 'underline',
    },
    desktop: {
      fontSize: 16,
      lineHeight: 24,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      textDecoration: 'underline',
    },
  },
  label8: {
    mobile: {
      fontSize: 15,
      lineHeight: 22,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.51,
    },
    desktop: {
      fontSize: 15,
      lineHeight: 22,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.51,
    },
  },
  label9: {
    mobile: {
      fontSize: 17,
      lineHeight: 22,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.57,
    },
    desktop: {
      fontSize: 17,
      lineHeight: 22,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.57,
    },
  },
  label10: {
    mobile: {
      fontSize: 17,
      lineHeight: 20,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.24,
    },
    desktop: {
      fontSize: 17,
      lineHeight: 20,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      letterSpacing: -0.24,
    },
  },
  label11: {
    mobile: {
      fontSize: 17,
      lineHeight: 28,
      fontFamily: FontFamily.GTAmericaTrial,
    },
    desktop: {
      fontSize: 17,
      lineHeight: 28,
      fontFamily: FontFamily.GTAmericaTrial,
    },
  },
  label12: {
    mobile: {
      fontFamily: FontFamily.GTAmericaTrial,
      fontSize: 13,
      lineHeight: 16,
      letterSpacing: -0.14,
    },
    desktop: {
      fontFamily: FontFamily.GTAmericaTrial,
      fontSize: 13,
      lineHeight: 16,
      letterSpacing: -0.14,
    },
  },
  description: {
    mobile: {
      fontSize: 16,
      lineHeight: 20,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      fontStyle: FontStyle.Italic,
    },
    desktop: {
      fontSize: 16,
      lineHeight: 20,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.GTAmericaTrial,
      fontStyle: FontStyle.Italic,
    },
  },
}

export const defaultTextStyle: TextStyleType = 'heading1'

/**
 * Define which breakpoint to use to toggle between desktop/mobile
 * text styles
 */
export const defaultTypographyBreakpoint = Breakpoint.L

/**
 * Create the needed CSS for a given text style (works like a mixin)
 * @param textStyle The ITextStyle object, containing desktop styles
 * and optionally, mobile ones
 */
const applyCustomTextStyle = (textStyle: ITextStyle, forceStyle?: ForceStyle) => {
  const breakpoint = textStyle.breakpoint || defaultTypographyBreakpoint

  if (forceStyle === 'mobile') {
    return css`
      ${getTextRules(textStyle.mobile)}
    `
  }
  if (forceStyle === 'desktop') {
    return css`
      ${textStyle.desktop && getTextRules(textStyle.desktop)}
    `
  }
  return css`
    ${getTextRules(textStyle.mobile)}
    ${textStyle.desktop && applyResponsive({ from: breakpoint }, getTextRules(textStyle.desktop))}
  `
}

/**
 * Return a FlattenSimpleInterpolation css`` literal for given
 * text rules
 * @param rules The ITextRules object
 */
const getTextRules = (rules: ITextRules) => {
  return css`
    font-size: ${rules.fontSize}px;
    line-height: ${rules.lineHeight}px;
    ${rules.fontFamily !== undefined &&
    css`
      font-family: ${rules.fontFamily};
    `}
    ${rules.fontWeight !== undefined &&
    css`
      font-weight: ${rules.fontWeight};
    `}
    ${rules.textTransform !== undefined &&
    css`
      text-transform: ${rules.textTransform};
    `}
    ${rules.fontStyle !== undefined &&
    css`
      font-style: ${rules.fontStyle};
    `}
    ${rules.textDecoration !== undefined &&
    css`
      text-decoration: ${rules.textDecoration};
    `}
    ${rules.letterSpacing !== undefined &&
    css`
      letter-spacing: ${rules.letterSpacing}px;
    `}
  `
}

/**
 * Apply a predefined text style (works like applyCustomTextStyle, but
 * takes as input a preset name, instead of an ITextRules object)
 * @param name The text style key, eg. 'caption' or 'labelHeavy'
 */
const applyTextStyle = (name: TextStyleType, forceStyle?: 'mobile' | 'desktop') => {
  const textStyle = textStyles[name]
  return applyCustomTextStyle(textStyle, forceStyle)
}

/**
 * Apply ellipsis-style text overflow
 */
const applyTextEllipsis = () => {
  return css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
}

/**
 * Remove text ellipsis style (unset certain properties)
 */
const removeTextEllipsis = () => {
  return css`
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
  `
}

export { Breakpoint, applyCustomTextStyle, applyTextStyle, applyTextEllipsis, removeTextEllipsis }
