export const cdiCourseModuleOrder = [
  {
    name: 'Conversation Designer',
    enrollments: ['Intro to Conversation Designer Course', 'Fundamentals - Conversation Designer', 'Workflow - Conversation Designer', 'Downloadables'],
  },
  {
    name: 'AI Trainer',
    enrollments: ['Intro to AI Trainer Course', 'Theory - AI Trainer', 'Build - AI Trainer', 'Improve - AI Trainer', 'Downloadables'],
  },
  {
    name: 'Conversational Copywriter',
    enrollments: [
      'Intro to Conversational Copywriter Course',
      'Increasing motivation - Conversational Copywriter',
      'Bot personality - Conversational Copywriter',
      'Downloadables',
    ],
  },
  {
    name: 'Conversational AI Workflow',
    enrollments: ['Conversational AI Workflow', 'Downloadables'],
  },
  {
    name: 'Prompt Designer for Conversational AI',
    enrollments: ['Prompt Designer for Conversational AI', 'Downloadables'],
  },
]
