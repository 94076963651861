import React, { useEffect, useState } from 'react'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import styled from 'styled-components'
import colors from '../styles/colors'
import Text from '../components/Text'
import Icon from '../components/icon/Icon'
import Button from '../components/Button'
import Section from '../components/Section'
import CertificatesList from '../components/CertificatesList'
import KnowledgeCardsList from '../components/KnowledgeCardsList'
import Switch from '../components/Forms/formControls/Switch'
import { useAppDispatch, useAppState } from '../context/AppContext'
import MainProfileForm from '../components/Forms/MainProfileForm'
import ProfileAbout from '../components/ProfileAbout'
import editUserService from '../context/services/editUser.service'
import strapi from '../helpers/strapi.helper'
import { UPLOAD, USERS } from '../constants/api'
import Avatar, { AvatarSize } from '../components/Avatar'
import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const ProfilePage: React.FC = () => {
  const appState = useAppState()
  const appDispatch = useAppDispatch()
  const history = useHistory()
  const params: { user_id: string } = useParams()
  const isAuthenticated = !!appState.userAuthToken

  const [user, setUser] = useState<User | undefined>(params.user_id ? undefined : appState.user)

  useEffect(() => {
    if (appState.user && params.user_id != appState.user.id) {
      const fetchUser = async () => {
        const userRes = (await strapi.request('get', USERS + `/${params.user_id}`)) as User
        setUser(userRes)
      }
      fetchUser()
    } else {
      setUser(appState.user)
    }
  }, [params.user_id, appState.user])

  const [isOnPublicProfile, setIsOnPublicProfile] = useState<boolean>(params.user_id != appState.user?.id)
  const [isExperienceOnAddMode, setIsExperienceOnAddMode] = useState<boolean>(false)
  const [isEducationOnAddMode, setIsEducationOnAddMode] = useState<boolean>(false)
  useEffect(() => {
    setIsOnPublicProfile(params.user_id != appState.user?.id)
  }, [params.user_id, appState.user])
  const [selectedFiles, setSelectedFiles] = useState<any>(undefined)

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login')
    }
  }, [isAuthenticated])

  const submitProfileImg = async () => {
    const formData = new FormData()

    formData.append('files', selectedFiles[0])

    await strapi.request('post', UPLOAD, { data: formData }).then(async (response: any) => {
      const url = response[0].url
      await editUserService(user?.id || '', {
        avatar_url: url,
      })
      appDispatch({
        type: 'updateShouldLoadUser',
        payload: true,
      })
      window.dataLayer.push({
        event: 'Change in profile',
      })
    })
  }

  useEffect(() => {
    if (selectedFiles) {
      submitProfileImg()
    }
  }, [selectedFiles])

  const editUser = () => {
    appDispatch({
      type: 'updateModalWindow',
      payload: {
        hideActions: true,
        content: <MainProfileForm></MainProfileForm>,
      },
    })
  }

  const changeIsPublic = async (isChecked: boolean) => {
    await editUserService(user?.id || '', { public_setting: isChecked })
    window.dataLayer.push({
      event: 'Change in profile',
    })
  }

  return (
    <StyledAppPage>
      <AppTop>
        <Helmet>
          <meta property="og:title" content={`Conversational Design Institute Profile ${user?.full_name}`} />
          <meta property="og:description" content={user?.headline} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:locale" content="english" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={user?.avatar_url} />
        </Helmet>
        {isAuthenticated && (
          <>
            {user && !isOnPublicProfile && (
              <PublicSwitchContainer>
                <Switch label="Public" onChange={(e) => changeIsPublic(e.target.checked)} defaultValue={user.public_setting} />
              </PublicSwitchContainer>
            )}
            <ProfileContainer>
              <Images>
                <AvatarStyled user={user} size={AvatarSize.ExtraLarge} />
                <SocialIcons>
                  {user?.website_url && (
                    <a href={`${user.website_url.startsWith('https://') ? user.website_url : 'https://' + user.website_url}`} rel="noreferrer">
                      <Icon type="Language" color={colors.neutralWhite} size={24} />{' '}
                    </a>
                  )}
                  {!user?.website_url && <Icon type="Language" color={colors.primary3} size={24} />}
                  {user?.twitter_url && (
                    <a href={`${user.twitter_url.startsWith('https://') ? user.twitter_url : 'https://' + user.twitter_url}`} rel="noreferrer">
                      <Icon type="Twitter" color={colors.neutralWhite} size={24} />
                    </a>
                  )}
                  {!user?.twitter_url && <Icon type="Twitter" color={colors.primary3} size={24} />}
                  {user?.linkedin_url && (
                    <a href={`${user.linkedin_url.startsWith('https://') ? user.linkedin_url : 'https://' + user.linkedin_url}`} rel="noreferrer">
                      <Icon type="Linkedin" color={colors.neutralWhite} size={24} />
                    </a>
                  )}
                  {!user?.linkedin_url && <Icon type="Linkedin" color={colors.primary3} size={24} />}
                </SocialIcons>
                {!isOnPublicProfile && (
                  <>
                    <EditProfileImg>
                      <StyledEditProfileImgIcon type="Edit" color={colors.neutralWhite} size={24} />
                    </EditProfileImg>
                    <InputTypeFile onChange={async (e: any) => setSelectedFiles(e.target.files)} type="file" />
                  </>
                )}
              </Images>
              <Texts>
                <Text style="headline" color="neutralWhite" marginBottom={4}>
                  {calculateName(user)}
                </Text>
                <Text style="heading2" color="neutralWhite" marginBottom={12}>
                  {user?.headline || 'A mysterious CDI profile'}
                </Text>
                <Text style="label3" color="neutralWhite" marginBottom={28}>
                  {calculateLocation(user)}
                </Text>
                <Text style="description" color="neutralWhite" marginBottom={10}>
                  {calculateBio(user, isOnPublicProfile)}
                </Text>
              </Texts>
            </ProfileContainer>
            <PublicProfileActions>
              {!isOnPublicProfile && <Button caption="Edit" onClick={editUser} />}
              {params.user_id == appState.user?.id && appState.user && (
                <Button
                  caption={isOnPublicProfile ? 'Back to profile' : 'View public profile'}
                  onClick={() => (isOnPublicProfile ? setIsOnPublicProfile(false) : setIsOnPublicProfile(true))}
                />
              )}
            </PublicProfileActions>
          </>
        )}
      </AppTop>
      {isAuthenticated && (
        <>
          <StyledAppContainer>
            <Section header="Certificates" forceStyleHeader="mobile" withTopBorder>
              <StyledCertificatesList certificates={user?.certificates} />
              <Placeholder>
                {user?.certificates && user.certificates.length < 1
                  ? isOnPublicProfile
                    ? 'This user does not have any CDI certificates yet'
                    : 'Your CDI certificates will be listed here after completion'
                  : ''}
              </Placeholder>
            </Section>
            <SectionExperienceStyled withTopBorder header="Experience" forceStyleHeader="mobile" marginBottomAfterLine={24}>
              <Placeholder>
                {user?.experience && user.experience.length < 1
                  ? isOnPublicProfile
                    ? 'This user has not added his/her experience data yet'
                    : isExperienceOnAddMode
                    ? ''
                    : 'Please add your working experience here'
                  : ''}
              </Placeholder>
              <StyledKnowledgeCardsList
                isOnPublicProfile={isOnPublicProfile}
                isDataExperience={true}
                data={user?.experience || []}
                passIsOnAdd={setIsExperienceOnAddMode}
              ></StyledKnowledgeCardsList>
            </SectionExperienceStyled>
            <SectionEducationStyled withTopBorder header="Education" forceStyleHeader="mobile" marginBottomAfterLine={24}>
              <Placeholder>
                {user?.education && user.education.length < 1
                  ? isOnPublicProfile
                    ? 'This user has not added his/her education data yet'
                    : isEducationOnAddMode
                    ? ''
                    : 'Please add your education experience here'
                  : ''}
              </Placeholder>
              <StyledKnowledgeCardsList
                isOnPublicProfile={isOnPublicProfile}
                isDataExperience={false}
                data={user?.education || []}
                passIsOnAdd={setIsEducationOnAddMode}
              ></StyledKnowledgeCardsList>
            </SectionEducationStyled>
          </StyledAppContainer>
          <StyledAboutAppContainer>
            <AppContainer>
              <Section header="About" forceStyleHeader="mobile" marginBottomAfterLine={33.5}>
                {user && <ProfileAbout user={user} isOnPublicProfile={isOnPublicProfile}></ProfileAbout>}
              </Section>
            </AppContainer>
          </StyledAboutAppContainer>
        </>
      )}
    </StyledAppPage>
  )
}

const calculateLocation = (user?: User) => {
  if (!user) return 'Whereabouts unkown'
  if (user.city && user.country) return `${user.city}, ${user.country}`
  return 'Whereabouts unkown'
}

const calculateName = (user?: User) => {
  if (!user) return 'Unknown user'
  if (user.full_name) return user.full_name
  if (!user.full_name && user.first_name && user.last_name) return `${user.first_name} ${user.last_name}`
  return 'Unknown user'
}

const calculateBio = (user?: User, publicProfile?: boolean) => {
  if (!user) return ''
  if (publicProfile) {
    if (user.bio) return user.bio
    if (user.avatar_url && user.headline) return ''
    return 'This user could use a proper bio on their profile.'
  }
  if (!publicProfile) {
    if (user.bio) return user.bio
    if (user.avatar_url && user.headline) return ''
    return 'Enter something about yourself here.'
  }
}

const StyledAppPage = styled(AppPage)`
  padding-bottom: 0;
`

const StyledAppContainer = styled(AppContainer)`
  margin-top: 38px;
`

const Placeholder = styled.span`
  color: ${colors.secondary1};
  font-size: 15px;
`

const ProfileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Images = styled.div`
  display: flex;
  flex-direction: column;
  width: 128px;
  flex-shrink: 0;
  position: relative;
  margin-right: 46px;
`

const AvatarStyled = styled(Avatar)`
  margin-bottom: 28px;
`

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 634px;
`

const EditProfileImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${colors.tertiary1};
  cursor: pointer;
`

const StyledEditProfileImgIcon = styled(Icon)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PublicSwitchContainer = styled.div`
  position: absolute;
  right: 15px;
  top: -18px;
`

const PublicProfileActions = styled.div`
  position: absolute;
  bottom: -40px;
  right: 15px;
  display: flex;
  gap: 10px;
`
const SectionExperienceStyled = styled(Section)`
  margin-top: 70px;
`

const SectionEducationStyled = styled(Section)`
  margin-top: 70px;
  margin-bottom: 103px;
`

const StyledAboutAppContainer = styled(AppContainer)`
  background-color: ${colors.neutralWhite};
  padding-top: 86px;
  padding-bottom: 67px;
  max-width: unset;
`

const StyledKnowledgeCardsList = styled(KnowledgeCardsList)`
  max-width: 920px;
  margin-left: auto;
  margin-right: 0;
`

const StyledCertificatesList = styled(CertificatesList)`
  max-width: 920px;
  margin-left: auto;
  margin-right: 0;
`

const InputTypeFile = styled.input`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${colors.tertiary1};
  cursor: pointer;
  opacity: 0;
  z-index: 1000;
`

export default ProfilePage
