import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import Text from './Text'
import { mqFrom } from '../styles/responsive'
import { applyTextStyle } from '../styles/typography'
import TextArea from './Forms/formControls/TextArea'
import LanguagesList from './LanguagesList'
import SkillsList from './SkillsList'
import { useAppDispatch } from '../context/AppContext'
import editUserService from '../context/services/editUser.service'
import { worldwideSpokenLanguages } from '../helpers/languages'

type AboutHeaders = 'What I do' | 'Achievements' | 'Skills' | 'Languages' | 'Ambitions'

interface IProps {
  isOnPublicProfile: boolean
  user: User
}

const ProfileAbout: React.FC<IProps> = ({ isOnPublicProfile, user }) => {
  const appDispatch = useAppDispatch()

  const aboutHeaders: AboutHeaders[] = ['What I do', 'Achievements', 'Skills', 'Languages', 'Ambitions']

  const [isOnEdit, setIsOnEdit] = useState<boolean[]>(aboutHeaders.map(() => false))
  const [aboutValue, setAboutValue] = useState<string>('')
  const [achievementsValue, setAchievementsValue] = useState<string>(user?.achievements || '')
  const [skillsValue, setSkillsValue] = useState<string[]>([])
  const [languagesValue, setLanguagesValue] = useState<string[]>([])
  const [ambitionsValue, setAmbitionsValue] = useState<string>()

  useEffect(() => {
    if (user) {
      setAboutValue(user.about || '')
      setAchievementsValue(user.achievements || '')
      setSkillsValue(user.skills || [])
      setLanguagesValue(user.languages || [])
      setAmbitionsValue(user.ambitions || '')
    }
  }, [user])

  useEffect(() => {
    if (user && user.skills && user.languages) {
      setSkillsValue([...user.skills])
      setLanguagesValue([...user.languages])
    }
  }, [isOnEdit[2], isOnEdit[3]])

  const editAbout = async (index: number) => {
    let dataToSend: User | undefined
    switch (index) {
      case 0:
        dataToSend = { about: aboutValue }
        break
      case 1:
        dataToSend = { achievements: achievementsValue }
        break
      case 2:
        dataToSend = { skills: skillsValue }
        break
      case 3:
        dataToSend = { languages: languagesValue }
        break
      case 4:
        dataToSend = { ambitions: ambitionsValue }
        break
      default:
        break
    }
    if (dataToSend) {
      await editUserService(user?.id || '', dataToSend)
    }
    appDispatch({
      type: 'updateShouldLoadUser',
      payload: true,
    })
    swapIsOnEdit(index)
    window.dataLayer.push({
      event: 'Change in profile',
    })
  }

  const swapIsOnEdit = (index: number) => {
    setIsOnEdit(isOnEdit.map((open, openIndex) => (openIndex === index ? !open : open)))
  }

  const getActionsContent = (index: number) => {
    return (
      <>
        {!isOnPublicProfile && (
          <>
            {isOnEdit[index] && (
              <EditLink isCancel onClick={() => swapIsOnEdit(index)}>
                Cancel
              </EditLink>
            )}
            <EditLink onClick={() => (isOnEdit[index] ? editAbout(index) : swapIsOnEdit(index))}>{isOnEdit[index] ? 'Save' : 'Edit'}</EditLink>
          </>
        )}
      </>
    )
  }

  return (
    <AboutContentContainer>
      <AboutItemContainer>
        <AboutItemTitle>
          <Text style="label2" color="secondary1">
            What I do
          </Text>
        </AboutItemTitle>
        <AboutItemContent>
          {!isOnEdit[0] && (
            <Text style="label5" color="primary1">
              {user?.about}
            </Text>
          )}
          {isOnEdit[0] && <TextArea placeholder="What I do" onChange={(e) => setAboutValue(e.target.value)} withBorder defaultValue={user?.about} />}
        </AboutItemContent>
        {getActionsContent(0)}
      </AboutItemContainer>
      <AboutItemContainer>
        <AboutItemTitle>
          <Text style="label2" color="secondary1">
            Achievements
          </Text>
        </AboutItemTitle>
        <AboutItemContent>
          {!isOnEdit[1] && (
            <Text style="label5" color="primary1">
              {user?.achievements}
            </Text>
          )}
          {isOnEdit[1] && (
            <TextArea placeholder="Achievements" onChange={(e) => setAchievementsValue(e.target.value)} withBorder defaultValue={user?.achievements} />
          )}
        </AboutItemContent>
        {getActionsContent(1)}
      </AboutItemContainer>
      <AboutItemContainer>
        <AboutItemTitle>
          <Text style="label2" color="secondary1">
            Skills
          </Text>
        </AboutItemTitle>
        <AboutItemContent>
          <SkillsList
            skills={skillsValue}
            isInEditMode={isOnEdit[2]}
            onChange={(e, index) => {
              const skills = skillsValue
              skills[index] = e.target.value
              setSkillsValue(skills)
            }}
            onAdd={() => {
              const skills = skillsValue
              skills.push('')
              setSkillsValue([...skills])
            }}
            onDelete={(index) => {
              const skills = skillsValue
              skills.splice(index, 1)
              setSkillsValue([...skills])
            }}
          ></SkillsList>
        </AboutItemContent>
        {getActionsContent(2)}
      </AboutItemContainer>
      <AboutItemContainer>
        <AboutItemTitle>
          <Text style="label2" color="secondary1">
            Languages
          </Text>
        </AboutItemTitle>
        <AboutItemContent>
          <LanguagesList
            languages={languagesValue}
            isInEditMode={isOnEdit[3]}
            onChange={(e, index) => {
              const languages = languagesValue
              languages[index] = e.target.value
              setLanguagesValue(languages)
            }}
            onAdd={() => {
              const languages = languagesValue
              languages.push(worldwideSpokenLanguages[0])
              setLanguagesValue([...languages])
            }}
            onDelete={(index) => {
              const languages = languagesValue
              languages.splice(index, 1)
              setLanguagesValue([...languages])
            }}
          ></LanguagesList>
        </AboutItemContent>
        {getActionsContent(3)}
      </AboutItemContainer>
      <AboutItemContainer>
        <AboutItemTitle>
          <Text style="label2" color="secondary1">
            Ambitions
          </Text>
        </AboutItemTitle>
        <AboutItemContent>
          {!isOnEdit[4] && (
            <Text style="label5" color="primary1">
              {user?.ambitions}
            </Text>
          )}
          {isOnEdit[4] && <TextArea placeholder="Ambitions" onChange={(e) => setAmbitionsValue(e.target.value)} withBorder defaultValue={user?.ambitions} />}
        </AboutItemContent>
        {getActionsContent(4)}
      </AboutItemContainer>
    </AboutContentContainer>
  )
}

const AboutContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AboutItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  position: relative;
  ${mqFrom.M`
    flex-direction: row;
  `}
`

const AboutItemTitle = styled.div`
  width: 190px;
  flex-shrink: 0;
  margin-bottom: 20px;
  ${mqFrom.M`
    margin-bottom: 0px;
  `}
`

const AboutItemContent = styled.div`
  width: 100%;
  padding-bottom: 31px;
  padding-right: 0px;
  margin-left: auto;
  margin-right: 0;
  border-bottom: 1px solid ${colors.secondary2};

  ${mqFrom.S`
    padding-right: 30px;
  `}
  ${mqFrom.XL`
    padding-right: 200px;
  `}
`

const EditLink = styled.div<{ isCancel?: boolean }>`
  position: absolute;
  right: ${({ isCancel }) => (isCancel ? '50px' : '0px')};
  bottom: 6px;
  cursor: pointer;
  ${applyTextStyle('label2')};
  color: ${colors.tertiary1};
`

export default ProfileAbout
