import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import colors from '../../../styles/colors'
import { applyTextStyle } from '../../../styles/typography'
import Text from '../../Text'
import { COMPANY_AUTOCOMPLETE } from '../../../constants/api'
import DropdownSelect from '../../DropdownSelect'
import { FormControlProps, InputVariant, InputVariantType } from '../../../common/models/FormControlProps'
import { FormControlError } from './FormControlError'

export type InputType = 'text' | 'password' | 'email'

export interface ICompanyAutocomplete {
  name: string
  domain?: string
  logo: string
}
export interface IProps extends FormControlProps {
  type?: InputType
  onCompaniesAutocompleteChange?: (company: ICompanyAutocomplete) => void
  withCompaniesAutocomplete?: boolean
}

const Input: React.FC<IProps> = ({
  className,
  placeholder,
  label,
  onChange,
  marginBottom,
  type,
  defaultValue,
  withCompaniesAutocomplete,
  onCompaniesAutocompleteChange,
  variant,
  ...props
}) => {
  const errorMessage = props.meta && props.meta.error && props.meta.touched ? props.meta.error : props.errorMessage

  const [displayAutocomplete, setDisplayAutocomplete] = useState<boolean>(false)
  const [companies, setCompanies] = useState<ICompanyAutocomplete[]>([])
  const [search, setSearch] = useState<ICompanyAutocomplete>({
    name: defaultValue ? defaultValue : '',
    logo: '',
    domain: '',
  })
  const [skipOnChangeWhenDefaultValue, setSkipOnChangeWhenDefaultValue] = useState<boolean>(defaultValue ? true : false)

  const isCompanyAlreadySelected = () => {
    return companies.find((company) => company.name === search.name)
  }

  useEffect(() => {
    if (search.name !== '' && withCompaniesAutocomplete && !skipOnChangeWhenDefaultValue) {
      if (!isCompanyAlreadySelected()) {
        // eslint-disable-next-line
        ;(async () => {
          const searchCompanies = await fetch(COMPANY_AUTOCOMPLETE(search.name), {
            method: 'GET',
          })
          const responseJson = await searchCompanies.json()
          if (responseJson.length > 0) {
            setCompanies(responseJson)
            setDisplayAutocomplete(true)
          } else {
            setCompanies([])
            setDisplayAutocomplete(false)
          }
        })()
      }
    }
  }, [search.name])

  const setCompany = (company: ICompanyAutocomplete) => {
    setSearch(company)
    setDisplayAutocomplete(false)
    onCompaniesAutocompleteChange ? onCompaniesAutocompleteChange(company) : undefined
  }
  return (
    <InputWrapper className={className} marginBottom={marginBottom}>
      {label && (
        <Text
          marginBottom={12}
          style={variant === InputVariant.Secondary ? 'label8' : 'label5'}
          color="primary1"
          fontWeight={variant === InputVariant.Secondary ? 500 : undefined}
        >
          {label}
        </Text>
      )}
      <InputInnerWrapper>
        <StyledInput
          variant={variant || InputVariant.Primary}
          autoComplete="off"
          value={withCompaniesAutocomplete ? search.name : undefined}
          {...props}
          defaultValue={withCompaniesAutocomplete ? undefined : defaultValue}
          placeholder={placeholder}
          onChange={(e) => {
            if (withCompaniesAutocomplete) {
              const searchValueCopy = search
              setSearch({ ...searchValueCopy, name: e.target.value })
            }
            setSkipOnChangeWhenDefaultValue(false)
            onChange ? onChange(e) : undefined
          }}
          type={type ? type : 'text'}
        ></StyledInput>
        <FormControlError>{errorMessage}</FormControlError>
        <DropdownSelectStyled
          setActiveHeader={(headerIndex) => setCompany(companies[headerIndex])}
          headers={companies.map((company) => company.name)}
          isVisible={displayAutocomplete}
          onOutsideClick={() => setDisplayAutocomplete(false)}
        ></DropdownSelectStyled>
      </InputInnerWrapper>
    </InputWrapper>
  )
}

const InputWrapper = styled.div<{ marginBottom?: number }>`
  position: relative;
  display: block;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  width: 100%;
`

const InputInnerWrapper = styled.div`
  position: relative;
`

export const StyledInput = styled.input<{ variant: InputVariantType }>`
  display: block;
  width: 100%;
  background-color: ${colors.neutralWhite};
  outline: 0;
  border: ${({ variant }) => (variant === InputVariant.Secondary ? `1px solid ${colors.secondary6}` : 'none')};
  padding: 15px 19px;
  ${applyTextStyle('label4')};
  color: ${colors.secondary1};
`

const DropdownSelectStyled = styled(DropdownSelect)`
  width: 100%;
  top: 54px;
  border: 1px solid ${colors.secondary1};
  border-top: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

export default Input
