import React, { useState } from 'react'
import styled from 'styled-components'
import AppPage from '../components/AppPage'
import Button, { ButtonVariant } from '../components/Button'
import colors from '../styles/colors'
import { mqFrom } from '../styles/responsive'
import Text from './../components/Text'
import HomeImg from '../images/landing-page/home.png'
import { useHistory } from 'react-router-dom'
import Link from '../components/Link'
import VideoImg from '../images/landing-page/video.png'
import { applyCustomTextStyle, applyTextStyle } from '../styles/typography'
import { FontFamily, FontWeight } from '../common/models/typography'
import CDILandingLogoImg from '../images/landing-page/cdi-landing-logo.png'
import LvlUpProfile from '../images/landing-page/lvlup-profile.png'
import LvlUpConnections from '../images/landing-page/lvlup-connections.png'
import LvlUpPrinciples from '../images/landing-page/lvlup-principles.png'
import LvlUpCertified from '../images/landing-page/lvlup-certified.png'
import LvlUpOpportunities from '../images/landing-page/lvlup-opportunities.png'
import Icon from '../components/icon/Icon'
import ConnectWithPeopleImg from '../images/landing-page/connect-with-people.png'
import NewSkillsImg from '../images/landing-page/new-skills.png'
import banner from '../images/banner_bg.png'
import Logo from '../images/logo.svg'
import PhoneScreen from '../images/landing-page/phone-screen.png'
import CutScreen from '../images/landing-page/cut-screen.png'
import Avatar, { AvatarSize } from '../components/Avatar'
import openLink from '../helpers/openLink.helper'
import StevenProfile from '../images/landing-page/steven-profile.png'
import DarrellProfile from '../images/landing-page/darrell-profile.png'
import OliveraProfile from '../images/landing-page/olivera-profile.png'
import JanProfile from '../images/landing-page/jan-profile.png'
import RickProfile from '../images/landing-page/rick-profile.png'
import FrenklinProfile from '../images/landing-page/frenklin-profile.png'
import TenjaProfile from '../images/landing-page/tenja-profile.png'
import StevenCertificate from '../images/landing-page/steven-certificate.png'
import DarrellCertificate from '../images/landing-page/darrell-certificate.png'
import OliveraCertificate from '../images/landing-page/olivera-certificate.png'
import JanCertificate from '../images/landing-page/jan-certificate.png'
import RickCertificate from '../images/landing-page/rick-certificate.png'
import FrenklinCertificate from '../images/landing-page/frenklin-certificate.png'
import TenjaCertificate from '../images/landing-page/tenja-certificate.png'
import IntroVideo from '../videos/introduction.mp4'

interface LevelUpSection {
  img: string
  header: string
  description: string
}

interface Alumni {
  profileImg: string
  name: string
  job: string
  date: string
  message: string
  cetificateImg: string
  likesAndComments: string
  linkedInUrl?: string
}

const LandingPage: React.FC = () => {
  const history = useHistory()
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)
  const companyAccessLink = 'https://share.hsforms.com/1beycNQHpRsigL_aoPOU1nA2g0rx'

  const openLink = (link?: string) => {
    if (!link) return
    const newWindow = window.open(link, '_self')
    if (newWindow) newWindow.opener = null
  }

  const levelUpData: LevelUpSection[] = [
    {
      img: '',
      header: '',
      description: '',
    },
    {
      img: LvlUpProfile,
      header: 'Create a profile',
      description: 'Your personal profile page to help engage in the community and apply for jobs.',
    },
    {
      img: LvlUpConnections,
      header: 'Make connections',
      description: 'Join a vibrant community of like minded people and make connections that last a lifetime.',
    },
    {
      img: LvlUpPrinciples,
      header: 'Learn proven principles',
      description: 'Lean the proven CDI workflow and create human-centric AI Assistants.',
    },
    {
      img: LvlUpCertified,
      header: 'Become certified',
      description: 'Get the credentials to show your expertise and level up your career.',
    },
    {
      img: LvlUpOpportunities,
      header: 'Find opportunities',
      description: 'Find your next job or hire via our job boards, alumni events, and community forums.',
    },
  ]

  const USPS: string[] = [
    'Start with 2 free courses',
    'Access to 25+ courses and certificates',
    'Connect to peers and companies worldwide',
    'Find jobs and hires in our community',
  ]

  const Alumni: Alumni[] = [
    {
      profileImg: StevenProfile,
      name: 'Steven Gross',
      job: 'Conversational UI Content Designer at Instacart',
      date: '4d',
      message: `After the long weekend, I woke up to an email letting me know I received my certification from the Conversation Design Institute! Highly recommend this program for anybody else interested in Conversational Design. Ready to put these skills into action!`,
      cetificateImg: StevenCertificate,
      likesAndComments: '95 likes  | 7 comments',
      linkedInUrl: 'https://www.linkedin.com/posts/steven-gross-33b9a984_conversation-designer-certification-activity-6841081861842722816-Rvl6/',
    },
    {
      profileImg: FrenklinProfile,
      name: 'Frenklin Hasani',
      job: 'Experience Architect at Salesforce | EXPEDITIONER Trailblazer',
      date: '6h',
      message: `Web 3.0 is causing a major tech, social, culture and economic revolution.

      As we get closer with all the subsequent technologies such as Blockchain, NFTs, Crypto, AI (Artificial Intelligence) , VA (Virtual Reality), AR (Augmented Reality), Wearable technologies, Chatbots, Voice Assistants, IoT (Internet of Things) we have to start thinking about the user experience and how it will affect social and cultural interactions.
      
      If you would like to learn more about design and how to tackle this wicked problems of the new tech area will definitely recommend Conversation Design Institute. CDI team and especially Hans van Dam they do an amazing job at explaining Conversational Design, AI and Copywriting.
      
      Before taking the CDI certificate I would definitely recommend to study and to have a deep understanding of conversation design workflow with an extra focus on human-centricity, sample dialogue, flowcharting techniques, wizard-of-oz testing ,and handover design.
      
      I think learning about this new experiences is something healthy for juniors, intermediate and senior designers.
      `,
      cetificateImg: FrenklinCertificate,
      likesAndComments: '7 likes  | 0 comments',
    },
    {
      profileImg: RickProfile,
      name: 'Rick Witjes',
      job: 'Conversation Designer bij ABN AMRO Bank N.V.',
      date: '2w',
      message: `I've been working as a Conversation Designer for about a year now and I wanted to learn more about training the model. So I did! 
        Thanks Conversation Design Institute for the great course!`,
      cetificateImg: RickCertificate,
      likesAndComments: '32 likes  | 9 comments',
    },
    {
      profileImg: DarrellProfile,
      name: 'Darrell Keller',
      job: 'Multimodal conversational AI designer at Deloitte',
      date: '1mo',
      message: `After building 4 amazon #alexaskills on my own, I am now a certified conversation designer with the Conversation Design Institute! Big thank you to Daniel Padgett for recommending the courses to me and Olaf Igesz + Hans van Dam for putting the courses together. My next projects will be much better as a result. I am looking for work as a conversation designer, so please feel free to message me!
      #conversationdesign #chatbot #voicebot #cxdesign #vui #voicetech`,
      cetificateImg: DarrellCertificate,
      likesAndComments: '25 likes  | 1 comment',
      linkedInUrl: 'https://www.linkedin.com/posts/darrell-keller-640a35134_conversation-designer-certificate-activity-6805600421231505408-Cc8I/',
    },
    {
      profileImg: TenjaProfile,
      name: 'Tanja Veil',
      job: 'Conversational Copywriter at Swisscom',
      date: '5mo',
      message: `Thrilled to be a certified conversational copywriter as of today.🥳

      I thorougly enjoyed learning where I can improve the writing for our internal chatbot, especially in regards to the psychological aspects. I would absolutely recommend Conversation Design Institute’s courses.
      Now to write better copies for Swisscom‘s digital helper, Blu.🤖💪🏻
      
      #chatbot #conversationdesign #conversationalcopywriter #communication #lifelonglearner #learningatswisscom`,
      cetificateImg: TenjaCertificate,
      likesAndComments: '34 likes  | 14 comments',
    },
    {
      profileImg: OliveraProfile,
      name: 'Olivera Bay 🍁',
      job: 'CDI Certified AI UX Conversational Designer at Master of Code',
      date: '8mo',
      message: `🥁🥁🥁 Excited to announce That I’m officially a certified Conversation Designer with the Conversation Design Institute!!!
      👏🏼 A special thanks for Joseph Suskin for encouraging and mentoring me along the way ‼️
      And thank you to Jennifer Wang for enabling this training 😊
      Last but not least thank you to Hans van Dam for his teachings and course
      Now it’s full speed ahead 🤟🏼
      
      #canadianconversationdesigner
      #canadianchatbotdesigner
      #conversationdesigninstitute
      #kudostome
      `,
      cetificateImg: OliveraCertificate,
      likesAndComments: '105 likes  | 35 comments',
    },
    {
      profileImg: JanProfile,
      name: 'Jan Jurjević',
      job: 'Senior CX Consultant @ Infobip | Global Professional Services',
      date: '3w',
      message: `🎓 I have been through my share of corporate educations, but this hands down one of the best. Conversation Design Institutes Conversation Design is masterfully presented by Hans van Dam and provides great hands on advice on ways how to make bots speak human! 🤖

      #conversationdesign #cxdesign #infobip`,
      cetificateImg: JanCertificate,
      likesAndComments: '50 likes  | 3 comments',
    },
  ]

  return (
    <StyledAppPage>
      <TitleContainer>
        <TitleContent>
          <Text textAlign="center" style="headline2" color="primary2" marginBottom={10}>
            Home to the <br></br> conversational AI community
          </Text>
          <Text textAlign="center" style="heading7" color="primary2" opacity={70} marginBottom={30}>
            Learn employable skills, earn industry backed <br></br> certificates, find your next job or hire
          </Text>
          <StyledJoinForFreeButton
            icon="Arrow"
            textStyle="label9"
            iconSize={15}
            caption="Join for free"
            iconColor={colors.neutralWhite}
            onClick={() => {
              history.push('/register')
              window.dataLayer.push({
                event: 'Join for free clicked',
              })
            }}
          />
          <Text textAlign="center" style="label4" color="primary2" opacity={50} marginBottom={60}>
            Free access to trial courses, <br></br> community and job board.
          </Text>

          <StyledCompanyAccesButton
            caption="Request free company access"
            withUnderline
            onClick={() => {
              window.dataLayer.push({
                event: 'Request free company access clicked',
              })
              openLink(companyAccessLink)
            }}
          />
          <HomeImage src={HomeImg} />
        </TitleContent>
      </TitleContainer>
      <SubtitleContainer>
        <TitleContent>
          <Text textAlign="center" style="headline2" color="primary2" marginBottom={30}>
            Create human-centric <br></br> AI Assistants.
          </Text>
          <Text textAlign="center" style="heading7" color="primary2" opacity={70} marginBottom={30}>
            Learn proven principles to help you create <br></br> better experiences for chatbots, voice <br></br> assistants, robots, and the meta-verse.
          </Text>
          <StyledJoinForFreeLink
            caption="Join for free"
            withUnderline
            onClick={() => {
              window.dataLayer.push({
                event: 'Join for free clicked',
              })
              history.push('/register')
            }}
          />
        </TitleContent>
      </SubtitleContainer>
      <VideoContainer>
        <Video controls={isVideoPlaying} poster={VideoImg} onClick={() => (!isVideoPlaying ? setIsVideoPlaying(true) : undefined)}>
          <source src={IntroVideo} type="video/mp4" />
        </Video>
      </VideoContainer>
      <LevelUpContainer>
        <LevelUpHeaderContainer>
          <CDILandingLogoImage src={CDILandingLogoImg}></CDILandingLogoImage>
          <LevelUpText>Everything you need to level up your career in conversational AI</LevelUpText>
        </LevelUpHeaderContainer>
        <LevelUpItemsContainer>
          {levelUpData.map((item, index) => (
            <LevelUpItem key={item.header + index}>
              <LevelUpImage src={item.img} />
              <Text style="heading8" color="primary2" marginBottom={20}>
                {item.header}
              </Text>
              <LevelUpDescription>{item.description}</LevelUpDescription>
            </LevelUpItem>
          ))}
        </LevelUpItemsContainer>
      </LevelUpContainer>
      <FreeAccessContainer>
        <CutScreenImage src={CutScreen} />
        <FreeAccessContent>
          <USPSContainer>
            <USPSLine />
            <USPSHeader>
              Get <ColoredUSPHeader>free access</ColoredUSPHeader> to the CDI learning platform
            </USPSHeader>
            {USPS.map((usp, index) => (
              <USP key={usp + index}>
                <Icon size={24} color={colors.tertiary1} type="Done" />
                <USPText>{usp}</USPText>
              </USP>
            ))}
            <StyledFreeAccessButton
              icon="Arrow"
              textStyle="label9"
              iconSize={15}
              caption="Join for free"
              iconColor={colors.neutralWhite}
              onClick={() => {
                history.push('/register')
                window.dataLayer.push({
                  event: 'Join for free clicked',
                })
              }}
            />
            <StyledCompanyAccesButton2
              caption="Request free company access"
              withUnderline
              onClick={() => {
                window.dataLayer.push({
                  event: 'Request free company access clicked',
                })
                openLink(companyAccessLink)
              }}
            />
          </USPSContainer>
        </FreeAccessContent>
      </FreeAccessContainer>
      <FindJobContainer>
        <FindJobContent>
          <PhoneScreenImage src={PhoneScreen} />
          <Text style="headline3" color="primary2" textAlign="center" marginBottom={60}>
            Find your next job or <br></br> hire in our community
          </Text>
          <FindJobLinkSections>
            <FindJobLinkSection>
              <Text style="heading8" color="primary2" textAlign="center" marginBottom={10}>
                Looking for a job?
              </Text>
              <Text style="label11" color="secondary8" textAlign="center" marginBottom={30}>
                Find the right job for you at top companies from around the world.
              </Text>
              <StyledTertiaryButton
                variant={ButtonVariant.Tertiary}
                textColor="primary1"
                icon="Arrow"
                iconSize={15}
                textStyle="label9"
                caption="Find a job"
                onClick={() => {
                  history.push('/jobboard')
                  window.dataLayer.push({
                    event: 'Find a job clicked',
                  })
                }}
              />
            </FindJobLinkSection>
            <FindJobLinkSection>
              <Text style="heading8" color="primary2" textAlign="center" marginBottom={10}>
                Looking for the right hire?
              </Text>
              <Text style="label11" color="secondary8" textAlign="center" marginBottom={30}>
                Get access to top certified talent and find your next amazing employee.
              </Text>
              <StyledTertiaryButton
                variant={ButtonVariant.Tertiary}
                textColor="primary1"
                icon="Arrow"
                iconSize={15}
                textStyle="label9"
                caption="Post a job"
                onClick={() => {
                  history.push('/jobboard/post-job')
                  window.dataLayer.push({
                    event: 'Post a job clicked',
                  })
                }}
              />
            </FindJobLinkSection>
          </FindJobLinkSections>
        </FindJobContent>
      </FindJobContainer>
      <AlumniContainer>
        <AlumniContent>
          <Text style="headline3" color="primary2" textAlign="center" marginBottom={97}>
            What our alumni say
          </Text>
          <DesktopAlumni>
            {[0, 2, 5].map((colIndex, index) => (
              <DesktopAlumniCol key={colIndex + index}>
                {Alumni.slice(colIndex, colIndex + (colIndex === 2 ? 3 : 2)).map((person, personIndex) => (
                  <AlumniItem key={person.name + personIndex}>
                    <AlumniHeader>
                      <Avatar imgSrc={person.profileImg} size={AvatarSize.Medium} />
                      <div>
                        <Text style="label9" color="primary1" marginBottom={4}>
                          {person.name}
                        </Text>
                        <Text style="label8" color="secondary8" opacity={70} marginBottom={4}>
                          {person.job}
                        </Text>
                        <Text style="label3" color="secondary8" opacity={70}>
                          {person.date}
                        </Text>
                      </div>
                    </AlumniHeader>
                    <Line />
                    <Text style="label1" color="secondary9" marginBottom={30}>
                      {person.message}
                    </Text>
                    <CertificateImg src={person.cetificateImg} />
                    <AlumniBottom>
                      <Text style="label12" color="secondary8" opacity={70}>
                        {person.likesAndComments}
                      </Text>
                      {person.linkedInUrl && <Link textStyle="label12" caption="View on Linkedin" withUnderline onClick={() => openLink(person.linkedInUrl)} />}
                    </AlumniBottom>
                  </AlumniItem>
                ))}
              </DesktopAlumniCol>
            ))}
          </DesktopAlumni>
        </AlumniContent>
      </AlumniContainer>
      <ConnectWithPeopleContainer>
        <ConnectWithPeopleContent>
          <ConnectWithPeopleCard>
            <Text style="heading8" color="primary2" textAlign="center" marginBottom={30}>
              Connect with people who can help
            </Text>
            <StyledTertiaryButton
              variant={ButtonVariant.Tertiary}
              textColor="primary1"
              icon="Arrow"
              textStyle="label9"
              iconSize={15}
              caption="Find people"
              onClick={() => {
                history.push('/members')
                window.dataLayer.push({
                  event: 'Find people clicked',
                })
              }}
            />
            <ConnectWithPeopleImage src={ConnectWithPeopleImg} />
          </ConnectWithPeopleCard>
          <ConnectWithPeopleCard>
            <Text style="heading8" color="primary2" textAlign="center" marginBottom={30}>
              Learn the skills to succeed
            </Text>
            <StyledTertiaryButton
              variant={ButtonVariant.Tertiary}
              textColor="primary1"
              textStyle="label9"
              icon="Arrow"
              iconSize={15}
              caption="Find courses"
              onClick={() => {
                history.push('/shop')
                window.dataLayer.push({
                  event: 'Find courses clicked',
                })
              }}
            />
            <ConnectWithPeopleImage src={NewSkillsImg} />
          </ConnectWithPeopleCard>
        </ConnectWithPeopleContent>
      </ConnectWithPeopleContainer>
      <JoinUsContainer>
        <Text style="headline" color="neutralWhite" textAlign="center" marginBottom={10}>
          Join alumni, colleagues and peers <br /> in the CDI community now.
        </Text>
        <SignUpText>Sign up for free — and benefit directly from all free community perks.</SignUpText>
        <StyledGetStartedButton
          caption="Get started for free"
          icon="ArrowRight"
          iconColor={colors.neutralWhite}
          onClick={() => {
            history.push('/register')
            window.dataLayer.push({
              event: 'Get started for free clicked',
            })
          }}
        />
        <StyledCompanyAccesButton3
          caption="Request free company access"
          withUnderline
          onClick={() => {
            window.dataLayer.push({
              event: 'Request free company access clicked',
            })
            openLink(companyAccessLink)
          }}
        />
      </JoinUsContainer>
      <FooterContainer>
        <FooterContent>
          <LogoImage src={Logo} />
          <Text style="label8" color="neutralWhite" opacity={50}>
            © 2022 Conversation Design Institute B.V.
          </Text>

          <StyledButtonTerms>
            <a href="https://www.conversationdesigninstitute.com/Terms_Agreements">Terms &amp; conditions</a>
          </StyledButtonTerms>
          <StyledButtonTerms>
            <a href="https://www.conversationdesigninstitute.com/PrivacyPolicy">Privacy policy</a>
          </StyledButtonTerms>
        </FooterContent>
      </FooterContainer>
    </StyledAppPage>
  )
}

const StyledButtonTerms = styled.div`
  font-size: 15px;
  line-height: 22px;
  font-family: 'GT America Trial';
  font-weight: 400;
  -webkit-letter-spacing: -0.51px;
  -moz-letter-spacing: -0.51px;
  -ms-letter-spacing: -0.51px;
  letter-spacing: -0.51px;
  color: #ffffff;
  opacity: 50%;
`

const StyledAppPage = styled(AppPage)`
  padding-bottom: 0;
`

const TitleContainer = styled.div`
  padding-top: 60px;
  background: rgba(25, 32, 139, 0.05);
  ${mqFrom.L`
    padding-top: 90px;
  `}
`

const TitleContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
`

const StyledJoinForFreeButton = styled(Button)`
  margin: 0 auto 10px auto;
  height: 60px;
  min-width: 220px;
  justify-content: space-between;
`

const HomeImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: -9.9%;
`

const SubtitleContainer = styled.div`
  padding: 120px 0 160px 0;
  background: ${colors.neutralWhite};
  ${mqFrom.L`
    padding-top: 160px;
  `}
`
const StyledJoinForFreeLink = styled(Link)`
  max-width: 85px;
  margin: auto;
`

const StyledCompanyAccesButton = styled(Link)`
  margin: auto;
  max-width: 215px;
  margin-top: -20px;
`

const StyledCompanyAccesButton2 = styled(Link)`
  max-width: 215px;
  margin-top: 25px;
`

const StyledCompanyAccesButton3 = styled(Link)`
  margin: auto;
  max-width: 180px;
  color: white;
  margin-top: 25px;

  p {
    color: white;
    font-size: 14px;
  }
`

const VideoContainer = styled.div`
  width: 100%;
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #eff2f3 50%, #eff2f3 100%);
  padding: 0 15px;
`

const Video = styled.video`
  width: 100%;
  max-width: 970px;
  display: flex;
  margin: 0 auto;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
`

const LevelUpContainer = styled.div`
  padding: 200px 15px 100px 15px;
  background: ${colors.backgroundPrimary};
  ${mqFrom.L`
    padding: 240px 0 130px 0;
  `}
`

const LevelUpHeaderContainer = styled.div`
  display: flex;
  max-width: 970px;
  margin: 0 auto 30px auto;
  gap: 50px;
  flex-direction: column;
  align-items: center;
  ${mqFrom.L`
    gap: 130px;
    flex-direction: row;
  `}
`

const LevelUpText = styled.div`
  ${applyTextStyle('headline3')};
  margin-bottom: 90px;
`

const LevelUpItemsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  max-width: 970px;
  margin: 0 auto;
  gap: 60px 30px;
  @media screen and (max-width: 900px) {
    justify-content: center;
  }
`

const LevelUpItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  :first-child {
    visibility: hidden;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`

const LevelUpImage = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
`

const LevelUpDescription = styled.div`
  ${applyTextStyle('label11')};
  color: ${colors.secondary8};
`

const CDILandingLogoImage = styled.img`
  width: 370px;
  height: 368.12px;
  box-shadow: 0 7px 20px 0 rgba(48, 49, 50, 0.34), 22px 22px 100px 0 rgba(84, 84, 84, 0.56);
  background: ${colors.backgroundPrimary};
  border-radius: 72px;
`

const FreeAccessContainer = styled.div`
  position: relative;
  padding: 194px 15px 314px 15px;
  background: ${colors.neutralWhite};
  ${mqFrom.L`
    padding: 194px 15px 314px 15px;
  `}
`

const CutScreenImage = styled.img`
  position: absolute;
  width: 600.29px;
  height: 730px;
  height: auto;
  right: 0px;
  top: 0;
`

const FreeAccessContent = styled.div`
  max-width: 970px;
  margin: 0 auto;
`

const USPSContainer = styled.div`
  max-width: 470px;
  margin-top: 400px;

  @media screen and (min-width: 450px) {
    margin-top: 600px;
  }
  @media screen and (min-width: 600px) {
    margin-top: 700px;
  }
  @media screen and (min-width: 1200px) {
    margin-top: 0px;
  }
`

const USPSLine = styled.div`
  width: 100%;
  height: 4px;
  background: ${colors.tertiary1};
  margin-bottom: 48px;
`

const USPSHeader = styled.div`
  ${applyTextStyle('headline3')};
  margin-bottom: 31px;
  color: ${colors.primary2};
`

const ColoredUSPHeader = styled.span`
  color: ${colors.tertiary1};
`

const USP = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const USPText = styled.div`
  margin-left: 18px;
  ${applyCustomTextStyle({
    mobile: {
      fontSize: 18,
      fontWeight: FontWeight.Medium,
      lineHeight: 21,
      letterSpacing: -0.56,
      fontFamily: FontFamily.GTAmericaTrial,
    },
  })}
`

const StyledFreeAccessButton = styled(Button)`
  margin: 61 auto 10px auto;
  height: 60px;
  min-width: 220px;
  justify-content: space-between;
`

const FindJobContainer = styled.div`
  background: ${colors.neutralWhite};
`

const FindJobContent = styled.div`
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
  background: ${colors.backgroundPrimary};
  padding: 264px 15px 160px 15px;
`

const PhoneScreenImage = styled.img`
  position: absolute;
  width: 420px;
  top: -335px;
  left: 50%;
  transform: translateX(-50%);
`

const FindJobLinkSections = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
`

const FindJobLinkSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 370px;
`

const StyledTertiaryButton = styled(Button)`
  border: 1px solid ${colors.primary1};
  height: 60px;
  min-width: 170px;
  justify-content: space-between;
`

const AlumniContainer = styled.div`
  background: ${colors.neutralWhite};
  padding: 119px 15px 121px 15px;
`
const AlumniContent = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`
const DesktopAlumni = styled.div`
  display: flex;
  gap: 30px;
  overflow: auto;
`

const DesktopAlumniCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const AlumniItem = styled.div`
  padding: 30px;
  background: ${colors.backgroundPrimary};
  width: 370px;
`

const AlumniHeader = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
`

const CertificateImg = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
`

const AlumniBottom = styled.div`
  display: flex;
  padding: 13px 0 5px 0;
  border-top: 1px solid ${colors.secondary6};
  justify-content: space-between;
`

const ConnectWithPeopleContainer = styled.div`
  background: ${colors.neutralWhite};
  padding: 0 15px 121px 15px;
`

const ConnectWithPeopleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  max-width: 1170px;
  ${mqFrom.M`
    flex-direction: row;
  `}
  align-items: center;
`

const ConnectWithPeopleCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.backgroundPrimary};
  padding: 60px 20px;
  width: 100%;
  max-width: 570px;
  align-items: center;
`

const ConnectWithPeopleImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: 60px;
  max-width: 348px;
  max-height: 160px;
`

const JoinUsContainer = styled.div`
  background: #2c34a8 url(${banner}) no-repeat center center;
  padding: 106px 15px 60px 15px;
`

const SignUpText = styled.div`
  ${applyCustomTextStyle({
    mobile: {
      fontFamily: FontFamily.GTAmericaTrial,
      fontSize: 19,
      lineHeight: 33,
      letterSpacing: -0.24,
    },
  })}
  text-align: center;
  margin-bottom: 28px;
  color: ${colors.neutralWhite};
`

const StyledGetStartedButton = styled(Button)`
  margin: 0 auto;
`

const FooterContainer = styled.div`
  background: ${colors.primary1};
  padding: 30px 15px;
`

const FooterContent = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`

const LogoImage = styled.img`
  width: 217.33px;
  height: 48px;
`

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.secondary6};
  margin-bottom: 30px;
`

export default LandingPage
