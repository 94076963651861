import strapi from '../../../helpers/strapi.helper'
import { STRIPE_SETUP } from '../../../constants/api'
import { PaymentType } from '../../../pages/post-job'

export default async (paymentType: PaymentType, jobId: string) =>
  await strapi.request('POST', STRIPE_SETUP, {
    data: {
      paymentType: paymentType,
      jobId: jobId
    },
  })
