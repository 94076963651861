import { Color } from '../common/models/colors'

/**
 * Colors
 */
export default {
  primary1: '#020447',
  primary2: '#0F1332',
  primary3: '#2C34A8',
  primary4: '#19208B',
  primary5: '#2C34A8F2',
  secondary1: '#9A9AAA',
  secondary2: '#D0D6D0',
  secondary3: '#FFFFFF80',
  secondary4: '#BFBFBF',
  secondary5: '#f5f5f5',
  secondary6: '#D8D8D8',
  secondary7: '#626386',
  secondary8: '#343455',
  secondary9: '#51546C',
  tertiary1: '#23CC7B',
  tertiary2: '#00E6AA',
  tertiary3: '#F8BF00',
  tertiary4: '#E75725',
  tertiary5: '#069772',
  neutralWhite: '#FFFFFF',
  backgroundPrimary: '#EFF2F3',
  transparent: 'transparent',
  shadow: '#19208B26;',
}

/**
 * Some components allow color customization. Since that's
 * optional, use this as a default fallback color in case
 * it's unset
 */
export const defaultColor: Color = 'primary1'

export const defaultTextColor: Color = 'primary1'
