import React from 'react'
import { useAppState } from '../context/AppContext'
import Filters, { IFilter } from './Forms/Filters'

interface IProps {
  filteredCourses: (jobs: Course[]) => void
}

const CoursesFilter: React.FC<IProps> = ({ filteredCourses }) => {
  const appState = useAppState()
  const courses = appState.courses

  // const instructors: string[] = courses && courses.length > 0 ? [...Array.from(new Set([...courses.flatMap((course) => course.instructor_name)]))] : []

  const categories: string[] =
    courses && courses.length > 0 ? [...Array.from(new Set([...courses.flatMap((course) => course.category || '')]))].filter((item) => item != '') : []
  // const levels: string[] =
  //   courses && courses.length > 0 ? [...Array.from(new Set([...courses.flatMap((course) => course.level || '')]))].filter((item) => item != '') : []
  // const types: string[] =
  //   courses && courses.length > 0
  //     ? [...Array.from(new Set([...courses.flatMap((course) => course.type?.toLocaleLowerCase() || '')]))].filter((item) => item != '')
  //     : []

  const filters: IFilter[] = [
    { header: 'Certificates', fields: ['Has certificates'], selectOnlyOne: false },
    // { header: 'Average completion time', fields: ['1 day', '1 week', '1 month', 'one month plus'], selectOnlyOne: true },
    // { header: 'Instructor name', fields: instructors, selectOnlyOne: false },
    // { header: 'Price', fields: ['free', '< $150', '< $500', '< $2000'], selectOnlyOne: true },
    { header: 'Category', fields: categories, selectOnlyOne: false },
    // { header: 'Level', fields: levels, selectOnlyOne: true },
    // { header: 'Type', fields: types, selectOnlyOne: true },
  ]

  // const checkPrice = (coursePrice: number, index: number) => {
  //   if (index === 0) {
  //     return coursePrice === 0
  //   } else if (index === 1) {
  //     return coursePrice < 150
  //   } else if (index === 2) {
  //     return coursePrice < 500
  //   } else if (index === 3) {
  //     return coursePrice < 2000
  //   }
  // }

  // const checkCompletionTime = (completionTimeInMinutes: number, index: number) => {
  //   const minutesInADay = 60 * 60
  //   const minutesInAWeek = minutesInADay * 7
  //   const minutesInAMonth = minutesInAWeek * 4
  //   if (index === 0) {
  //     return completionTimeInMinutes >= 0 && completionTimeInMinutes <= minutesInADay
  //   } else if (index === 1) {
  //     // less than week
  //     return completionTimeInMinutes > minutesInADay && completionTimeInMinutes <= minutesInAWeek
  //   } else if (index === 2) {
  //     // less than month
  //     return completionTimeInMinutes > minutesInAWeek && completionTimeInMinutes <= minutesInAMonth
  //   } else if (index === 4) {
  //     // more than month
  //     return completionTimeInMinutes > minutesInAMonth
  //   }
  // }

  const handleFilter = (values: boolean[][]) => {
    const hasCetificates = values[0][0]
    // const selectedvarageCompletionTimeIndex = values[1].findIndex((time) => time)
    // const selectedInstructors = instructors.filter((instructor, index) => (values[2][index] ? instructor : null))
    // const selectedPriceIndex = values[3].findIndex((price) => price)

    // 
    const selectedCategories = categories.filter((category, index) => (values[1][index] ? category : null))
    // const selectedLevels = categories.filter((level, index) => (values[5][index] ? level : null))
    // const selectedTypes = types.filter((type, index) => (values[6][index] ? type : null))

    if (courses) {
      const coursesToReturn = courses
        .filter((course) => (!hasCetificates ? course : course.has_certificate))
        // .filter((course) =>
        //   selectedvarageCompletionTimeIndex !== -1 ? checkCompletionTime(course.average_completion_time_in_min || 0, selectedvarageCompletionTimeIndex) : course
        // )
        // .filter((course) => (selectedInstructors.length > 0 ? selectedInstructors.includes(course.instructor_name) : course))
        // .filter((course) => (selectedPriceIndex !== -1 ? checkPrice(course.course_price ? parseFloat(course.course_price) : 0, selectedPriceIndex) : course))
        .filter((course) => (selectedCategories.length && course.category ? selectedCategories.includes(course.category) : course))
      // .filter((course) => (selectedLevels.length > 0 && course.level ? selectedLevels.includes(course.level) : course))
      // .filter((course) => (selectedTypes.length > 0 && course.type ? selectedTypes.includes(course.type) : course))

      filteredCourses(coursesToReturn)
    }
  }

  return <Filters filters={filters} passValues={(values) => handleFilter(values)}></Filters>
}

export default CoursesFilter
