import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../styles/colors'

interface IProps {
  size: number
  imgSrc?: string
  className?: string
}

const Logo: React.FC<IProps> = ({ size, imgSrc, className }) => {
  return (
    <LogoContainer size={size} className={className}>
      {imgSrc && <Image size={size} src={imgSrc} />}
      {!imgSrc && <DefaultImage size={size} />}
    </LogoContainer>
  )
}

const LogoContainer = styled.div<{ size: number }>`
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`

const DefaultImage = styled.div<{ size: number }>`
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
  /* background-color: ${colors.secondary2}; */
  border: 1px solid ${colors.secondary2};
  flex-shrink: 0;
`

const Image = styled.img<{ size: number }>`
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
  /* background-color: ${colors.secondary2}; */
  border: 1px solid ${colors.secondary2};
  flex-shrink: 0;
`

export default Logo
