import React, { useEffect, useState } from 'react'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import Text from '../components/Text'
import styled from 'styled-components'
import { mqFrom } from '../styles/responsive'
import MultiSections from '../components/MultiSections'
import CoursesList from '../components/CoursesList'
import colors from '../styles/colors'
import { IconType } from '../components/icon/IconType'
import Icon from '../components/icon/Icon'
import Section from '../components/Section'
import DropdownSelect from '../components/DropdownSelect'
import { useAppState } from '../context/AppContext'
import CoursesFilter from '../components/CoursesFilter'
import { certificates } from '../common/models/certificates'
import CertificateCardsList from '../components/CertificateCardsList'
import { Helmet } from 'react-helmet'
import Button, { ButtonVariant } from '../components/Button'
import LinkWithArrow from '../components/LinkWithArrow'
import { useParams } from 'react-router-dom'
import CourseCard from '../components/cards/CourseCard'
import { CourseCategories } from '../enums/courseCategories.enum'

interface ISocialProofSentence {
  iconType: IconType
  sentence: string
}

const CoursesPage: React.FC = () => {
  const appState = useAppState()
  const courses = appState.courses
  const sections = ['CDI Courses', 'Partner Courses', 'Certificate exams']
  const [activeSection, setActiveSection] = useState<number>(0)
  const allCoursesHeaders = ['Most popular', 'All']
  const [filteredCourses, setFilteredCourses] = useState<Course[]>(appState.courses || [])
  const [isAllCoursesDropdownVisible, setIsAllCoursesDropdownVisible] = useState<boolean>(false)
  const [activeAllCoursesHeader, setActiveAllCoursesHeader] = useState<number>(0)

  const [page, setPage] = useState<number>(0)
  const [filteredCDICoursesOnTop, setFilteredCDICoursesOnTop] = useState<Course[]>()

  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches)
  const params: { course: string } = useParams()

  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change', (e) => setMatches(e.matches))
  }, [])

  useEffect(() => {
    setFilteredCourses(appState.courses || [])
  }, [appState.courses])

  useEffect(() => {
    if (courses && courses?.length > 0) {
      setFilteredCDICoursesOnTop(courses?.filter((course) => course.enabled && course.category === CourseCategories.CDICourses).slice(page, page + 4) || [])
    }
  }, [page, courses, activeSection])

  useEffect(() => {
    if (params.course && courses) {
      const selectedCourse = courses?.filter((course) => course.name === params.course)
      setFilteredCourses(selectedCourse.length === 1 && selectedCourse[0].enabled ? selectedCourse : courses)
      const allCourses = document.getElementById('all-courses')
      allCourses?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }, [params.course])

  const socialProofSentences: ISocialProofSentence[] = [
    {
      iconType: 'Forum',
      sentence: 'Join a community of more than 5000 people',
    },
    {
      iconType: 'BeenHere',
      sentence: 'The leading conversational AI certificates',
    },
    {
      iconType: 'TumbUp',
      sentence: 'Level up your carreer and be in high demand',
    },
  ]

  const getSection = () => {
    const section = sections[activeSection]

    if (section === 'CDI Courses') {
      return <CoursesList xsColSpan={2} mColSpan={6} courses={filteredCDICoursesOnTop}></CoursesList>
    } else if (section === 'Partner Courses') {
      return (
        <AditionalCoursesContainer>
          {courses
            ?.filter((course) => course.category === CourseCategories.AdditionalCourses)
            ?.map((course) => course.enabled && <StyledCourseCard key={course.name} course={course}></StyledCourseCard>)}
        </AditionalCoursesContainer>
      )
    } else if (section === 'Certificate exams') {
      return <CertificateCardsList certificates={certificates} />
    } else if (section === 'Live Sessions') {
      return <EmptySection></EmptySection>
    }
  }

  return (
    <AppPage>
      <Helmet>
        <meta property="og:title" content="Conversational Design Institute Courses" />
        <meta property="og:description" content="Conversational Experience Courses. Courses to get you started" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content="english" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={process.env.REACT_APP_CDI_LOGO_URL} />
      </Helmet>
      <AppTop hideLine inverted={true}>
        <Text style="headline" color="primary2" marginBottom={4}>
          Conversational Experience Courses
        </Text>
        <Text style="heading2" color="primary1">
          Courses to get you started
        </Text>
      </AppTop>
      <StyledAppContainer>
        <MultiSections
          withLeftAllign
          activeHeader={activeSection}
          onHeaderClick={(index) => {
            setPage(0)
            setActiveSection(index)
          }}
          headers={sections}
          align={false}
        >
          {getSection()}
          {courses && courses?.length > 4 && filteredCDICoursesOnTop && filteredCDICoursesOnTop.length === 4 && courses.slice(page + 4, page + 8).length !== 0 && (
            <ButtonWrapper>
              <LinkWithArrow caption="" onClick={() => setPage(page + 4)} />
            </ButtonWrapper>
          )}
          {filteredCDICoursesOnTop && page >= 4 && (
            <ButtonWrapper>
              <LinkWithArrow caption="" iconType="ArrowLeft" onClick={() => setPage(page - 4)} />
            </ButtonWrapper>
          )}
        </MultiSections>
      </StyledAppContainer>
      <StyledSocialProofAppContainer>
        <SocialProofWrapper>
          {socialProofSentences.map((item, index) => (
            <SocialProofItem key={index}>
              <Icon type={item.iconType} size={32} color={colors.tertiary1} />
              <Text marginLeft={20} style="heading3" color="primary2">
                {item.sentence}
              </Text>
            </SocialProofItem>
          ))}
        </SocialProofWrapper>
      </StyledSocialProofAppContainer>
      <AllCoursesAppContainer>
        <AllCoursesTextsContainer id="all-courses">
          <Text style="headline" color="primary2" marginBottom={3}>
            All courses
          </Text>
          <Text style="heading2" color="primary1" marginBottom={27}>
            Not sure? We have a 30 day money back guarantee!
          </Text>
          <Line />
        </AllCoursesTextsContainer>
        <AllCoursesContainer>
          <CoursesFilter filteredCourses={(courses) => setFilteredCourses(courses)} />
          <Section
            clickTopSection={() => setIsAllCoursesDropdownVisible(!isAllCoursesDropdownVisible)}
            isTopSectionCursorPointer={true}
            customAction={
              <>
                <StyledButton
                  caption={allCoursesHeaders[activeAllCoursesHeader]}
                  variant={ButtonVariant.Tertiary}
                  textStyle="label8"
                  textColor="primary2"
                  icon="ArrowDown"
                />
                <DropdownSelect
                  headers={allCoursesHeaders}
                  setActiveHeader={(index) => setActiveAllCoursesHeader(index)}
                  isVisible={isAllCoursesDropdownVisible}
                  top={46}
                  onOutsideClick={() => setIsAllCoursesDropdownVisible(false)}
                ></DropdownSelect>
              </>
            }
          >
            <CoursesList xsColSpan={2} mColSpan={4} courses={filteredCourses} all={matches ? true : false}></CoursesList>
          </Section>
        </AllCoursesContainer>
      </AllCoursesAppContainer>
    </AppPage>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-bottom: 86px;
  ${mqFrom.L`
    margin-bottom: 73px;
  `}
`

const StyledSocialProofAppContainer = styled(AppContainer)`
  background-color: ${colors.neutralWhite};
  padding-top: 55px;
  padding-bottom: 31px;
  max-width: unset;
  ${mqFrom.L`
    padding-top: 50px;
    padding-bottom: 14px;
  `}
`

const SocialProofWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1140px;
  margin: 0 auto;
`

const SocialProofItem = styled.div`
  display: flex;
  width: 300px;
  align-items: center;
  margin-bottom: 30px;

  ${mqFrom.S`
    width: 350px;
  `}
`

const AllCoursesAppContainer = styled(AppContainer)`
  margin-top: 42px;
  ${mqFrom.L`
    margin-top: 72px;
  `}
`

const AllCoursesTextsContainer = styled.div`
  margin-bottom: 52px;

  ${mqFrom.L`
    margin-top: 65px;
  `}
`

const AllCoursesContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mqFrom.L`
    flex-direction: row;
  `}
`

const EmptySection = styled.div`
  height: 478px;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.secondary6};
`

const StyledButton = styled(Button)`
  margin-bottom: 30px;
`

const ButtonWrapper = styled.div`
  display: flex;
  float: right;
`

const AditionalCoursesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
`

const StyledCourseCard = styled(CourseCard)`
  width: 254px;
`

export default CoursesPage
