import React, { useEffect, useState } from 'react'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import Text from '../components/Text'
import styled from 'styled-components'
import Button from '../components/Button'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import FormikInput from '../components/Forms/formControls/formik/FormikInput'
import { useAppDispatch, useAppState } from '../context/AppContext'
import registerUserService from '../context/services/registerUser.service'
import { Helmet } from 'react-helmet'
import SocialButtons from '../components/SocialButtons'
import { FontWeight } from '../common/models/typography'
import Icon from '../components/icon/Icon'
import colors from '../styles/colors'
import RegistrationCertificateImg from '../images/register-certification.jpg'
import { InputVariant } from '../common/models/FormControlProps'
import { mqFrom } from '../styles/responsive'
import { submitToHubSpotForm } from '../helpers/hubspot'

interface IRegisterForm {
  first_name: string
  last_name: string
  email: string
  password: string
  passwordConfirmation: string
}

const includedForFreeContent = [
  'Start with 2 free courses',
  'Access to 25+ courses and certificates',
  'Connect to peers and companies worldwide',
  'Find jobs and hirees in our community',
]

const RegisterPage: React.FC = () => {
  const appDispatch = useAppDispatch()
  const history = useHistory()
  const appState = useAppState()
  const isAuthenticated = !!appState.userAuthToken
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard')
    }
  }, [isAuthenticated])

  const [registerProgress, setRegisterProgress] = useState<boolean>(false)

  const registerInitialValues: IRegisterForm = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
  }

  const registerValidationSchema = Yup.object({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().required('Required').email('Please provide valid email address'),
    password: Yup.string().min(8, 'At least 8 characters').required('Required'),
    passwordConfirmation: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })

  const register = async (values: IRegisterForm) => {
    setRegisterProgress(true)
    const hubspotCookie = Cookies.get('hubspotutk')
    console.log(hubspotCookie)
    submitToHubSpotForm(values, hubspotCookie)

    const registerResponse: any = await registerUserService({
      ...values,
      full_name: `${values.first_name} ${values.last_name}`,
      username: values.email.toLowerCase(),
      email: values.email.toLowerCase(),
      public_setting: false,
      registered_from_cdi_hub: true,
      user_created_at: new Date(),
    }).catch((e: any) => {
      appDispatch({
        type: 'updateModalWindow',
        payload: { content: e.message, dismissText: 'ok' },
      })
    })

    if (registerResponse && registerResponse.user) {
      // appDispatch({
      //   type: 'updateModalWindow',
      //   payload: { content: `Your account has been created successfully.`, dismissText: 'ok' },
      // })
      window.dataLayer.push({
        event: 'User registered',
      })
      history.push(`/login?username=${encodeURIComponent(values.email.toLowerCase())}&password=${values.password}`)
    }
    setRegisterProgress(false)
  }

  return (
    <Formik initialValues={registerInitialValues} validationSchema={registerValidationSchema} onSubmit={register}>
      {({ submitForm }) => (
        <AppPage>
          <Helmet>
            <meta property="og:title" content="Conversational Design Institute Register" />
            <meta property="og:description" content="Conversation Design Institute. Signup to get access to our learner portal." />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:locale" content="english" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={process.env.REACT_APP_CDI_LOGO_URL} />
          </Helmet>
          <AppTop inverted hideLine>
            <Text style="headline" color="primary2" marginBottom={15} marginTop={50} fontWeight={FontWeight.Bold}>
              Join the #1 Conversational AI <br /> community today
            </Text>
            <Text style="heading10" color="primary2" opacity={70}>
              Learn proven principles to help you create better <br /> experiences for chatbots, voice assistants, robots, and <br /> the meta-verse.
            </Text>
          </AppTop>

          <StyledAppContainer>
            <RegisterTexts>
              <Included>
                <Text style="heading7" color="primary2" marginBottom={30}>
                  Included for free
                </Text>
                {includedForFreeContent.map((item) => (
                  <IncludedItem key={item}>
                    <Icon type="Done" size={20} color={colors.tertiary1} />
                    <Text style="heading1" color="primary2" forceStyle="mobile">
                      {item}
                    </Text>
                  </IncludedItem>
                ))}
              </Included>
              <CertificationImg src={RegistrationCertificateImg} />
              <Text style="heading7" color="primary2" marginBottom={21}>
                Join alumni, colleagues and peers in the CDI community now
              </Text>
              <Text style="heading9" color="secondary8">
                The CDI Learning platform is the home of the Conversational AI community. Learn employable skills, earn industry-backed certificates and find
                your next job or hire. Join now for free!
              </Text>
            </RegisterTexts>
            <FormContainer>
              <Text style="heading7" color="primary2" marginBottom={10}>
                Sign up now. It’s free!
              </Text>
              <ToRegisterLink>
                <Text style="label8" color="primary2" marginRight={5}>
                  Already a member?
                </Text>
                <Text style="label8" color="primary2" isCursorPointer={true} withUnderline onClick={() => history.push('/login')}>
                  Sign in!
                </Text>
              </ToRegisterLink>
              <Form>
                <FormikInput variant={InputVariant.Secondary} name="first_name" placeholder="Enter your first name" label="First name" marginBottom={20} />
                <FormikInput variant={InputVariant.Secondary} name="last_name" placeholder="Enter your last name" label="Last name" marginBottom={20} />
                <FormikInput variant={InputVariant.Secondary} name="email" placeholder="Enter your email" label="Email" marginBottom={20} />
                <FormikInput
                  variant={InputVariant.Secondary}
                  name="password"
                  placeholder="Enter your password"
                  label="Password"
                  marginBottom={20}
                  type="password"
                />
                <FormikInput
                  variant={InputVariant.Secondary}
                  name="passwordConfirmation"
                  placeholder="Confirm your password"
                  label="Password confirmation"
                  marginBottom={20}
                  type="password"
                />

                <StyledRegisterButton
                  type="submit"
                  disabled={registerProgress}
                  showLoader={registerProgress}
                  onClick={submitForm}
                  caption="Join now  "
                  icon="Arrow"
                  iconSize={15}
                  iconColor={colors.neutralWhite}
                  justifyInnerContent="space-between"
                  textStyle="label9"
                />
              </Form>
              <Text marginBottom={19} style="label8" color="primary1">
                Or sign up with:
              </Text>
              <SocialButtons inverted />
              <Text marginTop={19} marginBottom={-20} textAlign={'center'} opacity={20} style="label8" color="primary1">
                By registering, you agree to our <a href="https://www.conversationdesigninstitute.com/Terms_Agreements">Terms &amp; conditions</a> and our{' '}
                <a href="https://www.conversationdesigninstitute.com/PrivacyPolicy">Privacy policy</a>
              </Text>
            </FormContainer>
          </StyledAppContainer>
        </AppPage>
      )}
    </Formik>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
`

const RegisterTexts = styled.div`
  max-width: 470px;
  margin-right: auto;
`

const Included = styled.div`
  margin-bottom: 61px;
`

const IncludedItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 18px;
  align-items: center;
`

const CertificationImg = styled.img`
  margin-bottom: 60px;
`

const FormContainer = styled.div`
  max-width: 470px;
  height: 100%;
  background-color: ${colors.neutralWhite};
  border-top: 4px solid ${colors.tertiary1};
  margin-left: auto;
  padding: 19px 15px 15px 15px;
  ${mqFrom.S`
    padding: 64px 60px 60px 60px;
  `}
`

const StyledRegisterButton = styled(Button)`
  width: 100%;
  height: 60px;
  margin-bottom: 60px;
`

const ToRegisterLink = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`

export default RegisterPage
