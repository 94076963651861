import chatbot from '../../images/interfaces/chatbot.png'
import other from '../../images/interfaces/other.svg'
import voice from '../../images/interfaces/voice.png'
import whatsapp from '../../images/interfaces/whatsapp.png'
import IVR from '../../images/interfaces/ivr.png'
import SocialMedia from '../../images/interfaces/social-media.png'
import MultiModal from '../../images/interfaces/multi-modal.png'
import virtual from '../../images/interfaces/virtual.png'

export interface Interface {
  name: string
  img: string
  active: boolean
  forceSmall?: boolean
}
export const interfaces: Interface[] = [
  {
    name: 'Chatbot',
    img: chatbot,
    active: false,
  },
  {
    name: 'Voice',
    img: voice,
    active: false,
  },
  {
    name: 'Multi-modal',
    img: MultiModal,
    active: false,
  },
  {
    name: 'IVR',
    img: IVR,
    active: false,
  },
  {
    name: 'WhatsApp',
    img: whatsapp,
    active: false,
    forceSmall: true,
  },
  {
    name: 'Social media',
    img: SocialMedia,
    active: false,
  },
  {
    name: 'Virtual beings',
    img: virtual,
    active: false,
  },
  {
    name: 'Other',
    img: other,
    active: false,
  },
]
