import { FieldMetaProps } from 'formik'

export enum InputVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type InputVariantType = 'primary' | 'secondary'
export interface FormControlProps {
  className?: string
  placeholder?: string
  label?: string // The label above the field
  defaultValue?: string
  marginBottom?: number
  errorMessage?: string
  name?: string
  meta?: FieldMetaProps<any>
  onChange?: (e?: any) => void
  handleChange?: (e?: any) => void
  value?: string
  variant?: InputVariantType
}
