import React from 'react'
import styled from 'styled-components'
import {
  IconAdd,
  IconArrowDown,
  IconArrowLeft,
  IconArrowUp,
  IconArrowRight,
  IconAwesome,
  IconBeenHere,
  IconClose,
  IconDone,
  IconDoneOutline,
  IconEdit,
  IconFilterList,
  IconForum,
  IconGrade,
  IconLanguage,
  IconLocalOffer,
  IconMenu,
  IconMilitaryTech,
  IconNewReleases,
  IconPeople,
  IconPermIdentity,
  IconSearch,
  IconStar,
  IconTrophy,
  IconTumbUp,
  IconVerified,
  IconTwitter,
  IconLinkedin,
  IconArrow,
  IconWritingPen,
  IconInfo,
  IconWarning,
  IconError,
  IconLock,
} from '.'
import { IconType } from './IconType'

interface IProps {
  type: IconType
  size?: number
  width?: number
  height?: number
  color?: string
  className?: string
  onClick?: () => void
}

interface IconProps extends IProps {
  color: string
  size: number
}
interface IconPropsMore extends IProps {
  width: number
  height: number
}

const Icon = (props: IProps) => {
  const size = props.size !== undefined ? props.size : 13
  const width = props.width !== undefined ? props.width : size
  const height = props.height !== undefined ? props.height : size
  const color = props.color ? props.color : 'black'
  return (
    <IconWrapper className={props.className} onClick={props.onClick} size={size} width={width} height={height}>
      {props.type === 'TumbUp' && <IconTumbUp size={size} color={color} />}
      {props.type === 'Forum' && <IconForum size={size} color={color} />}
      {props.type === 'BeenHere' && <IconBeenHere size={size} color={color} />}
      {props.type === 'FilterList' && <IconFilterList size={size} color={color} />}
      {props.type === 'ArrowDown' && <IconArrowDown size={size} color={color} />}
      {props.type === 'ArrowLeft' && <IconArrowLeft size={size} color={color} />}
      {props.type === 'ArrowUp' && <IconArrowUp size={size} color={color} />}
      {props.type === 'ArrowRight' && <IconArrowRight size={size} color={color} />}
      {props.type === 'Done' && <IconDone size={size} color={color} />}
      {props.type === 'Verified' && <IconVerified size={size} color={color} />}
      {props.type === 'Star' && <IconStar size={size} color={color} />}
      {props.type === 'Search' && <IconSearch size={size} color={color} />}
      {props.type === 'PermIdentity' && <IconPermIdentity size={size} color={color} />}
      {props.type === 'People' && <IconPeople size={size} color={color} />}
      {props.type === 'NewReleases' && <IconNewReleases size={size} color={color} />}
      {props.type === 'MilitaryTech' && <IconMilitaryTech size={size} color={color} />}
      {props.type === 'Menu' && <IconMenu size={size} color={color} />}
      {props.type === 'LocalOffer' && <IconLocalOffer size={size} color={color} />}
      {props.type === 'Language' && <IconLanguage size={size} color={color} />}
      {props.type === 'Twitter' && <IconTwitter size={size} color={color} />}
      {props.type === 'Linkedin' && <IconLinkedin size={size} color={color} />}
      {props.type === 'Grade' && <IconGrade size={size} color={color} />}
      {props.type === 'Trophy' && <IconTrophy size={size} color={color} />}
      {props.type === 'Edit' && <IconEdit size={size} color={color} />}
      {props.type === 'DoneOutline' && <IconDoneOutline size={size} color={color} />}
      {props.type === 'Close' && <IconClose size={size} color={color} />}
      {props.type === 'Awesome' && <IconAwesome size={size} color={color} />}
      {props.type === 'Add' && <IconAdd size={size} color={color} />}
      {props.type === 'Arrow' && <IconArrow size={size} color={color} />}
      {props.type === 'WritingPen' && <IconWritingPen size={size} color={color} />}
      {props.type === 'Info' && <IconInfo size={size} color={color} />}
      {props.type === 'Warning' && <IconWarning size={size} color={color} />}
      {props.type === 'Error' && <IconError size={size} color={color} />}
      {props.type === 'Lock' && <IconLock size={size} color={color} />}
    </IconWrapper>
  )
}

export const IconWrapper = styled.span<Pick<IconProps | IconPropsMore, 'size' | 'width' | 'height'>>`
  position: relative;
  display: inline-block;
  width: ${(props) => (props.width == undefined ? props.size : props.width)}px;
  height: ${(props) => (props.height == undefined ? props.size : props.height)}px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;

    path {
      transition: fill 0.3s ease;
    }
  }
`

export default Icon
