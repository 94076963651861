export const EDIT_USER = (userId: string) => `/users/${userId}`
export const JOB = '/jobs?_limit=-1'
export const COMPANY = '/companies'
export const UPLOAD = '/upload'
export const COMPANY_AUTOCOMPLETE = (companyName: string) => `https://autocomplete.clearbit.com/v1/companies/suggest?query=:${companyName}`
export const COURSE = '/courses'
export const NOTIFICATION = '/private-notifications'
export const EDIT_NOTIFICATION = (notification_id: string) => `/notifications/${notification_id}`
export const USERS = '/users'
export const POSTS = '/posts'
export const PUBLIC = '/public'
export const SSO = '/sso-link'
export const ENROLLEMENTS = 'private-enrollments'
export const DISCOURSE_SSO = '/discourse-sso'
export const STRIPE_SETUP = '/stripe-setup'
export const STRIPE_GET_NEW_PORTAL = '/stripe-portal'
export const REGISTER_USER = '/register'
export const REGISTER_ENTERPRISE = '/enterprise'
export const CHANGE_PASSWORD = '/change-password'
export const EVENTS = `/events?_limit=3&_sort=event_start_date&_where[0][event_start_date_gte]=${new Date().toISOString()}`
export const HUBSPOT_STATUS = '/hub-spot'
export const USERS_COUNT = (company: string) =>
  `/users/count?_where[_or][0][full_name_ne]=&_where[_or][1][first_name_ne]=` + (company ? `&_where[_and][0][company]=${company}` : '')
export const USERS_PAGE = (currentPage: number, company: string) =>
  `/users/?_limit=16&_sort=public_setting:DESC,avatar_url&_where[_or][0][full_name_ne]=&_where[_or][1][first_name_ne]=&_start=${16 * (currentPage - 1)}` +
  (company ? `&_where[_and][0][company]=${company}` : '')
export const SOCIAL_USER_SETUP = '/social-user-setup'
export const ENROLLEMENTS_LOG = '/enrollments/log'
export const PRIMARY_COMPANY = (companyId: string) => `/companies/${companyId}`
export const HUBSPOT_UPDATE = '/update-hubspot-user'
