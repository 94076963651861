import ibm from '../../images/technologies/ibm.jpeg'
import alan from '../../images/technologies/alan.jpeg'
import cognigy from '../../images/technologies/cognigy.jpeg'
import dialogflow from '../../images/technologies/dialogflow.png'
import luis from '../../images/technologies/luis.png'
import rasa from '../../images/technologies/rasa.png'
import voiceflow from '../../images/technologies/voiceflow.png'
import botsociety from '../../images/technologies/botsociety.png'
import qbox from '../../images/technologies/qbox.png'
import chatlayer from '../../images/technologies/chatlayer.png'
import blits from '../../images/technologies/blits.jpeg'
import dialogflowcx from '../../images/technologies/dialogflowcx.png'
import speechly from '../../images/technologies/speechly.png'
import einstein from '../../images/technologies/einstein.png'
import servicenow from '../../images/technologies/servicenow.png'
import ebot7 from '../../images/technologies/ebot7.png'
import kore from '../../images/technologies/kore.jpeg'
import haptik from '../../images/technologies/haptik.jpeg'
import amelia from '../../images/technologies/amelia.jpeg'
import airy from '../../images/technologies/airy.png'
import as from '../../images/technologies/as.jpeg'
import other from '../../images/technologies/other.svg'

export interface Technology {
  name: string
  img: string
  active: boolean
  url?: string
}

export const technologies: Technology[] = [
  {
    name: 'Alan AI',
    img: alan,
    active: false,
  },
  {
    name: 'Cognigy',
    img: cognigy,
    active: false,
  },
  {
    name: 'Rasa',
    img: rasa,
    active: false,
  },
  {
    name: 'Qbox',
    img: qbox,
    active: false,
  },
  {
    name: 'Chatlayer',
    img: chatlayer,
    active: false,
  },
  {
    name: 'Botsociety',
    img: botsociety,
    active: false,
  },
  {
    name: 'Voiceflow',
    img: voiceflow,
    active: false,
  },
  {
    name: 'Google Dialogflow',
    img: dialogflow,
    active: false,
  },
  {
    name: 'Google Dialogflow CX',
    img: dialogflowcx,
    active: false,
  },
  {
    name: 'IBM Watson',
    img: ibm,
    active: false,
  },
  {
    name: 'Microsoft LUIS',
    img: luis,
    active: false,
  },
  {
    name: 'Blits',
    img: blits,
    active: false,
    url: 'https://www.blits.ai/',
  },
  {
    name: 'Speechly',
    img: speechly,
    active: false,
    url: 'https://www.boost.ai/',
  },
  {
    name: 'Service Now VA',
    img: servicenow,
    active: false,
  },
  {
    name: 'Salesforce Einstein',
    img: einstein,
    active: false,
  },
  {
    name: 'Kore.ai',
    img: kore,
    active: false,
  },
  {
    name: 'Artificial Solutions',
    img: as,
    active: false,
  },
  {
    name: 'Amelia',
    img: amelia,
    active: false,
  },
  {
    name: 'E-bot 7 ',
    img: ebot7,
    active: false,
  },
  {
    name: 'Airy',
    img: airy,
    active: false,
  },
  {
    name: 'Boost.ai',
    img: kore,
    active: false,
  },
  {
    name: 'Haptik',
    img: haptik,
    active: false,
  },
  {
    name: 'Other',
    img: other,
    active: false,
  },
]
