import { EDIT_USER } from '../../constants/api'
import strapi from '../../helpers/strapi.helper'
import errorHandler from './errorHandler'

export default async (userId: string, data: User) =>
  await strapi
    .request('PUT', EDIT_USER(userId), {
      data: {
        ...data,
      },
    })
    .catch(errorHandler)
