import React from 'react'
import { usePagination, DOTS } from '../hooks/Pagination'
import styled, { css } from 'styled-components'
import Icon from './icon/Icon'
import colors from '../styles/colors'
import { applyTextStyle } from '../styles/typography'

interface IProps {
  onPageChange: (page: number) => void
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  className?: string
}

const Pagination: React.FC<IProps> = ({ onPageChange, totalCount, siblingCount = 0, currentPage, pageSize, className }) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  }) as (string | number)[]

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  const lastPage = paginationRange[paginationRange.length - 1]
  return (
    <PaginationContainer className={className}>
      <PaginationItem disabled={currentPage === 1} onClick={onPrevious}>
        <StyledIcon type="ArrowLeft" color={colors.secondary1} size={24} />
      </PaginationItem>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <PaginationItem key={index} isDots={true}>
              &#8230;
            </PaginationItem>
          )
        }

        return (
          <PaginationItem key={index} selected={pageNumber === currentPage} onClick={() => onPageChange(pageNumber as number)}>
            {pageNumber}
          </PaginationItem>
        )
      })}
      <PaginationItem disabled={currentPage === lastPage} onClick={onNext}>
        <StyledIcon type="ArrowRight" color={colors.secondary1} size={24} />
      </PaginationItem>
    </PaginationContainer>
  )
}

const PaginationContainer = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0px;
`

const PaginationItem = styled.li<{ isDots?: boolean; disabled?: boolean; selected?: boolean }>`
  position: relative;
  padding: 0 12px;
  height: 32px;
  width: 32px;
  text-align: center;
  margin: auto 4px;
  color ${colors.primary1};
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-radius: 50%;
  ${applyTextStyle('label3')}

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
  ${({ isDots }) => {
    if (isDots) {
      return css`
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      `
    }
  }}

  ${({ selected }) => {
    if (selected) {
      return css`
        background-color: rgba(0, 0, 0, 0.08);
      `
    }
  }}

  ${({ disabled }) => {
    if (disabled) {
      return css`
        pointer-events: none;
      `
    }
  }}
`
const StyledIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`
export default Pagination
