import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import JobView from '../components/JobView'
import formatSlug from '../helpers/formatSlug.helper'
import colors from '../styles/colors'
import { mqFrom } from '../styles/responsive'
import Button from './Button'

interface IProps {
  job?: Job
}

const JobDialog: React.FC<IProps> = ({ job }) => {
  const history = useHistory()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(job?.name !== '' && job !== undefined ? true : false)
  const dialogRef: any = useRef()

  useEffect(() => {
    if (job?.name !== '' && job !== undefined) {
      setIsDialogOpen(true)
    }
  }, [job])

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (dialogRef && dialogRef.current && !dialogRef.current.contains(event.target)) {
        setIsDialogOpen(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [])

  return (
    <DialogContainer ref={dialogRef} isDialogOpen={isDialogOpen}>
      {job && (
        <>
          <ToJobPageButton
            caption="share job"
            onClick={() => {
              window.dataLayer.push({
                event: 'Share job',
              })
              history.push(`/jobboard/${job.slug || formatSlug(job)}`)
            }}
          />
          <JobView isDialog={true} closeDialog={() => setIsDialogOpen(false)} job={job}></JobView>
        </>
      )}
    </DialogContainer>
  )
}

const DialogContainer = styled.div<{ isDialogOpen: boolean }>`
  max-width: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 400;
  transition: 0.5s;
  height: 100vh;
  overflow: auto;

  ${({ isDialogOpen }) => {
    if (isDialogOpen) {
      return css`
        max-width: 610px;
        padding: 126px 30px;
        box-shadow: 0px 3px 12px ${colors.shadow};
        background-color: ${colors.backgroundPrimary};
        ${mqFrom.M`
          width: 610px;
        `}
      `
    }
  }}
`

const ToJobPageButton = styled(Button)`
  margin-bottom: 15px;
  float: right;
  margin-top: -80px;
  padding: 0px;
  background-color: transparent;
  color: ${colors.tertiary1};

  :hover {
    text-decoration: underline;
  }
`

export default JobDialog
