import { EDIT_NOTIFICATION } from '../../constants/api'
import strapi from '../../helpers/strapi.helper'
import errorHandler from './errorHandler'

export default async (notification_id: string, data: Notification) =>
  await strapi
    .request('PUT', EDIT_NOTIFICATION(notification_id), {
      data: {
        ...data,
      },
    })
    .catch(errorHandler)
