import React from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import { mqFrom } from '../../styles/responsive'
import { applyTextStyle } from '../../styles/typography'
import Icon from '../icon/Icon'

interface IProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
}

const SearchBar: React.FC<IProps> = ({ onChange, value }) => {
  return (
    <InputWrapper>
      <InputInnerWrapper>
        <IconWrapper>
          <StyledIcon type="Search" color={colors.neutralWhite} size={24} />
        </IconWrapper>
        <StyledInput value={value} onChange={(e) => onChange(e)} placeholder="Start searching ..."></StyledInput>
      </InputInnerWrapper>
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
`

const InputInnerWrapper = styled.div`
  position: relative;
`

const StyledInput = styled.input`
  display: block;
  width: 100%;
  background-color: ${colors.neutralWhite};
  outline: 0;
  border: none;
  padding: 12px 45px 14px 17px;
  ${applyTextStyle('label3')};
  color: ${colors.secondary1};

  ${mqFrom.L`
    padding: 17px 45px 13px 20px;
    background-color: ${colors.primary4};
  `}
`

const StyledIcon = styled(Icon)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: ${colors.tertiary1};
  ${mqFrom.L`
    height: 48px;
    background-color: ${colors.transparent};
  `}
  &:hover {
    & + * path {
      fill: ${colors.tertiary1};
    }
  }
`

export default SearchBar
