import React from 'react'
import styled from 'styled-components'
import Certificate from './Certificate'

interface IProps {
  className?: string
  certificates?: Certificate[]
}

const CertificatesList: React.FC<IProps> = ({ className, certificates }) => {
  return (
    <div className={className}>
      {certificates?.map((certificate, index) => (
        <StyledCertificate key={index}>
          <Certificate caption={certificate.name || ''}></Certificate>
        </StyledCertificate>
      ))}
    </div>
  )
}

export const StyledCertificate = styled.div`
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 12px;
  border: 1px solid #d8d8d8;
`

export default CertificatesList
