import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../styles/colors'
import { ForceStyle } from '../styles/typography'
import Link from './Link'
import Text from './Text'

interface IProps {
  className?: string
  header?: string
  link?: string
  clickLink?: () => void
  children?: React.ReactNode
  withTopBorder?: boolean
  withBottomBorder?: boolean
  forceStyleHeader?: ForceStyle
  customAction?: React.ReactNode
  marginBottomAfterLine?: number
  clickTopSection?: () => void
  isTopSectionCursorPointer?: boolean
  smallHeader?: boolean
  subheader?: string
}
const Section: React.FC<IProps> = ({
  className,
  header,
  link,
  clickLink,
  children,
  withBottomBorder,
  forceStyleHeader,
  customAction,
  marginBottomAfterLine,
  clickTopSection,
  isTopSectionCursorPointer,
  smallHeader,
  withTopBorder,
  subheader,
}) => (
  <Wrapper className={className} withBottomBorder={withBottomBorder}>
    <SectionTop
      onClick={clickTopSection ? clickTopSection : undefined}
      marginBottom={withTopBorder ? (marginBottomAfterLine ? marginBottomAfterLine : 22.5) : 0}
      isCursorPointer={isTopSectionCursorPointer}
      withTopBorder={withTopBorder}
    >
      <HeadersContainer withTopBorder={withTopBorder}>
        <Text
          marginBottom={subheader ? 20 : 0}
          forceStyle={forceStyleHeader ? forceStyleHeader : undefined}
          style={smallHeader ? 'label9' : 'heading7'}
          color="primary2"
        >
          {header}
        </Text>
        <Text style="label5" color="primary1">
          {subheader}
        </Text>
      </HeadersContainer>
      {link && <Link caption={link} onClick={clickLink} withUnderline />}
      {customAction}
    </SectionTop>
    {children}
  </Wrapper>
)

const Wrapper = styled.div<{ withBottomBorder?: boolean }>`
  width: 100%;
  margin-top: 20px;

  ${({ withBottomBorder }) => {
    if (withBottomBorder) {
      return css`
        border-bottom: solid 3px ${colors.secondary2};
        padding-bottom: 15px;
      `
    }
  }}
`

export const SectionTop = styled.div<{
  marginBottom: number
  isCursorPointer?: boolean
  withTopBorder?: boolean
}>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ withTopBorder }) => (withTopBorder ? '9.5' : '0')}px;
  border-bottom: ${({ withTopBorder }) => (withTopBorder ? `solid 1px ${colors.secondary6}` : 'none')};
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  cursor: ${({ isCursorPointer }) => (isCursorPointer ? 'pointer' : 'unset')};
`

const HeadersContainer = styled.div<{ withTopBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ withTopBorder }) => (withTopBorder ? '0' : '33')}px;
`

export default Section
