import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrow = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 15 12"
    fill="none"
  >
    <title>Atoms / Icons / ic-arrow-right</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill={props.color} fillRule="evenodd">
      <g id="Atoms-/-Buttons-/-Light-/-🖥/Primary-/-Enabled" transform="translate(-240.000000, -24.000000)" fill={props.color}>
        <g id="Group" transform="translate(239.000000, 22.000000)">
          <g id="Combined-Shape" transform="translate(1.000000, 2.000000)">
            <path d="M9.74719819,0.335398718 L14.7856222,6 L9.74719819,11.6646013 L8.25280181,10.3353987 L11.333,6.87139872 L0,6.87211368 L0,4.87211368 L11.106,4.87139872 L8.25280181,1.66460128 L9.74719819,0.335398718 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconArrow
