import React from 'react'
import styled from 'styled-components'
import checkIfDataisUserExperience from '../../helpers/checkUserKnowledgeType.helper'
import colors from '../../styles/colors'
import Text from '../Text'
import Logo from '../Logo'
import dayjs from 'dayjs'

interface IProps {
  className?: string
  data: UserEducation | UserExperience
}

const KnowledgeCard: React.FC<IProps> = ({ className, data }) => {
  const isDataExperience = checkIfDataisUserExperience(data)

  return (
    <Card className={className}>
      <LogoStyled size={96} imgSrc={data.logo_url} />
      <div>
        <Text marginBottom={7} marginTop={9} forceStyle="desktop" color="primary1" style="heading6">
          {isDataExperience ? data.company_name : data.school_name}
        </Text>
        <TextsContainer>
          <Text marginRight={10} color="primary1" style="heading2" forceStyle="mobile">
            {isDataExperience ? data.job_title : data.study_title}
          </Text>
          {isDataExperience && (
            <>
              <SeparatingLine />
              <Text color="secondary1" style="heading2" forceStyle="mobile">
                {dayjs(new Date(data.start_date!)).format('MMM. YYYY') +
                  ' - ' +
                  `${!data.current ? dayjs(new Date(data.end_date!)).format('MMM. YYYY') : 'Current job'}`}
              </Text>
            </>
          )}
        </TextsContainer>
        <Text color="secondary1" style="description">
          {isDataExperience ? data.description : data.passed ? dayjs(new Date(data.end_date!)).format('MMM. YYYY') : 'Current education'}
        </Text>
      </div>
    </Card>
  )
}

const Card = styled.div`
  display: flex;
`

const LogoStyled = styled(Logo)`
  flex-shrink: 0;
  margin-right: 24px;
`

const TextsContainer = styled.div`
  display: flex;
  margin-bottom: 3px;
  align-items: center;
`

const SeparatingLine = styled.div`
  height: 10px;
  width: 1.5px;
  background-color: ${colors.secondary1};
  margin-right: 10px;
`

export default KnowledgeCard
