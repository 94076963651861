import React, { useState } from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import KnowledgeCard from './cards/KnowledgeCard'
import AddOrEditExperienceForm from './Forms/AddOrEditExperienceForm'
import AddOrEditEducationForm from './Forms/AddOrEditEducation'

interface IProps {
  className?: string
  isDataExperience: boolean
  data: UserExperience[] | UserEducation[]
  isOnPublicProfile: boolean
  passIsOnAdd?: (isOnAdd: boolean) => void
}

const KnowledgeCardsList: React.FC<IProps> = ({ className, isDataExperience, data, isOnPublicProfile, passIsOnAdd }) => {
  const [isOnEdit, setIsOnEdit] = useState<boolean[]>(data.map(() => false))

  const swapIsOnEdit = (index: number, isVisible: boolean) => {
    const isOnEditCopy = { ...isOnEdit }
    isOnEditCopy[index] = isVisible
    setIsOnEdit(isOnEditCopy)
  }

  return (
    <Wrapper className={className}>
      {data.map((dataItem, index) => (
        <CardContainer isOnPublicProfile={isOnPublicProfile} key={index}>
          {!isOnEdit[index] && <KnowledgeCard data={isDataExperience ? (dataItem as UserExperience) : (dataItem as UserEducation)} />}
          {!isOnPublicProfile && isDataExperience && (
            <AddOrEditExperienceForm
              changeIsOnEdit={(isVisible) => swapIsOnEdit(index, isVisible)}
              isEditMode={true}
              editExperience={dataItem as UserExperience}
              editIndex={index}
            />
          )}
          {!isOnPublicProfile && !isDataExperience && (
            <AddOrEditEducationForm
              changeIsOnEdit={(isVisible) => swapIsOnEdit(index, isVisible)}
              isEditMode={true}
              editEducation={dataItem as UserEducation}
              editIndex={index}
            />
          )}
        </CardContainer>
      ))}
      {!isOnPublicProfile && (
        <>
          {isDataExperience && <AddOrEditExperienceForm isEditMode={false} passIsOnAdd={passIsOnAdd} />}
          {!isDataExperience && <AddOrEditEducationForm isEditMode={false} passIsOnAdd={passIsOnAdd} />}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
`

const CardContainer = styled.div<{ isOnPublicProfile: boolean }>`
  padding-bottom: ${({ isOnPublicProfile }) => (isOnPublicProfile ? 31 : 11)}px;
  border-bottom: 1px solid ${colors.secondary2};
  margin-bottom: 24px;
  position: relative;
`

export default KnowledgeCardsList
