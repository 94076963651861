import React, { useEffect, useState } from 'react'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import Text from '../components/Text'
import styled from 'styled-components'
import { mqFrom } from '../styles/responsive'
import DropdownSelect from '../components/DropdownSelect'
import Section from '../components/Section'
import colors from '../styles/colors'
import JobsList from '../components/JobsList'
import JobDialog from '../components/JobDialog'
import { useHistory } from 'react-router'
import { useAppState } from '../context/AppContext'
import JobsFilter from '../components/JobsFilter'
import { Helmet } from 'react-helmet'
import Button, { ButtonVariant } from '../components/Button'

const JobboardPage: React.FC = () => {
  const appState = useAppState()
  const user = appState.user
  const jobs = appState.jobs
  const history = useHistory()

  const allJobsHeaders = ['Most popular', 'All']
  const [isAllJobsDropdownVisible, setIsAllJobsDropdownVisible] = useState<boolean>(false)
  const [activeAllJobsHeader, setActiveAllJobsHeader] = useState<number>(0)
  const [isJobDialogOpen, setIsJobDialogOpen] = useState<Job>()

  const [filteredJobs, setFilteredJobs] = useState<Job[]>(
    jobs?.sort((a, b) => new Date(b.job_application_post_date).getTime() - new Date(a.job_application_post_date).getTime()) || []
  )
  useEffect(() => {
    if (jobs) {
      setFilteredJobs(jobs.sort((a, b) => new Date(b.job_application_post_date).getTime() - new Date(a.job_application_post_date).getTime()))
    }
  }, [jobs])
  return (
    <AppPageStyled>
      <Helmet>
        <meta property="og:title" content="Conversational Design Institute Job board" />
        <meta property="og:description" content="Conversational Jobs. Find a job that fits." />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content="english" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={process.env.REACT_APP_CDI_LOGO_URL} />
      </Helmet>
      <AppTop inverted={true}>
        <Text style="headline" color="primary2" marginBottom={4}>
          Job board
        </Text>
        <Text style="heading2" color="primary1">
          Find the perfect job for you
        </Text>
        <AddJobContainer>
          <Button
            icon="WritingPen"
            iconColor={colors.neutralWhite}
            variant={ButtonVariant.Secondary}
            iconOnLeft
            iconSize={16}
            caption={user?.unlimited_subscription ? 'Post a job' : 'Post a job from $99.00'}
            onClick={() => {
              window.dataLayer.push({
                event: 'Post a job from €99 clicked',
              })
              history.push('/jobboard/post-job')
            }}
          />
        </AddJobContainer>
      </AppTop>
      <JobboardContainer>
        <JobDialog job={isJobDialogOpen}></JobDialog>
        <StyledAppContainer>
          <AllJobsContainer>
            <JobsFilter filteredJobs={(jobs) => setFilteredJobs(jobs)} />
            <Section
              isTopSectionCursorPointer={true}
              clickTopSection={() => setIsAllJobsDropdownVisible(!isAllJobsDropdownVisible)}
              customAction={
                <>
                  <StyledButton
                    caption={allJobsHeaders[activeAllJobsHeader]}
                    variant={ButtonVariant.Tertiary}
                    textStyle="label8"
                    textColor="primary2"
                    icon="ArrowDown"
                  />
                  <DropdownSelect
                    headers={allJobsHeaders}
                    setActiveHeader={(index) => setActiveAllJobsHeader(index)}
                    isVisible={isAllJobsDropdownVisible}
                    top={46}
                    onOutsideClick={() => setIsAllJobsDropdownVisible(false)}
                  ></DropdownSelect>
                </>
              }
            >
              <JobsList isBigger openJobDialog={(job) => setIsJobDialogOpen({ ...job })} jobs={filteredJobs} />
            </Section>
          </AllJobsContainer>
        </StyledAppContainer>
      </JobboardContainer>
    </AppPageStyled>
  )
}

const AppPageStyled = styled(AppPage)`
  overflow: unset;
  padding-bottom: 0;
`

const JobboardContainer = styled.div`
  height: 100%;
  position: relative;
  @media only screen and (max-width: 610px) {
    position: unset;
  }
`

const StyledAppContainer = styled(AppContainer)``

const AllJobsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 86px;

  ${mqFrom.L`
    flex-direction: row;
    padding-top: 38px;
    padding-bottom: 73px;
  `}
`

const AddJobContainer = styled.div`
  position: absolute;
  bottom: -38px;
  right: 15px;
`

const StyledButton = styled(Button)`
  margin-bottom: 30px;
`

export default JobboardPage
