import React from 'react'
import styled from 'styled-components'
import { ColSpanAmount } from '../common/models/grid'
import colors from '../styles/colors'
import CertificateCard from './cards/CertificateCard'
import GridCol from './grid/GridCol'
import GridRow from './grid/GridRow'

interface IProps {
  className?: string
  certificates?: Certificate[]
  xsColSpan?: ColSpanAmount
  mColSpan?: ColSpanAmount
  lColSpan?: ColSpanAmount
}

const CertificateCardsList: React.FC<IProps> = ({ className, certificates, xsColSpan, mColSpan, lColSpan }) => {
  return (
    <Wrapper className={className}>
      <Certificate>
        {certificates?.map((certificate, index) => (
          <GridColStyled l={lColSpan ? lColSpan : undefined} m={mColSpan ? mColSpan : undefined} xs={xsColSpan ? xsColSpan : undefined} key={index}>
            <CertificateCard certificate={certificate}></CertificateCard>
          </GridColStyled>
        ))}
      </Certificate>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
`

const Certificate = styled(GridRow)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  min-height: 520px;

  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.backgroundPrimary};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.primary1};
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.primary1};
  }
`

const GridColStyled = styled(GridCol)`
  max-width: 284px;
`

export default CertificateCardsList
