import React from 'react'
import styled from 'styled-components'
import Text from './Text'
import Button from './Button'
import { useHistory } from 'react-router-dom'

const LoginFirst: React.FC = () => {
  const history = useHistory()

  return (
    <Container>
      <Text style="heading2" forceStyle="mobile" color="neutralWhite">
        Login first
      </Text>
      <Button onClick={() => history.push('login')} caption="Login"></Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`

export default LoginFirst
