import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { TextStyleType } from '../common/models/typography'
import colors from '../styles/colors'
import Text from './Text'

interface IProps {
  className?: string
  headers: string[]
  setActiveHeader: (index: number) => void
  isVisible: boolean
  top?: number
  right?: number
  onOutsideClick?: () => void
  textStyle?: TextStyleType
}

const DropdownSelect: React.FC<IProps> = ({ className, setActiveHeader, headers, isVisible, top, right, onOutsideClick, textStyle }) => {
  const dropdownRef: any = useRef()
  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (dropdownRef && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onOutsideClick ? onOutsideClick() : undefined
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [])

  return (
    <>
      {isVisible && (
        <Dropdown ref={dropdownRef} className={className} top={top} right={right}>
          {headers.map((header, index) => (
            <ItemWrapper key={index}>
              <Text
                onClick={() => setActiveHeader(index)}
                marginBottom={5}
                style={textStyle ? textStyle : 'heading1'}
                color="primary2"
                forceStyle="mobile"
                isCursorPointer={true}
              >
                {header}
              </Text>
            </ItemWrapper>
          ))}
        </Dropdown>
      )}
    </>
  )
}

const Dropdown = styled.div<{ top?: number; right?: number }>`
  position: absolute;
  background-color: ${colors.neutralWhite};
  padding: 15px;
  top: ${({ top }) => (top ? top : '20')}px;
  right: ${({ right }) => (right ? right : '0')}px;
  z-index: 150;
`

const ItemWrapper = styled.div`
  :hover {
    background-color: ${colors.secondary6};
  }
`

export default DropdownSelect
