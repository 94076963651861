import React from 'react'
import styled from 'styled-components'
import { FormControlProps } from '../../../common/models/FormControlProps'
import Text from '../../Text'
import { FormControlError } from './FormControlError'

export interface IProps extends FormControlProps {
  defaultChecked?: boolean
  checked?: boolean
}

const Checkbox: React.FC<IProps> = ({ className, label, onChange, marginBottom, defaultChecked, checked, ...props }) => {
  const errorMessage = props.meta && props.meta.error && props.meta.touched ? props.meta.error : props.errorMessage

  return (
    <CheckboxWrapper className={className} marginBottom={marginBottom}>
      <CheckboxInnerWrapper>
        <StyledCheckbox
          {...props}
          defaultChecked={defaultChecked}
          onChange={(e) => (onChange ? onChange(e) : undefined)}
          checked={checked}
          type="checkbox"
        ></StyledCheckbox>
        {label && (
          <Text marginLeft={12} style="label5" color="primary1">
            {label}
          </Text>
        )}
      </CheckboxInnerWrapper>
      <FormControlError>{errorMessage}</FormControlError>
    </CheckboxWrapper>
  )
}

const CheckboxWrapper = styled.div<{ marginBottom?: number }>`
  position: relative;
  display: block;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  width: 100%;
`

const CheckboxInnerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const StyledCheckbox = styled.input``

export default Checkbox
