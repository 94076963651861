import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import colors from '../styles/colors'
import { mqFrom } from '../styles/responsive'
import DropdownSelect from './DropdownSelect'
import Icon from './icon/Icon'
import Text from './Text'

interface IProps {
  className?: string
  headers: string[]
  onHeaderClick: (index: number) => void
  align: boolean
  activeHeader: number
  children: React.ReactNode
  withLeftAllign?: boolean
}
const MultiSections: React.FC<IProps> = ({ className, headers, children, onHeaderClick, align, activeHeader, withLeftAllign }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)

  return (
    <Wrapper className={className}>
      <Top withLeftAllign={withLeftAllign}>
        {headers.map((header, index) => (
          <TextContainer
            withLeftAllign={withLeftAllign}
            onClick={() => onHeaderClick(index)}
            itemWidth={100 / headers.length}
            isActive={index === activeHeader}
            key={index}
            align={align}
          >
            <Text marginRight={withLeftAllign ? 60 : 0} style="heading8" color="primary2" opacity={index === activeHeader ? 100 : 50}>
              {header}
            </Text>
          </TextContainer>
        ))}
        <Select onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
          <Text style="heading1" forceStyle="mobile" color="primary2">
            {headers[activeHeader]}
          </Text>
          <StyledIcon type="ArrowDown" color={colors.tertiary1} size={24} />
          <DropdownSelect headers={headers} setActiveHeader={(index) => onHeaderClick(index)} isVisible={isDropdownVisible}></DropdownSelect>
        </Select>
      </Top>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const Top = styled.div<{ withLeftAllign?: boolean }>`
  display: flex;
  justify-content: ${({ withLeftAllign }) => (withLeftAllign ? 'flex-start' : 'space-between')};
  padding-bottom: 19px;
  border-bottom: solid 1px ${colors.secondary6};
  margin-bottom: 22.5px;
`
const TextContainer = styled.div<{
  itemWidth: number
  isActive: boolean
  align: boolean
  withLeftAllign?: boolean
}>`
  display: none;
  ${mqFrom.M`
    display: block;
  `}
  position: relative;
  width: ${({ itemWidth, withLeftAllign }) => (!withLeftAllign ? itemWidth : 'unset')}%;
  cursor: pointer;

  ${({ align }) => {
    if (align) {
      return css`
        width: auto;
      `
    }
  }}

  ${({ isActive, withLeftAllign }) => {
    if (isActive) {
      return css`
        &:after {
          content: '';
          position: absolute;
          width: ${withLeftAllign ? 'calc(100% - 60px)' : '100%'};
          border-bottom: solid 3px ${colors.tertiary2};
          bottom: -19.5px;
        }
      `
    }
  }}
`

const Select = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  ${mqFrom.M`
    display: none;
  `}
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

export default MultiSections
