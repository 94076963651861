import React from 'react'
import AppPage from '../components/AppPage'
import AppTop from '../components/AppTop'
import Text from '../components/Text'

const NotFoundPage: React.FC = () => {
  return (
    <AppPage>
      <AppTop>
        <Text style="headline" color="neutralWhite" marginBottom={4}>
          Page Not found
        </Text>
      </AppTop>
    </AppPage>
  )
}

export default NotFoundPage
