import React from 'react'
import styled from 'styled-components'
import { applyTextStyle } from '../../styles/typography'
import colors from '../../styles/colors'
import Text from '../Text'
import Icon from '../icon/Icon'
import Button, { ButtonVariant } from '../Button'

interface IProps {
  className?: string
  certificate: Certificate
}

const CertificateCard: React.FC<IProps> = ({ className, certificate }) => {
  const openBuyLink = () => {
    const newWindow = window.open(certificate?.url, '_self')
    if (newWindow) newWindow.opener = null
  }

  return (
    <Card className={className}>
      <Image>
        <img src={certificate.imgLarge} className="course-img" />
      </Image>
      <CourseTitle>{certificate.name}</CourseTitle>

      <>
        {certificate.usps &&
          certificate.usps.length > 0 &&
          certificate.usps.map((usp) => (
            <AboutItemContainer key={usp.id}>
              <Icon size={24} color={colors.tertiary1} type="Done" />
              <Text withTextEllipsis={true} style="label5" color="primary1" marginLeft={3}>
                {usp.name}
              </Text>
            </AboutItemContainer>
          ))}

        {!certificate.usps ||
          (certificate.usps.length === 0 && (
            <>
              <AboutItemContainer>
                <Icon size={24} color={colors.tertiary1} type="Done" />
                <Text style="label5" color="primary1" marginLeft={3}>
                  Great Content
                </Text>
              </AboutItemContainer>
              <AboutItemContainer>
                <Icon size={24} color={colors.tertiary1} type="Done" />
                <Text style="label5" color="primary1" marginLeft={3}>
                  Huge learning opportunity
                </Text>
              </AboutItemContainer>
              <AboutItemContainer>
                <Icon size={24} color={colors.tertiary1} type="Done" />
                <Text style="label5" color="primary1" marginLeft={3}>
                  Value for money
                </Text>
              </AboutItemContainer>
            </>
          ))}
        <PriceContainer>
          <Button variant={ButtonVariant.Secondary} caption={'Read more'} onClick={openBuyLink} />
          <Text style="heading5" color="primary1">
            € {certificate.certificate_price ? parseFloat(certificate.certificate_price).toFixed(0) : 0}
          </Text>
        </PriceContainer>
      </>
    </Card>
  )
}

const Card = styled.div`
  position: relative;
  display: block;
`

const Image = styled.div`
  width: auto;
  height: 140px;
  background-color: ${colors.secondary2};
  margin-bottom: 20px;

  .course-img {
    height: 140px;
    width: 100%;
  }
`

const CourseTitle = styled.div`
  ${applyTextStyle('heading3')}
  margin-bottom: 13px;
  height: 40px;
`

const AboutItemContainer = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  margin-bottom: 8px;
  /* overflow: hidden; */
`

const PriceContainer = styled.div`
  padding: 13px 0 18px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid ${colors.secondary2};

  .scratched-text {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: ${colors.secondary1};
      top: 50%;
    }
  }
`

export default CertificateCard
