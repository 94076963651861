import React from 'react'
import styled from 'styled-components'
import { applyTextStyle } from '../../styles/typography'
import colors from '../../styles/colors'
import Text from '../Text'
import Icon from '../icon/Icon'
import Button, { ButtonVariant } from '../Button'
import { useAppState } from '../../context/AppContext'
import { useHistory } from 'react-router-dom'

interface IProps {
  className?: string
  course: Course
}

enum CourseStatus {
  New = 'New',
  Bestseller = 'Bestseller',
  Discount = 'Discount',
}

const CourseCard: React.FC<IProps> = ({ className, course }) => {
  const appState = useAppState()
  const history = useHistory()
  const enrollements = appState.enrollements
  const openBuyLink = () => {
    const newWindow = window.open(course?.buy_link, '_self')
    if (newWindow) newWindow.opener = null
  }

  return (
    <Card className={className}>
      <Image>
        <img src={course.card_image_url} className="course-img" />
      </Image>
      <CourseTitle>{course.name}</CourseTitle>
      <Text style="label4" color="secondary1" marginBottom={13}>
        {course.instructor_name}
      </Text>

      <>
        {course.usps &&
          course.usps.length > 0 &&
          course.usps.map((usp) => (
            <AboutItemContainer key={usp.id}>
              <Icon size={24} color={colors.tertiary1} type="Done" />
              <Text withTextEllipsis={true} style="label5" color="primary1" marginLeft={3}>
                {usp.name}
              </Text>
            </AboutItemContainer>
          ))}

        {!course.usps ||
          (course.usps.length === 0 && (
            <>
              <AboutItemContainer>
                <Icon size={24} color={colors.tertiary1} type="Done" />
                <Text style="label5" color="primary1" marginLeft={3}>
                  Great Content
                </Text>
              </AboutItemContainer>
              <AboutItemContainer>
                <Icon size={24} color={colors.tertiary1} type="Done" />
                <Text style="label5" color="primary1" marginLeft={3}>
                  Huge learning opportunity
                </Text>
              </AboutItemContainer>
              <AboutItemContainer>
                <Icon size={24} color={colors.tertiary1} type="Done" />
                <Text style="label5" color="primary1" marginLeft={3}>
                  Value for money
                </Text>
              </AboutItemContainer>
            </>
          ))}

        <StatusAndCertificateContainer>
          <StatusAndCertificateItem>
            <Icon
              size={24}
              color={colors.tertiary3}
              type={course.status === CourseStatus.Bestseller ? 'Verified' : course.status === CourseStatus.New ? 'Awesome' : 'LocalOffer'}
            />
            <Text style="label6" color="primary1" marginLeft={8}>
              {course.status}
            </Text>
          </StatusAndCertificateItem>
          <StatusAndCertificateItem>
            <Icon size={24} color={course.has_certificate ? colors.tertiary3 : colors.secondary2} type="Trophy" />
            <Text style="label6" color={course.has_certificate ? 'primary1' : 'secondary2'} marginLeft={8}>
              Certificate
            </Text>
          </StatusAndCertificateItem>
          <></>
        </StatusAndCertificateContainer>
        <PriceContainer>
          {enrollements?.some((enrollement) => (enrollement?.course as Course)?.id === course.id) ? (
            <Button
              variant={ButtonVariant.Primary}
              caption={'Your Courses'}
              onClick={() => {
                history.push('/dashboard')
              }}
            />
          ) : (
            <Button variant={ButtonVariant.Secondary} caption={'Read more'} onClick={openBuyLink} />
          )}

          <Text className="scratched-text" style="label5" color="secondary1">
            {/* € {course.course_discount_percentage} */}
            {/* <Button variant={isCompleted ? ButtonVariant.Secondary : ButtonVariant.Primary} caption={isCompleted ? 'Retake' : 'Continue'} /> */}
          </Text>
          <Text style="heading5" color="primary1">
            {enrollements?.some((enrollement) => (enrollement?.course as Course)?.id === course.id)
              ? 'Owned'
              : `€ ${course.course_price ? parseFloat(course.course_price).toFixed(0) : 0}`}
          </Text>
        </PriceContainer>
      </>
    </Card>
  )
}

const Card = styled.div`
  position: relative;
  display: block;
`

const Image = styled.div`
  width: auto;
  height: 140px;
  background-color: ${colors.secondary2};
  margin-bottom: 20px;

  .course-img {
    height: 140px;
    width: 100%;
  }
`

const CourseTitle = styled.div`
  ${applyTextStyle('heading3')}
  margin-bottom: 10px;
  height: 56px;
`

const AboutItemContainer = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  margin-bottom: 8px;
  /* overflow: hidden; */
`

const StatusAndCertificateContainer = styled.div`
  margin-top: 18px;
  padding: 16px 0 13px 0;
  border-top: 1px solid ${colors.secondary6};
  border-bottom: 1px solid ${colors.secondary6};
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const StatusAndCertificateItem = styled.div`
  align-items: center;
  display: flex;
`

const PriceContainer = styled.div`
  padding: 16px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.secondary6};

  .scratched-text {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: ${colors.secondary1};
      top: 50%;
    }
  }
`

export default CourseCard
