import React from 'react'
import styled, { css } from 'styled-components'
import { worldwideSpokenLanguages } from '../helpers/languages'
import colors from '../styles/colors'
import { applyTextStyle } from '../styles/typography'
import Dropdown, { StyledIconDropdown, StyledSelect } from './Forms/formControls/Dropdown'
import Icon from './icon/Icon'
import Text from './Text'

interface IProps {
  className?: string
  languages?: string[]
  isInEditMode?: boolean
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>, index: number) => void
  onAdd?: () => void
  onDelete?: (index: number) => void
}

const LanguagesList: React.FC<IProps> = ({ className, languages, isInEditMode, onChange, onAdd, onDelete }) => {
  return (
    <Wrapper className={className}>
      {languages?.map((language, index) => (
        <Language isInEditMode={isInEditMode} key={index}>
          {isInEditMode && (
            <>
              <StyledLanguagesDropdown withBorder={true} onChange={(e) => (onChange ? onChange(e, index) : undefined)}>
                <option value={language}>{language}</option>
                {worldwideSpokenLanguages.sort().map((lan, index) =>
                  language !== lan ? (
                    <option key={index} value={lan}>
                      {lan}
                    </option>
                  ) : null
                )}
                <option value=""></option>
              </StyledLanguagesDropdown>
              <StyledDeleteIcon color={colors.backgroundPrimary} size={18} type="Close" onClick={() => (onDelete ? onDelete(index) : undefined)} />
            </>
          )}
          {!isInEditMode && (
            <Text marginRight={5} style="label5" color="primary1">
              {language + (index !== languages?.length - 1 ? ',' : '')}
            </Text>
          )}
        </Language>
      ))}
      {isInEditMode && <StyledAddIcon onClick={onAdd} color={colors.tertiary1} size={24} type="Add" />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const Language = styled.div<{ isInEditMode?: boolean }>`
  position: relative;
  display: inline-block;
  ${({ isInEditMode }) =>
    isInEditMode
      ? css`
          margin-right: 30px;
          margin-bottom: 12px;
          height: 40px;
        `
      : null}
`

const StyledLanguagesDropdown = styled(Dropdown)`
  ${StyledSelect} {
    padding: 0 20px 0 20px;
    ${applyTextStyle('label1')};
    border: 1px solid ${colors.primary1};
    height: 40px;
    color: ${colors.primary1};
  }
  ${StyledIconDropdown} {
    pointer-events: none;
    top: 8px;
    right: 8px;
  }
`

const StyledAddIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  margin-top: 10px;
`

const StyledDeleteIcon = styled(Icon)`
  position: absolute;
  right: -9px;
  top: -8px;
  background-color: ${colors.primary1};
  border-radius: 50%;
  cursor: pointer;
`

export default LanguagesList
