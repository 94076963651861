import React from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import banner from '../images/banner_bg.png'

// Styling
import { mqFrom } from '../styles/responsive'
import AppContainer from './AppContainer'

interface IProps {
  className?: string
  children: React.ReactNode
  inverted?: boolean
  hideLine?: boolean
}
const AppTop: React.FC<IProps> = ({ className, children, inverted, hideLine }) => (
  <>
    <Wrapper className={className} inv={inverted}>
      <AppContainer>{children}</AppContainer>
    </Wrapper>
    {!hideLine && <Line />}
  </>
)

const Wrapper = styled.div<{ inv?: boolean }>`
  padding: 44px 0 51px 0;
  position: relative;
  background: ${({ inv }) => (inv ? '' : `#2c34a8 url(${banner}) no-repeat center center`)};
  background-color: ${({ inv }) => (inv ? colors.backgroundPrimary : colors.primary4)};
  ${mqFrom.M`
    padding: 52px 0 114px 0;
  `}
`

const Line = styled.div`
  width: 100%;
  max-width: 1110px;
  margin: auto;
  background: ${colors.secondary6};
  height: 1px;
`

export default AppTop
