import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import Text from './Text'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import workplaceText from '../helpers/workplace.helper'
import { useHistory, useLocation } from 'react-router-dom'
import Link from './Link'
import Pagination from './Pagination'
import formatSlug from '../helpers/formatSlug.helper'
dayjs.extend(relativeTime)
interface IProps {
  className?: string
  jobs?: Job[] | undefined
  openJobDialog?: (job: Job) => void
  withViewAllJobsLink?: boolean
  isBigger?: boolean
}

const PageSize = 20

const JobsList: React.FC<IProps> = ({ className, jobs, openJobDialog, withViewAllJobsLink, isBigger }) => {
  const history = useHistory()
  const location = useLocation()
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setCurrentPage(1)
  }, [jobs])

  const currentJobs = useMemo(() => {
    if (jobs && jobs?.length > 0) {
      const firstPageIndex = (currentPage - 1) * PageSize
      const lastPageIndex = firstPageIndex + PageSize
      return jobs.slice(firstPageIndex, lastPageIndex)
    }
    return []
  }, [currentPage, jobs])

  return (
    <div className={className}>
      <>
        {currentJobs.map((job, index) => (
          <Container key={index}>
            <Card isBigger={isBigger} onClick={() => history.push(`/jobboard/${job.slug || formatSlug(job)}`)} className={className}>
              <Image>
                <img src={job.company?.company_logo_url} alt=""></img>
              </Image>
              <div>
                <Text marginBottom={4} forceStyle="mobile" color="primary1" style="heading1">
                  {job.name}
                </Text>
                <TextsContainer isBigger={isBigger}>
                  <Text marginRight={9} color="primary1" style="label4">
                    {job.company?.name}
                  </Text>
                  <Text color="secondary7" style="label4">
                    {'|  ' + job.job_country + ' ' + workplaceText(job.workplace)}
                  </Text>
                </TextsContainer>
                {/* <Text marginBottom={4} color="secondary1" style="description">
                {job.job_description_short}
              </Text> */}
                <Text color="primary1" style="label3">
                  Posted {calculatePostedTime(job.job_application_post_date)}
                </Text>
              </div>
            </Card>
            <PreviewLink
              caption={location.pathname === '/jobboard' ? 'Preview' : ''}
              onClick={() => {
                openJobDialog ? openJobDialog(job) : null
                window.dataLayer.push({
                  event: 'Preview job',
                })
              }}
              color="secondary1"
            />
          </Container>
        ))}
        {withViewAllJobsLink && <ViewJobsLink onClick={() => history.push('/jobboard')} withUnderline caption="View all jobs" />}
        <StyledPagination currentPage={currentPage} totalCount={jobs?.length || 0} pageSize={PageSize} onPageChange={(page) => setCurrentPage(page)} />
      </>
    </div>
  )
}

const calculatePostedTime = (date: Date) => {
  if (dayjs(date).isSame(dayjs(), 'd')) return 'today'
  return dayjs(date).from(dayjs())
}

const Container = styled.div`
  position: relative;
  :first-child {
    border-top: 1px solid ${colors.secondary2};
  }
`

const Card = styled.div<{ isBigger?: boolean }>`
  cursor: pointer;
  position: relative;
  display: flex;
  padding: ${({ isBigger }) => (isBigger ? '30px 0' : '20px 0')};
  border-bottom: 1px solid ${colors.secondary2};
`
// actual img here
const Image = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${colors.transparent};
  margin-right: 25px;
  flex-shrink: 0;
`

const TextsContainer = styled.div<{ isBigger?: boolean }>`
  display: flex;
  margin-bottom: ${({ isBigger }) => (isBigger ? '16' : '12')}px;
  align-items: center;
`

const PreviewLink = styled(Link)`
  position: absolute;
  bottom: 8px;
  right: 0;
`

const StyledPagination = styled(Pagination)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ViewJobsLink = styled(Link)`
  max-width: 88px;
  float: right;
  margin-top: 20px;
`

export default JobsList
