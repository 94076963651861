import React from 'react'
import styled from 'styled-components'
import { IIconProps as IProps } from './IIconProps'

const IconArrowUp = (props: IProps) => (
  <Transform>
    <svg
      style={{
        width: `${props.size}px`,
        height: `${props.size}px`,
      }}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" fill={props.color} />
    </svg>
  </Transform>
)

export default IconArrowUp

const Transform = styled.div`
  svg {
    transform: rotate(180deg);
  }
`
