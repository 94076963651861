import React from 'react'
import styled, { css } from 'styled-components'
import colors, { defaultTextColor } from '../styles/colors'
import { Color } from '../common/models/colors'
import { applyTextEllipsis, applyTextStyle, defaultTextStyle, ForceStyle } from '../styles/typography'
import { TextStyleType, TextTagType } from '../common/models/typography'

interface IProps {
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  tag?: TextTagType
  color?: Color
  style?: TextStyleType
  className?: string
  children: React.ReactNode
  textAlign?: 'left' | 'right' | 'center'
  forceStyle?: ForceStyle
  onClick?: () => void
  isCursorPointer?: boolean
  withTextEllipsis?: boolean
  opacity?: number
  fontWeight?: number
  withUnderline?: boolean
}

export const Text = (props: IProps) => {
  const tag = props.tag || 'p'
  const color = props.color || defaultTextColor
  const textStyle = props.style || defaultTextStyle

  const marginTop = props.marginTop || 0
  const marginRight = props.marginRight || 0
  const marginBottom = props.marginBottom || 0
  const marginLeft = props.marginLeft || 0
  const textAlign = props.textAlign || 'left'
  const forceStyle = props.forceStyle || undefined
  const isCursorPointer = props.isCursorPointer || false
  const withTextEllipsis = props.withTextEllipsis || false
  const opacity = props.opacity || 100
  const fontWeight = props.fontWeight || undefined
  const withUnderline = props.withUnderline

  return (
    <StyledText
      className={props.className}
      as={tag}
      color={color}
      textStyle={textStyle}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      textAlign={textAlign}
      forceStyle={forceStyle}
      onClick={props.onClick}
      isCursorPointer={isCursorPointer}
      withTextEllipsis={withTextEllipsis}
      opacity={opacity}
      fontWeight={fontWeight}
      withUnderline={withUnderline}
    >
      {props.children}
    </StyledText>
  )
}

const StyledText = styled.span<{
  textStyle: TextStyleType
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  textAlign?: string
  color: Color
  forceStyle?: 'mobile' | 'desktop'
  isCursorPointer: boolean
  withTextEllipsis: boolean
  opacity: number
  fontWeight?: number
  withUnderline?: boolean
}>`
  margin-top: ${(props) => props.marginTop}px;
  margin-right: ${(props) => props.marginRight}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-left: ${(props) => props.marginLeft}px;
  color: ${(props) => colors[props.color]};
  opacity: ${(props) => props.opacity}%;
  ${(props) => applyTextStyle(props.textStyle, props.forceStyle ? props.forceStyle : undefined)}
  text-align: ${(props) => props.textAlign};
  cursor: ${(props) => (props.isCursorPointer ? 'pointer' : 'unset')};
  a {
    color: ${colors.primary1};
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
  }

  a:hover {
    color: ${colors.primary3};
  }
  ${({ withTextEllipsis }) => {
    if (withTextEllipsis) {
      return css`
        ${applyTextEllipsis()}
      `
    }
  }}}

${({ fontWeight }) => {
  if (fontWeight) {
    return css`
      font-weight: ${fontWeight} !important;
    `
  }
}}}

${({ withUnderline }) => {
  if (withUnderline) {
    return css`
      text-decoration: underline;
    `
  }
}}}
`

export default Text
