import React, { useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppState } from '../../context/AppContext'
import editUserService from '../../context/services/editUser.service'
import Button, { ButtonVariant } from '../Button'
import { departments } from '../../helpers/departments'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import FormikInput from './formControls/formik/FormikInput'
import Text from '../../components/Text'
import FormikDropdown from './formControls/formik/FormikDropdown'
import colors from '../../styles/colors'
import Icon from '../icon/Icon'
import strapi from '../../helpers/strapi.helper'
import { HUBSPOT_UPDATE } from '../../constants/api'

interface ChildProps {
  closeModal: any
}

const AdditionalContent: React.FC<ChildProps> = (props: ChildProps) => {
  const appState = useAppState()
  const appDispatch = useAppDispatch()
  const user = appState.user

  const [editUserProgress, setEditUserProgress] = useState<boolean>(false)
  const [editLevel, setLevel] = useState<string>(user?.level_of_experience || '')

  const mainProfileInitialValues: User = {
    full_name: user?.full_name || '',
    headline: user?.headline || '',
    city: user?.city || '',
    country: user?.country || '',
    bio: user?.bio || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    linkedin_url: user?.linkedin_url || '',
    twitter_url: user?.twitter_url || '',
    website_url: user?.website_url || '',
    department: user?.department || '',
    company: user?.company || '',
    job_title: user?.job_title || '',
    level_of_experience: user?.level_of_experience || '',
    additional_modal_shown: user?.additional_modal_shown || false,
  }

  const formValidationSchema = Yup.object({
    full_name: Yup.string().max(140, 'There is a maximum of 140 characters'),
    last_name: Yup.string().max(140, 'There is a maximum of 140 characters'),
    company: Yup.string().max(140, 'There is a maximum of 140 characters'),
    job_title: Yup.string().max(140, 'There is a maximum of 140 characters'),
  })

  const editMainProfile = async (values: User) => {
    values.level_of_experience = editLevel
    values.additional_modal_shown = true

    if (values.first_name && values.last_name) {
      values.full_name = values.first_name + ' ' + values.last_name
    }

    setEditUserProgress(true)
    const editMainProfileResponse = await editUserService(user?.id || '', values).catch((e: any) => {
      console.log(e)
    })
    if (editMainProfileResponse) {
      appDispatch({
        type: 'updateShouldLoadUser',
        payload: true,
      })
      appDispatch({
        type: 'updateModalWindow',
        payload: undefined,
      })
    }
    setEditUserProgress(false)
    window.dataLayer.push({
      event: 'Change in profile',
    })

    await strapi.request('post', HUBSPOT_UPDATE, { data: { first_name: values.first_name, last_name: values.last_name, company: values.company } })

    props.closeModal()
  }

  const closeModal = () => {
    editMainProfile(mainProfileInitialValues)
    props.closeModal()
  }

  return (
    <Formik initialValues={mainProfileInitialValues} validationSchema={formValidationSchema} onSubmit={editMainProfile}>
      {({ submitForm }) => (
        <Form>
          <InnerWrapper>
            <TopWrapper>
              <CrossWrapper>
                <StyledCloseIcon size={24} color={colors.tertiary1} type="Close" onClick={closeModal} />
              </CrossWrapper>

              <Text style="headline" color="primary2" marginBottom={20}>
                Tell us a bit about yourself
              </Text>
              <Text style="heading1" color="primary2" marginBottom={60}>
                so we can provide you with relevant updates about our future offerings, events, and promotions
              </Text>

              <FormWrapper>
                <FormikInput name="first_name" placeholder="Enter first name" label="What is your first name?" marginBottom={16} />
                <FormikInput name="last_name" placeholder="Enter last name" label="What is your last name?" marginBottom={16} />
                <FormikInput name="company" placeholder="Enter company name" label="Company name (if applicable)" marginBottom={16} />
                <FormikInput name="job_title" placeholder="Enter your job title" label="Job title" marginBottom={16} />

                {/* <ButtonSelectWrapper>
                  <SelectButton
                    variant={editLevel === 'Beginner' ? ButtonVariant.Primary : ButtonVariant.Secondary}
                    caption="Beginner"
                    onClick={() => setLevel('Beginner')}
                  />
                  <SelectButton
                    variant={editLevel === 'Novice' ? ButtonVariant.Primary : ButtonVariant.Secondary}
                    caption="Novice"
                    onClick={() => setLevel('Novice')}
                  />
                  <SelectButton
                    variant={editLevel === 'Expert' ? ButtonVariant.Primary : ButtonVariant.Secondary}
                    caption="Expert"
                    onClick={() => setLevel('Expert')}
                  />
                </ButtonSelectWrapper>

                <FormikDropdown name="department" label="What is your department?" marginBottom={16}>
                  <option value={user?.department}>{user?.department}</option>
                  {departments.map((dep, index) =>
                    dep !== user?.department ? (
                      <option key={dep + index} value={dep}>
                        {dep}
                      </option>
                    ) : null
                  )}
                  {departments.map((country, index) => (
                    <option key={country + index}>{country}</option>
                  ))}
                </FormikDropdown> */}
              </FormWrapper>
            </TopWrapper>

            <BottomWrapper>
              <StyleButtonDisabled variant={ButtonVariant.Secondary} caption="Skip" onClick={closeModal} />
              <StyleButton
                variant={ButtonVariant.Secondary}
                type="submit"
                disabled={editUserProgress}
                showLoader={editUserProgress}
                caption="Save"
                onClick={submitForm}
              />
            </BottomWrapper>
          </InnerWrapper>
        </Form>
      )}
    </Formik>
  )
}

const SelectButton = styled(Button)`
  margin-right: 20px;

  @media only screen and (max-width: 450px) {
    margin-right: 10px;
  }
`

const ButtonSelectWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`

const CrossWrapper = styled.div`
  height: 24px;
  width: calc(100% + 40px);
  position: relative;
  margin-top: -40px;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 450px) {
    width: calc(100% + 20px);
  }
`

const StyledCloseIcon = styled(Icon)`
  cursor: pointer;
`

const StyleButtonDisabled = styled(Button)`
  background-color: transparent;
  color: #969696;
  margin-left: 20px;
  min-width: 120px;
`

const StyleButton = styled(Button)`
  background-color: #2e34a2;
  color: white;
  margin-left: 20px;
  min-width: 100px;
`

const BottomWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  bottom: 0px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  max-height: 40px;

  @media only screen and (max-width: 450px) {
    justify-content: space-between;
  }
`
const TopWrapper = styled.div``

const FormWrapper = styled.div``

const InnerWrapper = styled.div`
  background-color: #f0f2f3;
  min-height: 65vh;
  width: 30vw;
  min-width: 600px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 60px 40px 60px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  overflow: hidden;

  @media only screen and (max-width: 950px) {
    padding: 30px;
    padding-top: 50px;
    min-width: 100%;
  }

  @media only screen and (max-width: 450px) {
    padding: 30px;
    padding-top: 50px;
    min-width: 100%;
  }
`

export default AdditionalContent
