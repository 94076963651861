import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconWritingPen = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 17 16"
    fill="none"
  >
    <title>Group 10</title>
    <defs>
      <filter colorInterpolationFilters="auto" id="filter-1">
        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g id="Hub" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="CDI-HUB-D-Jobs" transform="translate(-1096.000000, -280.000000)">
        <g id="Group-10" transform="translate(1080.000000, 269.000000)" filter="url(#filter-1)">
          <g transform="translate(16.000000, 11.000000)">
            <path
              d="M12.1337695,14.5846658 L1.41531878,14.5846658 C1.41531878,14.4754834 1.41531878,11.3801979 1.41531878,5.29880925 L7.50809351,5.29880925 L11.3915901,1.41531261 L0,1.41531261 C0,2.19198112 0,15.0100227 0,16 L13.5663483,16 C13.5663483,15.4119318 13.5663483,12.2130812 13.5663483,6.4034483 L12.1337695,7.8359346 C12.1337695,12.2545853 12.1337695,14.5041624 12.1337695,14.5846658 Z"
              id="Path"
              fill="#000000"
              fillRule="nonzero"
            />
            <path
              d="M5.41960449,10.5976247 C5.38505062,10.8047075 5.57491662,10.9945735 5.79932416,10.960146 L7.83594076,10.7012955 C8.1639113,10.6667417 8.45730022,10.5114295 8.68171085,10.2870251 L8.78525227,10.1834837 L6.21357613,7.59436261 L6.11003472,7.69802423 C5.88561792,7.92244102 5.73031504,8.23305295 5.69576425,8.54379432 L5.41960449,10.5976247 Z"
              id="Path"
              fill="#000000"
              fillRule="nonzero"
            />
            <path
              d="M16.1553152,1.67421239 L14.7055073,0.241633627 C14.5501952,0.0863215004 14.342986,0 14.1359278,0 C13.9288696,0 13.7217498,0.0689875439 13.5836699,0.241633627 L6.71419584,7.11110769 L9.28596443,9.68287628 L16.1554385,2.81340222 C16.4488428,2.50266085 16.4488428,1.98483048 16.1554385,1.67421239 L16.1553152,1.67421239 Z"
              id="Path"
              fill="#000000"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconWritingPen
