import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowDown = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" fill={props.color} />
  </svg>
)

export default IconArrowDown
