interface IRegisterForm {
  first_name: string
  last_name: string
  email: string
  password: string
  passwordConfirmation: string
}

const URL = `https://api.hsforms.com/submissions/v3/integration/submit/4106733/0a3cd59e-8c9e-47bd-a024-09e9613cfd70`

export const submitToHubSpotForm = async (values: IRegisterForm, hutk?: string) => {
  const data = {
    fields: [
      {
        name: 'email',
        value: values.email,
      },
      {
        objectTypeId: '0-1',
        name: 'firstname',
        value: values.first_name,
      },
      {
        objectTypeId: '0-1',
        name: 'lastname',
        value: values.last_name,
      },
    ],
    context: {
      hutk,
      pageUri: 'https://edu.conversationdesigninstitute.com/',
      pageName: 'Freemium Registration',
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow the Conversation Design Institute to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'I agree to receive marketing communications from Conversation Design Institute.',
          },
        ],
      },
    },
  }

  const response = await fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  const json = await response.json()
  console.log(json)
}

export const submitToHubSpotFormEmailonly = async (email: string, hutk?: string) => {
  const data = {
    fields: [
      {
        name: 'email',
        value: email,
      },
    ],
    context: {
      hutk,
      pageUri: 'https://edu.conversationdesigninstitute.com/',
      pageName: 'Freemium Registration',
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow the Conversation Design Institute to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'I agree to receive marketing communications from Conversation Design Institute.',
          },
        ],
      },
    },
  }

  const response = await fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  const json = await response.json()
  console.log(json)
}
