import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconLanguage = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.326 185.85h90.011v270.872H51.326V185.85zM96.934 55.278C66.127 55.278 46 75.503 46 102.049c0 26 19.538 46.813 49.756 46.813h.574c31.396 0 50.948-20.814 50.948-46.813-.589-26.546-19.551-46.771-50.344-46.771zm265.405 124.209c-47.779 0-69.184 26.28-81.125 44.71V185.85h-90.038c1.192 25.411 0 270.872 0 270.872h90.038V305.448c0-8.102.589-16.174 2.958-21.978 6.519-16.174 21.333-32.923 46.182-32.923 32.602 0 45.622 24.851 45.622 61.248v144.926H466V301.398c0-83.199-44.402-121.911-103.661-121.911z"
      fill={props.color}
    ></path>
  </svg>
)

export default IconLanguage
