import React from 'react'
import styled, { css } from 'styled-components'
import { FontFamily, FontWeight } from '../common/models/typography'
import colors from '../styles/colors'

export enum AvatarSize {
  ExtraSmall = 'extra_small', // 32x32
  Small = 'small', // 48x48
  Medium = 'medium', // 64x64
  Large = 'large', // 96x96
  ExtraLarge = 'extra_large', // 128x128
}

interface IProps {
  // Default size is small
  size?: AvatarSize
  imgSrc?: string
  className?: string
  user?: User
}

const Avatar: React.FC<IProps> = ({ size, imgSrc, className, user }) => {
  const fullNameInitials = user?.full_name ? `${user?.full_name?.slice(0, 1)}${user?.full_name?.charAt(user?.full_name.lastIndexOf(' ') + 1)}` : false
  const twoNamesInitials = `${user?.first_name?.slice(0, 1)}${user?.last_name?.charAt(user?.last_name.lastIndexOf(' ') + 1)}`

  return (
    <StyledAvatar size={size || AvatarSize.Small} className={className}>
      {!imgSrc && !user?.avatar_url && (user?.full_name || (user?.first_name && user.last_name)) && (
        <AvatarInitials size={size || AvatarSize.Small}>{fullNameInitials || twoNamesInitials}</AvatarInitials>
      )}
      {(imgSrc || user?.avatar_url) && <AvatarImage src={imgSrc || user?.avatar_url} />}
    </StyledAvatar>
  )
}

export const StyledAvatar = styled.figure<{ size: AvatarSize }>`
  position: relative;
  display: flex;
  margin: 0;
  flex-shrink: 0;
  ${({ size }) =>
    size === AvatarSize.ExtraSmall
      ? css`
          width: 32px;
          height: 32px;
        `
      : size === AvatarSize.Small
      ? css`
          width: 48px;
          height: 48px;
        `
      : size === AvatarSize.Medium
      ? css`
          width: 64px;
          height: 64px;
        `
      : size === AvatarSize.Large
      ? css`
          width: 96px;
          height: 96px;
        `
      : css`
          width: 128px;
          height: 128px;
        `}
  border-radius: 50%;
  overflow: hidden;
  background-color: ${colors.secondary2};
`

const AvatarInitials = styled.div<{ size: AvatarSize }>`
  position: absolute;
  color: ${colors.primary1};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: ${FontFamily.GTAmericaTrial};
  font-weight: ${FontWeight.Medium};
  ${({ size }) =>
    size === AvatarSize.ExtraSmall
      ? css`
          font-size: 12px;
        `
      : size === AvatarSize.Small
      ? css`
          font-size: 16px;
        `
      : size === AvatarSize.Medium
      ? css`
          font-size: 24px;
        `
      : size === AvatarSize.Large
      ? css`
          font-size: 32px;
        `
      : css`
          font-size: 40px;
        `}
`

const AvatarImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export default Avatar
