import styled from 'styled-components'
import colors from '../../../styles/colors'
import { applyTextStyle, applyTextEllipsis } from '../../../styles/typography'

export const FormControlError = styled.p`
  width: 100%;
  margin: 8px 0 0;
  padding-left: 5px;
  color: ${colors.tertiary4};
  ${applyTextStyle('label3')};
  ${applyTextEllipsis()}
  text-align: left;
`
