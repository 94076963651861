export const veryFastDuration = 200
export const fastDuration = 240
export const slowDuration = 320
export const verySlowDuration = 500

export const defaultEase = 'ease-in-out'
export const cubicEaseIn = 'cubic-bezier(0.4, 0, 0.2, 1)'

export const fastTransition = `${fastDuration}ms ${defaultEase}`
export const slowTransition = `${slowDuration}ms ${defaultEase}`
