import { Workplace, WorkplaceType } from '../enums/workplace.enum'

const workplaceText = (workplace: WorkplaceType) => {
  return workplace
    ? workplace === Workplace.remote
      ? '(Work from anywhere)'
      : workplace === Workplace.hybrid
        ? '(Hybrid)'
        : workplace === Workplace.onsite
          ? '(Office)'
          : ''
    : ''
}

export default workplaceText