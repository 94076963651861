import React from 'react'
import styled from 'styled-components'
import { FormControlProps } from '../../../common/models/FormControlProps'
import colors from '../../../styles/colors'
import { applyTextStyle } from '../../../styles/typography'
import Text from '../../Text'
import { FormControlError } from './FormControlError'

export interface IProps extends FormControlProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  height?: number
  withBorder?: boolean
  maxCharsCounter?: number
}

const TextArea: React.FC<IProps> = ({ className, placeholder, label, onChange, marginBottom, height, withBorder, defaultValue, maxCharsCounter, ...props }) => {
  const errorMessage = props.meta && props.meta.error && props.meta.touched ? props.meta.error : props.errorMessage

  return (
    <TextAreaWrapper className={className} marginBottom={marginBottom}>
      {label && (
        <Text marginBottom={12} style="label5" color="primary1">
          {label}
        </Text>
      )}
      <TextAreaInnerWrapper>
        <StyledTextArea
          {...props}
          defaultValue={defaultValue}
          height={height}
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
          withBorder={withBorder}
        ></StyledTextArea>

        <FormControlError>{errorMessage}</FormControlError>
        {maxCharsCounter !== undefined && (
          <Text marginTop={8} style="label5" color="primary1">
            {(props.value?.length || 0) + ' / ' + maxCharsCounter}
          </Text>
        )}
      </TextAreaInnerWrapper>
    </TextAreaWrapper>
  )
}

const TextAreaWrapper = styled.div<{ marginBottom?: number }>`
  position: relative;
  display: block;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  width: 100%;
`

const TextAreaInnerWrapper = styled.div`
  position: relative;
`

const StyledTextArea = styled.textarea<{ height?: number; withBorder?: boolean }>`
  display: block;
  width: 100%;
  background-color: ${colors.neutralWhite};
  outline: 0;
  border: ${({ withBorder }) => (withBorder ? `1px solid ${colors.primary1}` : 'none')};
  padding: 15px 19px;
  ${applyTextStyle('label4')};
  color: ${colors.secondary1};
  resize: none;
  height: ${({ height }) => (height ? height : 144)}px;
`

export default TextArea
