import React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/colors'
import Text from '../../Text'
import Icon from '../../icon/Icon'
import DatePickerFW from 'react-datepicker'
import { applyTextStyle } from '../../../styles/typography'
import { FormControlProps } from '../../../common/models/FormControlProps'
import { FormControlError } from './FormControlError'
import { Field } from 'formik'

export interface IProps extends FormControlProps {
  date?: Date
  defaultDate?: Date
  maxDate?: Date
  minDate?: Date
  disabled?: boolean
}

const DatePicker: React.FC<IProps> = ({ date, className, marginBottom, label, maxDate, minDate, disabled, ...props }) => {
  const errorMessage = props.meta && props.meta.error && props.meta.touched ? props.meta.error : props.errorMessage

  return (
    <DatePickerWrapper className={className} marginBottom={marginBottom}>
      <Text marginBottom={12} style="label5" color="primary1">
        {label}
      </Text>
      <DatePickerIconWrapper>
        <Field name={props.name}>
          {({ form, field }: any) => {
            const { setFieldValue } = form
            const { value } = field
            return (
              <DatePickerFWStyled
                id={props.name}
                {...field}
                {...props}
                selected={date ? date : value}
                onChange={(e) => {
                  setFieldValue(props.name, e)
                }}
                maxDate={maxDate}
                minDate={minDate}
                disabled={disabled}
                showYearDropdown
                showMonthDropdown
              />
            )
          }}
        </Field>

        <StyledIcon type="ArrowDown" color={colors.tertiary1} size={24} />
      </DatePickerIconWrapper>
      <FormControlError>{errorMessage}</FormControlError>
    </DatePickerWrapper>
  )
}

export default DatePicker

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 12px;
  top: 15px;
  pointer-events: none;
`

const DatePickerIconWrapper = styled.div`
  width: 100%;
  position: relative;
`

const DatePickerWrapper = styled.div<{ marginBottom?: number }>`
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  width: 100%;
`

const DatePickerFWStyled = styled(DatePickerFW)`
  ${applyTextStyle('label4')}
  width: 100%;
  overflow: visible;
  height: 54px;
  border: none;
  padding: 0px 20px 0 20px;
  color: ${colors.secondary1};
`
