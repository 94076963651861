import React from 'react'
import { useAppState } from '../context/AppContext'
import Filters, { IFilter } from './Forms/Filters'
import { certificates } from '../common/models/certificates'
import { Workplace } from '../enums/workplace.enum'

interface IProps {
  filteredJobs: (jobs: Job[]) => void
}

const JobsFilter: React.FC<IProps> = ({ filteredJobs }) => {
  const appState = useAppState()
  const jobs = appState.jobs

  // only the countries which are used in a job
  const countries: string[] = jobs && jobs.length > 0 ? [...Array.from(new Set([...jobs.flatMap((job) => job.job_country)]))] : []
  const certificatesOptions = ['None']
  certificates.forEach((certificate) => certificatesOptions.push(certificate.name))

  const filters: IFilter[] = [
    { header: 'Job date', fields: ['this month', 'next month', 'in two months', 'in three months', 'later than three months'], selectOnlyOne: false },
    { header: 'Workplace', fields: ['Office', 'Hybrid', 'Work from anywhere'], selectOnlyOne: false },
    { header: 'Country', fields: countries, selectOnlyOne: false },
    { header: 'Certification', fields: certificatesOptions, selectOnlyOne: false },
    { header: 'Workload', fields: ['Full-time', 'Part-time', 'Flex'], selectOnlyOne: false },
  ]

  const handleJobDateChanges = (jobPostDate: Date, selectedDateIndexes: number[]) => {
    for (const selectedIndex of selectedDateIndexes) {
      if (selectedIndex < 4) {
        if (checkInWhichMonth(jobPostDate, selectedDateIndexes[selectedIndex])) {
          return true
        }
      } else {
        if (isLaterThanThreeMonths(jobPostDate)) {
          return true
        }
      }
    }
  }

  const checkInWhichMonth = (jobPostDate: Date, monthsAhead: number) => {
    const nowDate = new Date()
    let nowYear = nowDate.getFullYear()
    let nowMonth = nowDate.getMonth() + monthsAhead

    const postDate = new Date(jobPostDate)
    const postYear = postDate.getFullYear()
    const postMonth = postDate.getMonth()

    if (nowMonth >= 12) {
      nowMonth %= 12
      nowYear++
    }
    return postYear === nowYear && postMonth === nowMonth
  }

  const checkRequiredCertificates = (selectedCertificates: string[], isNoneCertificatesSelected: boolean, requiredCertificates: Certificate[]) => {
    if (requiredCertificates.length === 0 && isNoneCertificatesSelected) {
      return true
    } else if (requiredCertificates.length > 0) {
      let isAtLeasOneSelected = false
      for (const requiredCertificate of requiredCertificates) {
        if (selectedCertificates.includes(requiredCertificate.name)) {
          isAtLeasOneSelected = true
        }
      }
      return isAtLeasOneSelected
    }
    return false
  }

  const isLaterThanThreeMonths = (jobPostDate: Date) => {
    const nowDate = new Date()
    let nowYear = nowDate.getFullYear()
    let afterThreeMonths = nowDate.getMonth() + 3

    const postDate = new Date(jobPostDate)
    const postYear = postDate.getFullYear()
    const postMonth = postDate.getMonth()

    if (afterThreeMonths >= 12) {
      afterThreeMonths %= 12
      nowYear++
    }
    return postYear > nowYear || (postYear === nowYear && postMonth > afterThreeMonths)
  }

  const handleFilter = (values: boolean[][]) => {
    const selectedDateIndexes: number[] = []
    values[0].forEach((val, index) => (val ? selectedDateIndexes.push(index) : undefined))
    const workplaces: string[] = []
    if (values[1][0]) workplaces.push(Workplace.onsite)
    if (values[1][1]) workplaces.push(Workplace.hybrid)
    if (values[1][2]) workplaces.push(Workplace.remote)
    const selectedCountries = countries.filter((country, index) => (values[2][index] ? country : null))
    const selectedCertificates = certificatesOptions.filter((certificate, index) => (values[3][index] ? certificate : null))
    const isNoneCertificatesSelected = values[3][0]
    const workloadValues = values[4]

    if (jobs) {
      const jobsToReturn = jobs
        .filter((job) => (selectedDateIndexes.length > 0 ? handleJobDateChanges(job.job_application_post_date, selectedDateIndexes) : job))
        .filter((job) => (workplaces.length > 0 ? job.workplace && workplaces.includes(job.workplace) : job))
        .filter((job) => (selectedCountries.length > 0 ? selectedCountries.includes(job.job_country) : job))
        .filter((job) =>
          selectedCertificates.length > 0
            ? checkRequiredCertificates(selectedCertificates, isNoneCertificatesSelected, job.required_certificates?.filter((skill) => skill.active) || [])
            : job
        )
        .filter((job) => {
          if (workloadValues.includes(true)) {
            if (Number(job.job_workload) === 0 && workloadValues[2]) return job
            else if (Number(job.job_workload) === 1 && workloadValues[0]) return job
            else if (job.job_workload && Number(job.job_workload) > 0 && Number(job.job_workload) < 1 && workloadValues[1]) return job
          } else {
            return job
          }
        })

      filteredJobs(jobsToReturn)
    }
  }

  return <Filters filters={filters} passValues={(values) => handleFilter(values)}></Filters>
}

export default JobsFilter
