import React, { useState } from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import { useAppState } from '../context/AppContext'
import editNotificationService from '../context/services/editNotification.service'
import Text from './Text'
import Icon from '../components/icon/Icon'
import { keyframes } from 'styled-components'
import { mqFrom } from '../styles/responsive'

interface IProps {
  className?: string
}

let initialNotifications: Notification[] = []
const autoRemoveNotifications: Notification[] = []

const Notifications: React.FC<IProps> = ({ className }) => {
  const appState = useAppState()
  const isAuthenticated = !!appState.userAuthToken
  const user = appState.user
  const notifications = appState.notifications

  if (!isAuthenticated || !user || !notifications) {
    return <> </>
  }

  // The component gets loaded multiple times.
  // To avoid notifications being removed multiple times we need to keep track of the initial notifications.
  if (initialNotifications.length === 0) {
    initialNotifications = notifications
  }

  if (autoRemoveNotifications.length === 0) {
    for (let i = 0; i < initialNotifications.length; i++) {
      if (initialNotifications[i].autohide) {
        autoRemoveNotifications.push(initialNotifications[i])
      }
    }

    for (let i = 0; i < autoRemoveNotifications.length; i++) {
      setTimeout(() => {
        removeNotification(autoRemoveNotifications[i])
      }, 3000)
    }
  }

  const initialList = notifications

  // eslint-disable-next-line
  const [notificationlist, setList] = useState<Notification[]>(initialList)

  const getStatuscolor = (status: number) => {
    switch (status) {
      case 0:
        return colors.primary4
        break
      case 1:
        return colors.tertiary5
        break
      case 2:
        return colors.tertiary3
        break
      default:
        return colors.tertiary4
        break
    }
  }

  const getStatusIcon = (status: number) => {
    switch (status) {
      case 0:
        return 'Info'
        break
      case 1:
        return 'Done'
        break
      case 2:
        return 'Warning'
        break
      default:
        return 'Error'
        break
    }
  }

  const removeNotification = (notification: Notification) => {
    if (notifications.indexOf(notification) > -1) {
      const duration = 1000

      const el = document.getElementById(`notification_${notification.id}`)
      el!.style.transition = 'opacity ' + duration / 1000 + 's ease'
      el!.style.opacity = '0'

      setTimeout(() => {
        const indx = notifications.indexOf(notification)
        const newList = notifications.splice(indx, 1)
        el!.style.transition = 'opacity ' + 0 + 's ease'
        el!.style.opacity = '1'

        setList(newList)
      }, duration)
    }
  }

  const disableNotification = (notification: Notification) => {
    if (notifications.indexOf(notification) > -1) {
      removeNotification(notification)
    }
    notification.active = false
    editNotificationService(notification?.id || '', notification)
  }

  return (
    <Wrapper className={className}>
      {notifications.length > 0 &&
        notifications.map((notification, index) => {
          // hideAfterTimeout(notification)
          return (
            <Container key={index} id={`notification_${notification.id}`} delay={index * 0.2} zindex={notifications.length - index}>
              <IconWrapper bgcolor={getStatuscolor(notification.notification_level)}>
                <StyledIcon type={getStatusIcon(notification.notification_level)} color={colors.neutralWhite} size={24} />
              </IconWrapper>

              <Content>
                <Text style="heading8">{notification.title}</Text>
                <Text style="heading9" opacity={60}>
                  {notification.text}
                </Text>
              </Content>
              <Actions>
                {notification.user_can_disable && (
                  <DisableButton>
                    <Text style="heading9" withUnderline={true} opacity={60} onClick={() => disableNotification(notification)}>
                      Delete
                    </Text>
                  </DisableButton>
                )}
                <CloseButton onClick={() => removeNotification(notification)}>
                  <StyledIcon type="Close" color="black" size={24} />
                </CloseButton>
              </Actions>
            </Container>
          )
        })}
    </Wrapper>
  )
}

// ANIMATION
const slideIn = keyframes`
 0% { transform: translateY(-50vh) }
 100% { transform: translateY(0vh) }
`

// COMPONENT CSS
const StyledIcon = styled(Icon)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Container = styled.div<{ delay: number; zindex: number }>`
  margin: 5px;
  display: flex;
  flex-direction: row;
  background-color: ${colors.neutralWhite};
  border-radius: 0px;
  width: 90%;
  ${mqFrom.L`
    width: 50%;
    `}

  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: ${({ zindex }) => zindex};

  animation-name: ${slideIn};
  animation-duration: 1s;
  animation-delay: ${({ delay }) => delay}s;
  animation-fill-mode: both;
`

const IconWrapper = styled.div<{ bgcolor: string }>`
  width: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 0px;
  max-height: 100%;
  background: ${({ bgcolor }) => bgcolor};
  margin-left: -5px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 200px;
`

const Actions = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DisableButton = styled.div`
  margin-left: 10px;
  padding-bottom: 1px;
  cursor: pointer;
`

const CloseButton = styled.div`
  height: 100%;
  padding: 5px;
  cursor: pointer;
`

const Wrapper = styled.div`
  z-index: 200;
  position: fixed;
  margin-top: 170px; // Height of menu
  ${mqFrom.L`
      margin-top: 100px;
    `}

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default Notifications
