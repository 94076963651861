import React, { useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch } from '../../context/AppContext'
import Button from '../Button'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import FormikInput from './formControls/formik/FormikInput'
import changePasswordService from '../../context/services/changePassword.service'

interface IChangePasswordForm {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

const SettingsForm: React.FC = () => {
  const appDispatch = useAppDispatch()

  const [changePasswordProgress, setChangePasswordProgress] = useState<boolean>(false)

  const settingsInitialValues: IChangePasswordForm = {
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  }

  const settingsValidationSchema = Yup.object({
    currentPassword: Yup.string().required('Required'),
    newPassword: Yup.string().required('Required').min(8, 'At least 8 characters'),
    passwordConfirmation: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  })

  const changePassword = async (values: IChangePasswordForm) => {
    setChangePasswordProgress(true)
    const changePasswordResponse = await changePasswordService(values.currentPassword, values.newPassword).catch((e: any) => {
      console.log(e)
    })

    if (changePasswordResponse) {
      appDispatch({
        type: 'updateModalWindow',
        payload: undefined,
      })
    }
    setChangePasswordProgress(false)
  }

  return (
    <Formik initialValues={settingsInitialValues} validationSchema={settingsValidationSchema} onSubmit={changePassword}>
      {({ submitForm }) => (
        <Form>
          <FormikInput name="currentPassword" placeholder="Enter your current password" label="Password" marginBottom={16} type="password" />
          <FormikInput name="newPassword" placeholder="Enter your new password" label="New password" marginBottom={16} type="password" />
          <FormikInput name="passwordConfirmation" placeholder="Confirm your password" label="Password confirmation" marginBottom={40} type="password" />
          <Actions>
            <Button
              caption="Cancel"
              onClick={() =>
                appDispatch({
                  type: 'updateModalWindow',
                  payload: undefined,
                })
              }
            />
            <Button type='submit' onClick={submitForm} caption="Save" disabled={changePasswordProgress} showLoader={changePasswordProgress} />
          </Actions>
        </Form>
      )}
    </Formik>
  )
}

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`

export default SettingsForm
