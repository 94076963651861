import React, { useEffect } from 'react'
import { COURSE, ENROLLEMENTS, EVENTS, HUBSPOT_STATUS, JOB, NOTIFICATION } from '../constants/api'
import strapi from '../helpers/strapi.helper'
import { useAppDispatch, useAppState } from './AppContext'
import logEnrollmentsService from './services/logEnrollments.service'
import { useHistory } from 'react-router'
import getPrimaryCompanyService from './services/company/getPrimaryCompany.service'

const GlobalStateDataFetch: React.FC = () => {
  const appDispatch = useAppDispatch()
  const appState = useAppState()
  const history = useHistory()
  const loadingState = appState.loadingState
  const loadedState = appState.loadedState

  useEffect(() => {
    // eslint-disable-next-line
    ;(async () => {
      if (loadedState.isUserLoaded) {
        appDispatch({
          type: 'updateCourses',
          payload: [],
        })
        getAndStoreCourses()
        getAndStoreEvents()
      }
      // getAndStorePosts()
    })()
  }, [loadedState.isUserLoaded])
  useEffect(() => {
    // eslint-disable-next-line
    ;(async () => {
      if (appState.userAuthToken) {
        await getAndStoreHubSpotUserStatus()
        await getAndStoreUser()
        getAndStoreEvents()
        getAndStoreEnrollments()
        getAndStoreNotifications()
      } else {
        appDispatch({ type: 'updateIsUserLoaded', payload: true })
      }
    })()
  }, [appState.userAuthToken])

  useEffect(() => {
    // eslint-disable-next-line
    ;(async () => {
      if (appState.userAuthToken && loadingState.shouldLoadUser) {
        appDispatch({ type: 'updateShouldLoadUser', payload: false })
        getAndStoreUser()
      }
    })()
  }, [appState.userAuthToken, loadingState.shouldLoadUser])

  useEffect(() => {
    // eslint-disable-next-line
    ;(async () => {
      if (loadingState.shouldLoadJobs) {
        appDispatch({ type: 'updateShouldLoadJobs', payload: false })
        getAndStoreJobs()
      }
    })()
  }, [loadingState.shouldLoadJobs])

  const getAndStoreUser = async () => {
    const user = (await strapi.fetchUser()) as User
    if (user) {
      await getAndStoreUserWithCertificates(user)
    }
  }

  const getAndStoreCourses = async () => {
    let courses: Course[] = (await strapi.request('get', COURSE)) as Course[]

    if (appState.user?.primaryCompany?.disabledCourses) {
      courses = courses.filter((c) => !appState.user?.primaryCompany?.disabledCourses.find((disabledCourse) => c.name === disabledCourse.name)) || []
    }
    if (courses) appDispatch({ type: 'updateCourses', payload: courses })
  }

  const getAndStoreNotifications = async () => {
    const notifications = (await strapi.request('get', NOTIFICATION)) as Notification[]

    if (notifications) appDispatch({ type: 'updateNotifications', payload: notifications })
  }

  const getAndStoreJobs = async () => {
    const jobs = (await strapi.request('get', JOB)) as Job[]
    const jobsFilterd = jobs.filter((item: Job) => item.name && item.payment_status === 'payed')

    if (jobs) appDispatch({ type: 'updateJobs', payload: jobsFilterd })
  }

  const getAndStoreEnrollments = async () => {
    // log enrollments for debugging
    appDispatch({ type: 'updateEnrollmentsLoading', payload: true })

    const enrollments = (await strapi.request('get', ENROLLEMENTS)) as Enrollement[]
    await logEnrollmentsService(JSON.stringify(enrollments))

    appDispatch({ type: 'updateEnrollmentsLoading', payload: false })

    if (enrollments) appDispatch({ type: 'updateEnrollments', payload: enrollments })
  }

  const getAndStoreUserWithCertificates = async (user: User) => {
    const userForGlobalState = user
    const certificates = (await strapi.request('get', `/certificates?_where[0][user.email]=${user.email}`)) as Certificate[]

    if (user?.primaryCompanyId) {
      userForGlobalState.primaryCompany = await getPrimaryCompanyService(user?.primaryCompanyId)
    }

    if (certificates) {
      user.certificates = []
      certificates.forEach((certificate) => {
        userForGlobalState?.certificates?.push(certificate)
      })
      appDispatch({ type: 'updateUser', payload: userForGlobalState })
      appDispatch({ type: 'updateIsUserLoaded', payload: true })
    }
  }

  // const getAndStorePosts = async () => {
  //   const posts = (await strapi.request('get', POSTS)) as Post[]

  //   if (posts) {
  //     appDispatch({
  //       type: 'updatePosts',
  //       payload: posts,
  //     })
  //   }
  // }

  const getAndStoreEvents = async () => {
    if (appState.user?.primaryCompany?.isEventAreaDisabled === true || !appState.userAuthToken) return
    const events = (await strapi.request('get', EVENTS)) as CdiEvent[]

    if (events) {
      appDispatch({ type: 'updateEvents', payload: events })
    }
  }

  const getAndStoreHubSpotUserStatus = async () => {
    try {
      await strapi.request('get', HUBSPOT_STATUS)
    } catch (err: any) {
      if (err.status === 401) {
        console.log('Token not valid')
        appDispatch({ type: 'updateUserToken', payload: undefined })
        history.push('/login')
      }
    }
  }

  return <></>
}

export default GlobalStateDataFetch
