import React, { useEffect, useState } from 'react'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import Text from '../components/Text'
import styled from 'styled-components'
import Section from '../components/Section'
import Button from '../components/Button'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import FormikInput from '../components/Forms/formControls/formik/FormikInput'
import { useHistory } from 'react-router'
import strapi from '../helpers/strapi.helper'
import { useAppDispatch, useAppState } from '../context/AppContext'
import { useParams } from 'react-router-dom'

interface IPasswordResetForm {
  password: string
  passwordConfirmation: string
}

const PasswordResetPage: React.FC = () => {
  const appDispatch = useAppDispatch()
  const params: { code: string } = useParams()
  const history = useHistory()
  const appState = useAppState()
  const isAuthenticated = !!appState.userAuthToken

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard')
    }
  }, [isAuthenticated])

  const [passwordResetProgress, setPasswordResetProgress] = useState<boolean>(false)

  const passwordResetSubmit = async (values: IPasswordResetForm) => {
    setPasswordResetProgress(true)
    const passwordResetResponse = await strapi.resetPassword({ ...values, code: params.code }).catch((e: any) => {
      appDispatch({
        type: 'updateModalWindow',
        payload: { content: e.message.message, dismissText: 'ok' },
      })
    })

    if (passwordResetResponse && passwordResetResponse.jwt) {
      appDispatch({
        type: 'updateUserToken',
        payload: passwordResetResponse.jwt,
      })
      history.push('/dashboard')
    }
    setPasswordResetProgress(false)
  }

  const passwordResetInitialValues: IPasswordResetForm = {
    password: '',
    passwordConfirmation: '',
  }

  const passwordResetValidationSchema = Yup.object({
    password: Yup.string().required('Required').min(8, 'At least 8 characters'),
    passwordConfirmation: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })

  return (
    <Formik initialValues={passwordResetInitialValues} validationSchema={passwordResetValidationSchema} onSubmit={passwordResetSubmit}>
      {({ submitForm }) => (
        <AppPage>
          <AppTop>
            <Text style="headline" color="neutralWhite" marginBottom={4}>
              Conversational Experience Courses
            </Text>
            <Text style="heading2" color="neutralWhite">
              Courses to get you started
            </Text>
          </AppTop>

          <StyledAppContainer>
            <Section header="Password reset" forceStyleHeader="mobile" marginBottomAfterLine={57.5}>
              <Form>
                <FormikInput name="password" placeholder="Enter your password" label="Password" marginBottom={16} type="password" />
                <FormikInput name="passwordConfirmation" placeholder="Confirm your password" label="Password confirmation" marginBottom={40} type="password" />
                <Actions>
                  <Button type='submit' disabled={passwordResetProgress} showLoader={passwordResetProgress} onClick={submitForm} caption="Send" />
                </Actions>
              </Form>
            </Section>
          </StyledAppContainer>
        </AppPage>
      )}
    </Formik>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-top: 38px;
  max-width: 395px;
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`

export default PasswordResetPage
