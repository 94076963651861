import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppState } from '../../context/AppContext'
import editUserService from '../../context/services/editUser.service'
import Button from '../Button'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import FormikInput from './formControls/formik/FormikInput'
import Logo from '../Logo'
import FormikDatePicker from './formControls/formik/FormikDatePicker'
import Link from '../Link'
import FormikCheckbox from './formControls/formik/FormikCheckbox'
import strapi from '../../helpers/strapi.helper'
import { UPLOAD } from '../../constants/api'

interface IProps {
  isEditMode: boolean
  editEducation?: UserEducation
  editIndex?: number
  changeIsOnEdit?: (isVisible: boolean) => void
  passIsOnAdd?: (isOnAdd: boolean) => void
}

const AddOrEditEducationForm: React.FC<IProps> = ({ isEditMode, editEducation, editIndex, changeIsOnEdit, passIsOnAdd }) => {
  const appState = useAppState()
  const appDispatch = useAppDispatch()
  const user = appState.user
  const [actionInProgress, setActionInProgress] = useState<boolean>(false)
  const [isOnAdd, setIsOnAdd] = useState<boolean>(false)

  const [company, setCompany] = useState<{ name: string; logo: string }>({ name: editEducation?.school_name || '', logo: editEducation?.logo_url || '' })
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<any>(undefined)
  const fileInputRef: any = useRef(null)

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile)
      setCompany({ ...company, logo: objectUrl })
    }
  }, [selectedFile])

  const educationInitialValues: UserEducation = {
    school_name: !isOnAdd && isEditMode && editEducation ? editEducation.school_name : '',
    study_title: !isOnAdd && isEditMode && editEducation ? editEducation.study_title : '',
    end_date: !isOnAdd && isEditMode && editEducation ? new Date(editEducation.end_date!) : new Date(),
    passed: !isOnAdd && isEditMode && editEducation ? editEducation?.passed : true,
  }

  const educationValidationSchema = Yup.object({
    school_name: Yup.string().required('Required'),
    study_title: Yup.string().required('Required'),
    end_date: Yup.date().required('Required'),
    passed: Yup.bool(),
  })

  const addOrEditEducation = async (values: UserEducation) => {
    setActionInProgress(true)
    let url = undefined
    if (selectedFile) {
      const formData = new FormData()
      formData.append('files', selectedFile)
      await strapi.request('post', UPLOAD, { data: formData }).then(async (response: any) => {
        url = response[0].url
      })
    }
    const userEducation = user?.education
    if (isEditMode) {
      if (userEducation && editIndex !== undefined) {
        userEducation[editIndex] = { ...values, logo_url: url ? url : company.logo }
      }
    } else {
      userEducation?.push({ ...values, logo_url: url ? url : company.logo })
    }

    await editUserService(user?.id || '', { education: userEducation })

    appDispatch({
      type: 'updateShouldLoadUser',
      payload: true,
    })
    changeIsVisible(false)
    setActionInProgress(false)
    window.dataLayer.push({
      event: 'Change in profile',
    })
  }

  const deleteEducation = async () => {
    if (editIndex !== undefined) {
      const userEducation = user?.education
      userEducation?.splice(editIndex, 1)
      await editUserService(user?.id || '', {
        education: userEducation,
      })
      appDispatch({
        type: 'updateShouldLoadUser',
        payload: true,
      })
      changeIsVisible(false)
      window.dataLayer.push({
        event: 'Change in profile',
      })
    }
  }

  const changeIsVisible = (isVisible: boolean) => {
    changeIsOnEdit ? changeIsOnEdit(isVisible) : undefined
    setIsFormVisible(isVisible)
  }

  const showAddForm = () => {
    passIsOnAdd ? passIsOnAdd(true) : undefined
    setIsFormVisible(true)
    setIsOnAdd(true)
  }

  const hideAddForm = () => {
    passIsOnAdd ? passIsOnAdd(false) : undefined
    setIsFormVisible(false)
    setIsOnAdd(false)
  }

  return (
    <Formik initialValues={educationInitialValues} validationSchema={educationValidationSchema} onSubmit={addOrEditEducation}>
      {({ submitForm, setFieldValue, resetForm, values }) => (
        <Form>
          {isFormVisible && (
            <>
              <Logo size={96} imgSrc={company.logo} />
              <InputTypeFile ref={fileInputRef} onChange={async (e: any) => setSelectedFile(e.target.files[0])} type="file" />
              <StyledPickFileButton caption="Upload logo" onClick={() => fileInputRef?.current?.click()} />
              <FormikInput
                onCompaniesAutocompleteChange={(company) => {
                  setSelectedFile(undefined)
                  fileInputRef.current.value = ''
                  setCompany({ ...company })
                  setFieldValue('school_name', company.name)
                }}
                withCompaniesAutocomplete={true}
                name="school_name"
                placeholder="Enter school name"
                label="School name"
                marginBottom={16}
              />
              <FormikInput name="study_title" placeholder="Enter study title" label="Study title" marginBottom={16} />
              <FormikDatePicker name="end_date" marginBottom={16} label="Ending date" maxDate={new Date()} disabled={values.passed === false} />
              <FormikCheckbox defaultChecked={educationInitialValues.passed} name="passed" marginBottom={28} label="I completed this education" />
            </>
          )}
          {!isEditMode && (
            <Actions>
              {isFormVisible && <Button caption="Cancel" onClick={() => hideAddForm()} />}
              <Button
                caption="Add Education"
                disabled={actionInProgress}
                showLoader={actionInProgress}
                onClick={() => {
                  if (isFormVisible) {
                    submitForm()
                  } else {
                    showAddForm()
                    resetForm()
                    setCompany({ name: '', logo: '' })
                  }
                }}
              />
            </Actions>
          )}
          {isEditMode && (
            <>
              <EditLinks>
                {isFormVisible && <Link color="tertiary4" caption="Delete" onClick={() => deleteEducation()} />}
                {isFormVisible && <Link color="tertiary1" caption="Cancel" onClick={() => changeIsVisible(false)} />}
                <Link caption={isFormVisible ? 'Save' : 'Edit'} onClick={() => (isFormVisible ? submitForm() : changeIsVisible(true))} color="tertiary1" />
              </EditLinks>
            </>
          )}
        </Form>
      )}
    </Formik>
  )
}

const Actions = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-end;
`

const EditLinks = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`

const InputTypeFile = styled.input`
  display: none;
`

const StyledPickFileButton = styled(Button)`
  margin-top: 10px;
`

export default AddOrEditEducationForm
