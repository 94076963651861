import React from 'react'
import styled from 'styled-components'
import { Color } from '../common/models/colors'
import colors from '../styles/colors'
import { applyTextStyle } from '../styles/typography'

interface IProps {
  caption: string
}

enum CertificateName {
  AITrainer = 'AI Trainer',
  ConversationDesigner = 'Conversation designer',
  ConversationalCopywriter = 'Conversational copywriter',
  CxDForWhatsApp = 'CxD for WhatsApp',
  TheVoiceCourse = 'The Voice Course',
  Rasa = 'Rasa',
  QBox = 'QBox',
  CognigyAI = 'Cognigy.AI',
}

const Certificate: React.FC<IProps> = ({ caption }) => {
  const getCertificateColor = (caption: string) => {
    let color: Color
    switch (caption) {
      case CertificateName.AITrainer:
        color = 'primary1'
        break
      case CertificateName.ConversationDesigner:
        color = 'primary4'
        break
      case CertificateName.ConversationalCopywriter:
        color = 'tertiary5'
        break
      case CertificateName.CxDForWhatsApp:
        color = 'primary1'
        break
      case CertificateName.TheVoiceCourse:
        color = 'primary1'
        break
      case CertificateName.Rasa:
        color = 'primary1'
        break
      case CertificateName.QBox:
        color = 'primary1'
        break
      case CertificateName.CognigyAI:
        color = 'primary1'
        break
      default:
        color = 'secondary4'
        break
    }
    return color
  }
  return (
    <StyledCertificate>
      <ColoredDot color={getCertificateColor(caption)} />
      <Label>{caption}</Label>
    </StyledCertificate>
  )
}

const StyledCertificate = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px 14px;
  background-color: ${colors.neutralWhite};
`

const ColoredDot = styled.div<{ color: Color }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ color }) => colors[color]};
  margin-right: 8px;
`

const Label = styled.label`
  ${applyTextStyle('label1')};
  color: ${colors.primary4};
`

export default Certificate
