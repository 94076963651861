import React from 'react'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import Text from '../components/Text'
import styled from 'styled-components'
import Button, { ButtonVariant } from '../components/Button'
import { useHistory } from 'react-router'
import { useAppState } from '../context/AppContext'
import { useParams } from 'react-router-dom'
import { cdiCourseModuleOrder } from '../common/models/cdiCourseModuleOrder'
import EnrollmentCard from '../components/cards/EnrollmentCard'

const YourCoursesPage: React.FC = () => {
  const appState = useAppState()
  const history = useHistory()
  const params: { name: string } = useParams()

  const courses = appState.courses
  const enrollments = appState.enrollements
  const allAccess = enrollments?.find((enrollment) => enrollment.enrollment_name === 'Intro to All-Course Access') ? true : false
  const cdiCourses = cdiCourseModuleOrder.find((cdiEnrollment) => cdiEnrollment.name === params.name)
  let coursesArray = cdiCourses?.enrollments.map((enrollment) => {
    const course = courses?.find((course) => course.name === enrollment)
    if (course) return course
  })

  //  Logic for all access course and specific bundles
  if (allAccess) {
    coursesArray = coursesArray?.filter((course) => course?.name !== 'Intro to Conversation Designer Course')
    coursesArray = coursesArray?.filter((course) => course?.name !== 'Intro to AI Trainer Course')
    coursesArray = coursesArray?.filter((course) => course?.name !== 'Intro to Conversational Copywriter Course')
  } else {
    if (!enrollments?.find((enrollment) => enrollment.enrollment_name === 'Intro to Conversation Designer Course')) {
      coursesArray = coursesArray?.filter((course) => course?.name !== 'Intro to Conversation Designer Course')
    }

    if (!enrollments?.find((enrollment) => enrollment.enrollment_name === 'Intro to AI Trainer Course')) {
      coursesArray = coursesArray?.filter((course) => course?.name !== 'Intro to AI Trainer Course')
    }

    if (!enrollments?.find((enrollment) => enrollment.enrollment_name === 'Intro to Conversational Copywriter Course')) {
      coursesArray = coursesArray?.filter((course) => course?.name !== 'Intro to Conversational Copywriter Course')
    }
  }

  return (
    <AppPage>
      <AppTop inverted={true}>
        <Text style="headline" color="primary2" marginBottom={4}>
          {params.name}
        </Text>
        <Text style="heading2" color="primary1">
          Overview of all the {params.name} modules
        </Text>
      </AppTop>
      <AppContainerStyled>
        <StyledButton
          caption="Your dashboard"
          onClick={() => history.push('/dashboard')}
          variant={ButtonVariant.Tertiary}
          textColor="primary2"
          icon="ArrowLeft"
          textStyle="label8"
          iconOnLeft
        />
        <EnrollmentsContainer>
          {coursesArray?.map((course) => (
            <StyledEnrollmentCard key={course?.id} course={course}></StyledEnrollmentCard>
          ))}
        </EnrollmentsContainer>
      </AppContainerStyled>
    </AppPage>
  )
}

const AppContainerStyled = styled(AppContainer)`
  margin-top: 30px;
`

const StyledButton = styled(Button)`
  margin-bottom: 30px;
`

const EnrollmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
`

const StyledEnrollmentCard = styled(EnrollmentCard)`
  max-width: 213px;
  height: 400px;
`

export default YourCoursesPage
