import React, { useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { Form, Formik, useFormikContext } from 'formik'
import FormikInput from './formControls/formik/FormikInput'
import Text from './../Text'
import styled from 'styled-components'
import Button, { ButtonVariant } from '../Button'
import strapi from '../../helpers/strapi.helper'
import { UPLOAD } from '../../constants/api'
import Logo from '../Logo'

export interface IJobCompanyForm {
  name: string
  company_logo_url: string
  company_url: string
}

interface IProps {
  onSubmit: (values: IJobCompanyForm) => void
  back: () => void
  initialValues?: IJobCompanyForm
  triggerSubmit?: boolean
  onSubmitBtnClick?: () => void
}

const JobCompanyForm: React.FC<IProps> = ({ onSubmit, back, initialValues, triggerSubmit, onSubmitBtnClick }) => {
  const jobDetailsInitialValues: IJobCompanyForm = {
    name: '',
    company_logo_url: '',
    company_url: '',
  }

  const jobDetailsValidationSchema = Yup.object({
    name: Yup.string().required('Required'),
    company_logo_url: Yup.string().required('Required'),
    company_url: Yup.string().required('Required'),
  })

  const OutsideSubmitTrigger = () => {
    const { submitForm } = useFormikContext()
    useEffect(() => {
      if (triggerSubmit) {
        submitForm()
      }
    }, [triggerSubmit])
    return null
  }

  const fileInputRef: any = useRef(null)

  const uploadFile = async (selectedFile: any, setFieldValue: any) => {
    if (selectedFile) {
      const formData = new FormData()
      formData.append('files', selectedFile)
      await strapi.request('post', UPLOAD, { data: formData }).then(async (response: any) => {
        const url = response[0].url
        setFieldValue('company_logo_url', url)
      })
    }
  }

  return (
    <Formik initialValues={initialValues ? initialValues : jobDetailsInitialValues} validationSchema={jobDetailsValidationSchema} onSubmit={onSubmit}>
      {({ submitForm, setFieldValue, values }) => (
        <Form>
          <Text marginBottom={12} style="label5" color="primary1">
            Enter your company info
          </Text>
          <FormikInput
            onCompaniesAutocompleteChange={(company) => {
              setFieldValue('name', company.name)
              setFieldValue('company_url', company.domain)
              setFieldValue('company_logo_url', company.logo)
              fileInputRef.current.value = ''
            }}
            withCompaniesAutocomplete={true}
            name="name"
            placeholder="Enter a company name"
            label="Company Name"
            marginBottom={16}
          />
          <FormikInput name="company_url" placeholder="Enter a company URL" label="Company URL" marginBottom={16} />
          <FormikInput name="company_logo_url" placeholder="Enter a company Logo URL" label="Company Logo URL" />
          <LogoWrapper>
            <Logo size={96} imgSrc={values.company_logo_url} />
          </LogoWrapper>
          <InputTypeFile ref={fileInputRef} onChange={async (e: any) => uploadFile(e.target.files[0], setFieldValue)} type="file" />
          <StyledPickFileButton caption="Upload logo" onClick={() => fileInputRef?.current?.click()} />
          <ButtonsContainer>
            <Button caption="Previous" variant={ButtonVariant.Secondary} onClick={back} />
            <Button
              caption="Next"
              type='submit'
              onClick={() => {
                submitForm()
                onSubmitBtnClick ? onSubmitBtnClick() : undefined
              }}
            />
          </ButtonsContainer>
          <OutsideSubmitTrigger />
        </Form>
      )}
    </Formik>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 40px;
`

const InputTypeFile = styled.input`
  display: none;
`
const LogoWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
`

const StyledPickFileButton = styled(Button)`
  margin-top: 10px;
`

export default JobCompanyForm
