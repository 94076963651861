import React from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import Text from './../Text'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import workplaceText from '../../helpers/workplace.helper'
dayjs.extend(relativeTime)
interface IProps {
  className?: string
  job?: Job
}

const JobCard: React.FC<IProps> = ({ className, job }) => {
  return (
    <Card className={className}>
      <Image>{job?.company?.company_logo_url && <img src={job.company.company_logo_url} alt=""></img>}</Image>
      <div>
        <Text marginBottom={6} color="primary1" style="label3">
          {dayjs(job?.job_application_post_date).from(dayjs())}
        </Text>
        <Text marginBottom={10} color="primary1" style="heading6" forceStyle="desktop">
          {job?.name || 'Job Name'}
        </Text>
        <TextsContainer>
          <Text marginRight={4} color="primary1" style="heading2" forceStyle="mobile">
            {job?.company?.name || 'Company Name'}
          </Text>
          <SeparatingLine />
          <Text color="primary1" style="heading2" forceStyle="mobile">
            {job?.job_country || 'Country'} {workplaceText(job?.workplace)}
          </Text>
        </TextsContainer>
        <Text color="secondary1" style="description">
          {calculateWorkLoad(job?.job_workload)}
        </Text>
      </div>
    </Card>
  )
}

const calculateWorkLoad = (value?: number) => {
  if (!value) return '0FTE'
  if (value === 1) return 'Full time'
  return `Part time (${value}FTE)`
}

const Card = styled.div`
  display: flex;
`
const Image = styled.div`
  width: 96px;
  height: 96px;
  /* background-color: ${colors.secondary2}; */
  margin-right: 24px;
  flex-shrink: 0;
`

const TextsContainer = styled.div`
  display: flex;
  margin-bottom: 3px;
  align-items: center;
`

const SeparatingLine = styled.div`
  height: 10px;
  width: 1.5px;
  background-color: ${colors.secondary1};
  margin-right: 5px;
`

export default JobCard
