import React from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: React.ReactNode
}
const AppContainer: React.FC<IProps> = ({ className, children }) => (
  <Wrapper className={className}>{children}</Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1140px;
`

export default AppContainer
