import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import strapi from '../helpers/strapi.helper'
import { useAppState } from '../context/AppContext'
import { DISCOURSE_SSO } from '../constants/api'

const DiscourseSSO: React.FC = () => {
  const appState = useAppState()
  const history = useHistory()
  const isAuthenticated = !!appState.userAuthToken

  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const sso = query.get('sso')
  const sig = query.get('sig')

  if (isAuthenticated) {
    if (sso && sig) {
      useEffect(() => {
        // eslint-disable-next-line
        ;(async () => {
          getdiscouseLink(sso, sig)
        })()
      }, [])
    } else {
      history.push('/login')
    }
  }

  if (!isAuthenticated) {
    history.push('/login')
  }

  return <></>
}

const getdiscouseLink = async (sso: string, sig: string) => {
  const link: any = await strapi.request('get', DISCOURSE_SSO + '?sso=' + sso + '&sig=' + sig)
  const directLink = link.sso as string
  window.location.replace(directLink)
}

export default DiscourseSSO
