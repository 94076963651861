export const baseCDICourses: Course[] = [
  {
    img_url: 'https://import.cdn.thinkific.com/354495/v8hizwvqSBmZQls1z0DI_bundle_img1.png',
    name: 'Conversation Designer',
    percentage_completed: 0,
    moduleHeader: true,
    owned: false,
    buy_link: 'https://www.conversationdesigninstitute.com/courses/conversation-designer',
  },
  {
    img_url: 'https://import.cdn.thinkific.com/354495/fQuFTBtoRkGT2ZPZHEyj_bundle_img3.png',
    name: 'AI Trainer',
    percentage_completed: 0,
    moduleHeader: true,
    owned: false,
    buy_link: 'https://www.conversationdesigninstitute.com/courses/ai-trainer',
  },
  {
    img_url: 'https://import.cdn.thinkific.com/354495/2XRpKhriTNG7lqD0Yy0r_bundle_img2.png',
    name: 'Conversational Copywriter',
    percentage_completed: 0,
    moduleHeader: true,
    owned: false,
    buy_link: 'https://www.conversationdesigninstitute.com/courses/conversational-copywriter',
  },
  {
    img_url: 'https://import.cdn.thinkific.com/354495/courses/2147754/RR5lnE1hR2GJkMX8UC4o_Workflow%20course%20slides%20%281%29.png',
    name: 'Conversational AI Workflow',
    percentage_completed: 0,
    moduleHeader: true,
    owned: false,
    buy_link: 'https://www.conversationdesigninstitute.com/courses/conversational-ai-workflow',
  },
  {
    img_url:
      'https://import.cdn.thinkific.com/354495/courses/2338187/D2zaHzZCSTyiQABxIo5V_Olaveyou_a_chatbot_in_a_maze_3d562aa9-f171-433c-997a-f03c7b69af31.png',
    name: 'Prompt Designer for Conversational AI',
    percentage_completed: 0,
    moduleHeader: true,
    owned: false,
    buy_link: 'https://www.conversationdesigninstitute.com/courses/prompt-designer-for-conversational-ai',
  },
]
