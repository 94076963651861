import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../styles/colors'
import Text from '../components/Text'
import Checkbox from './Forms/formControls/Checkbox'
import { technologies } from '../common/models/technologies'
import { certificates } from '../common/models/certificates'
import { interfaces } from '../common/models/interfaces'

interface IProps {
  border?: boolean
  selecting?: boolean
  job?: Job
}

const SkillsContainer: React.FC<IProps> = ({ border, selecting, job }) => {
  const isBorder = border ? border : false
  const isSelecting = selecting ? selecting : false

  let certifcatesData = job?.required_certificates ? job?.required_certificates : certificates
  let technologyData = job?.required_certificates ? job?.required_technologies : technologies
  let interfaceData = job?.required_certificates ? job?.required_interfaces : interfaces

  if (!selecting) {
    certifcatesData = certifcatesData?.filter((skill) => skill.active)
    technologyData = technologyData?.filter((skill) => skill.active)
    interfaceData = interfaceData?.filter((skill) => skill.active)
  }

  return (
    <SkillsAndCertificationContainer isBorder={isBorder}>
      {certifcatesData.length > 0 && (
        <Text style="heading1" color="primary1" marginBottom={18} forceStyle="mobile">
          CDI certification needed
        </Text>
      )}
      <SectionWrapper>
        {certifcatesData?.map((skill, index) => (
          <Skill key={skill.name + index}>
            {isSelecting && (
              <CheckBoxWrapper>
                <Checkbox defaultChecked={skill.active} onChange={() => (skill.active = !skill.active)} />
              </CheckBoxWrapper>
            )}
            <ImageContainer>
              <Image src={skill.img} alt={skill.name} />
            </ImageContainer>
            <Text style="heading2" forceStyle="mobile" color="primary1">
              {skill.name}
            </Text>
          </Skill>
        ))}
      </SectionWrapper>
      {technologyData.length > 0 && (
        <Text style="heading1" color="primary1" marginBottom={18} forceStyle="mobile">
          Skills & Technologies needed
        </Text>
      )}
      <SectionWrapper>
        {technologyData?.map((skill, index) => (
          <Skill key={skill.name + index}>
            {isSelecting && (
              <CheckBoxWrapper>
                <Checkbox defaultChecked={skill.active} onChange={() => (skill.active = !skill.active)} />
              </CheckBoxWrapper>
            )}
            <ImageContainer>
              <Image src={skill.img} alt={skill.name} />
            </ImageContainer>
            <Text style="heading2" forceStyle="mobile" color="primary1">
              {skill.name}
            </Text>
          </Skill>
        ))}
      </SectionWrapper>
      {interfaceData.length > 0 && (
        <Text style="heading1" color="primary1" marginBottom={18} forceStyle="mobile">
          Interface experience needed
        </Text>
      )}
      <SectionWrapper>
        {interfaceData?.map((skill, index) => (
          <Skill key={skill.name + index}>
            {isSelecting && (
              <CheckBoxWrapper>
                <Checkbox defaultChecked={skill.active} onChange={() => (skill.active = !skill.active)} />
              </CheckBoxWrapper>
            )}
            <ImageContainer>
              <Image src={skill.img} alt={skill.name} forceSmall={skill.forceSmall} />
            </ImageContainer>
            <Text style="heading2" forceStyle="mobile" color="primary1">
              {skill.name}
            </Text>
          </Skill>
        ))}
      </SectionWrapper>
    </SkillsAndCertificationContainer>
  )
}

const SkillsAndCertificationContainer = styled.div<{ isBorder: boolean }>`
  padding: 23px 0 32px 0;
  border-top: 1px solid ${colors.secondary2};
  border-bottom: 1px solid ${colors.secondary2};
  margin-bottom: 20px;

  ${({ isBorder }) => {
    if (!isBorder) {
      return css`
        border-top: 0px solid ${colors.secondary2};
        border-bottom: 0px solid ${colors.secondary2};
      `
    }
  }}
`

const CheckBoxWrapper = styled.div`
  width: 20px;
  margin-right: 15px;
`

const Skill = styled.div`
  display: flex;
  flex: 50%;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 16px;
  }
`

const SectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  min-width: 100%;
`

const ImageContainer = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  opacity: 1;
  background: ${colors.secondary5};
  position: relative;
`

const Image = styled.img<{ forceSmall?: boolean }>`
  ${({ forceSmall }) => {
    if (forceSmall) {
      return css`
        width: 36px;
        height: 36px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `
    }
  }}
`

export default SkillsContainer
