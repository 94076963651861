import React, { useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppState } from '../../context/AppContext'
import editUserService from '../../context/services/editUser.service'
import { countries } from '../../helpers/countries'
import Button from '../Button'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import FormikInput from './formControls/formik/FormikInput'
import FormikDropdown from './formControls/formik/FormikDropdown'
import FormikTextArea from './formControls/formik/FormikTextArea'

const MainProfileForm: React.FC = () => {
  const appState = useAppState()
  const appDispatch = useAppDispatch()
  const user = appState.user

  const [editUserProgress, setEditUserProgress] = useState<boolean>(false)

  const mainProfileInitialValues: User = {
    full_name: user?.full_name || '',
    headline: user?.headline || '',
    city: user?.city || '',
    country: user?.country || '',
    bio: user?.bio || '',
    linkedin_url: user?.linkedin_url || '',
    twitter_url: user?.twitter_url || '',
    website_url: user?.website_url || '',
  }

  const mainProfileValidationSchema = Yup.object({
    full_name: Yup.string().required('Required'),
    headline: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    bio: Yup.string().max(140, 'There is a maximum of 140 characters'),
  })

  const editMainProfile = async (values: User) => {
    setEditUserProgress(true)
    const editMainProfileResponse = await editUserService(user?.id || '', values).catch((e: any) => {
      console.log(e)
    })
    if (editMainProfileResponse) {
      appDispatch({
        type: 'updateShouldLoadUser',
        payload: true,
      })
      appDispatch({
        type: 'updateModalWindow',
        payload: undefined,
      })
    }
    setEditUserProgress(false)
    window.dataLayer.push({
      event: 'Change in profile',
    })
  }

  return (
    <Formik initialValues={mainProfileInitialValues} validationSchema={mainProfileValidationSchema} onSubmit={editMainProfile}>
      {({ submitForm }) => (
        <Form>
          <FormikInput name="full_name" placeholder="Enter full name" label="Full name" marginBottom={16} />
          <FormikInput name="headline" placeholder="Enter headline" label="Headline" marginBottom={16} />
          <FormikInput name="city" placeholder="Enter city" label="City" marginBottom={16} />
          <FormikDropdown name="country" label="Country" marginBottom={16}>
            <option value={user?.country}>{user?.country}</option>
            {countries.map((country, index) =>
              country !== user?.country ? (
                <option key={country + index} value={country}>
                  {country}
                </option>
              ) : null
            )}
            {countries.map((country, index) => (
              <option key={country + index}>{country}</option>
            ))}
          </FormikDropdown>
          <FormikTextArea name="bio" placeholder="Enter bio" label="Bio" marginBottom={16} maxCharsCounter={140} />
          <FormikInput name="linkedin_url" placeholder="Your LinkedIn url" label="Linkedin" marginBottom={16} />
          <FormikInput name="twitter_url" placeholder="Your Twitter url" label="Twitter" marginBottom={16} />
          <FormikInput name="website_url" placeholder="Your website url" label="Website" marginBottom={16} />
          <Actions>
            <Button
              caption="Cancel"
              onClick={() =>
                appDispatch({
                  type: 'updateModalWindow',
                  payload: undefined,
                })
              }
            />
            <Button type="submit" onClick={submitForm} caption="Save" disabled={editUserProgress} showLoader={editUserProgress} />
          </Actions>
        </Form>
      )}
    </Formik>
  )
}

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`

export default MainProfileForm
