import { REGISTER_USER, REGISTER_ENTERPRISE } from '../../constants/api'
import strapi from '../../helpers/strapi.helper'

export default async (data: User) =>
  await strapi.request('POST', REGISTER_USER, {
    data: {
      ...data,
    },
  })

export async function enterpriseRegister(data: User) {
  return await strapi.request('POST', REGISTER_ENTERPRISE, {
    data: {
      ...data,
    },
  })
}
