import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconAdd = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
      fill={props.color}
    />
  </svg>
)

export default IconAdd
