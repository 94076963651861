import { ENROLLEMENTS_LOG } from '../../constants/api'
import strapi from '../../helpers/strapi.helper'

export default async (enrollmentsString: string) =>
  await strapi
    .request('POST', ENROLLEMENTS_LOG, {
      data: {
        enrollmentsString,
      },
    })
