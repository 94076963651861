import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import Text from '../components/Text'
import { mqFrom } from '../styles/responsive'
import Section from '../components/Section'
import { useHistory } from 'react-router'
import ProfileCard from '../components/cards/ProfileCard'
import { useAppState } from '../context/AppContext'
import LoginFirst from '../components/LoginFirst'
import { Helmet } from 'react-helmet'
import { CourseCategories } from '../enums/courseCategories.enum'
import { cdiCourseModuleOrder } from '../common/models/cdiCourseModuleOrder'
import EventsList from '../components/EventsList'
import EnrollmentCard from '../components/cards/EnrollmentCard'
import { ScaleLoader } from 'react-spinners'
import { baseCDICourses } from '../common/models/baseCDICourses'
import { enterpriseProducts } from '../common/models/enterpriseProducts'
import AdditionalContent from '../components/Forms/AdditionalContent'

const HomePage: React.FC = () => {
  const history = useHistory()
  const appState = useAppState()

  const [isModalAdditionalInfo, setModalAdditionalInfo] = useState(true)

  const user = appState.user
  const { isUserLoaded } = appState.loadedState
  const isAuthenticated = !!appState.userAuthToken
  const enrollments = appState.enrollements
  const courses = appState.courses
  const events = appState.events
  const loading = appState.enrollmentsLoading
  const freemiumUser =
    enrollments && enrollments.find((enrollment) => enrollment.enrollment_name === 'Trial course' || enrollment.enrollment_name === 'Designing AI Assistants')
  const enterpriseUser = user?.primaryCompany
  const RMITUser = user?.primaryCompany?.name === 'RMIT'
  const allAccess = enrollments?.find((enrollment) => enrollment.enrollment_name === 'Intro to All-Course Access') ? true : false
  const isGoogleUser = user?.email?.substring(0, user?.email.indexOf('@')) === user?.username
  const showModal = user?.additional_modal_shown ? false : true

  const handleParentClick = (event: any) => {
    event.preventDefault()

    if (event.target === event.currentTarget) {
      setModalAdditionalInfo(false)
    }
  }

  const closeModal = () => {
    setModalAdditionalInfo(false)
  }

  //Set access to CDI Main courses
  if (
    enrollments?.find(
      (enrollment) =>
        enrollment.enrollment_name === 'Intro to Conversation Designer Course' ||
        enrollment.enrollment_name === 'Intro to AI Trainer Course' ||
        enrollment.enrollment_name === 'Intro to Conversational Copywriter Course'
    )
  ) {
    baseCDICourses[0].owned = true
    baseCDICourses[1].owned = true
    baseCDICourses[2].owned = true
  }

  if (allAccess) {
    baseCDICourses[0].owned = true
    baseCDICourses[1].owned = true
    baseCDICourses[2].owned = true
  }

  if (RMITUser) {
    baseCDICourses[1].owned = false
    baseCDICourses[2].owned = false
  }

  //Calculate process for CDI Courses
  baseCDICourses.forEach((course, index) => {
    const cdiCourse = cdiCourseModuleOrder.find((cdiEnrollment) => cdiEnrollment.name === course.name)

    if (cdiCourse) {
      let percentageSum = 0
      let enrollmentsCount = 0
      for (const moduleName of cdiCourse.enrollments) {
        if (moduleName === 'Downloadables') continue
        const enrollement = enrollments?.find((enrollement) => enrollement.enrollment_name === moduleName)
        if (enrollement) {
          enrollmentsCount++
          percentageSum += enrollement.percentage_completed || 0
        }
      }
      baseCDICourses[index].percentage_completed = percentageSum / enrollmentsCount
    }
  })

  //Define CDI modules
  const allModules = cdiCourseModuleOrder.map((item) => item.enrollments).flat()

  //Get additional CDI courses
  let additionalCDICourses =
    courses?.filter(
      (course) =>
        course.category === CourseCategories.CDICourses &&
        !allModules.includes(course.name) &&
        course.name !== 'AI Trainer Course' &&
        course.name !== 'Conversational Copywriter Course' &&
        course.name !== 'Conversation Designer Course' &&
        course.name !== 'All-Course Access Overview' &&
        !course.hidden
    ) || []

  //unhide IN course for users that are enrolled
  additionalCDICourses =
    additionalCDICourses?.filter((course) => {
      if (course.name === 'Conversation Design Course (IN)') {
        if (enrollments?.find((e) => e.enrollment_name === course?.name)) return true
        return false
      }
      return true
    }) || []

  //add enterprise course
  additionalCDICourses =
    additionalCDICourses?.filter((course) => {
      if (course.name === 'Welcome for Enterprise Teams from Hans van Dam') {
        if (enrollments?.find((e) => e.enrollment_name === course?.name)) return true
        return false
      }
      return true
    }) || []

  //Logic for all access course
  additionalCDICourses = additionalCDICourses.filter((course) => {
    if (!allAccess && course.name === 'Intro to All-Course Access') return false
    return true
  })

  //Return everyting
  const allCDICourses = [...baseCDICourses, ...additionalCDICourses]

  //Get additional courses
  let additionalCourses = courses?.filter(
    (course) =>
      course.category !== CourseCategories.CDICourses &&
      course.name !== 'Trial course' &&
      course.name !== 'Conversation Design Course (IN)' &&
      course.name !== 'Designing AI Assistants' &&
      course.name !== 'CDI Course Materials Tour' &&
      course.name !== 'Maximizing Chatbot Success: Metrics and Management' &&
      !course.hidden
  )

  //  hide RMIT course for additional users
  additionalCourses = additionalCourses?.filter((course) => {
    if (course.name === 'CxD in The Field | RMIT Interviews with Peter Thomas') {
      if (RMITUser) return true
      return false
    }
    return true
  })

  //Sort additional courses on owned
  additionalCourses?.sort((x, y) => {
    return enrollments?.find((e) => e.enrollment_name === x?.name) ? -1 : enrollments?.find((e) => e.enrollment_name === y?.name) ? 1 : 0
  })

  //Get free courses
  const freeCourses = courses?.filter(
    (course) =>
      course.name === 'Trial course' ||
      course.name === 'Designing AI Assistants' ||
      course.name === 'CDI Course Materials Tour' ||
      course.name === 'Introduction to Conversation Design' ||
      course.name === 'Maximizing Chatbot Success: Metrics and Management' ||
      (course.name === 'Welcome for Enterprise Teams from Hans van Dam' &&
        enrollments?.find((e) => e.enrollment_name === 'Welcome for Enterprise Teams from Hans van Dam'))
  )

  const four = 'Maximizing Chatbot Success: Metrics and Management'
  freeCourses?.sort(function (x, y) {
    return x.name == four ? -1 : y.name == four ? 1 : 0
  })

  const third = 'Designing AI Assistants'
  freeCourses?.sort(function (x, y) {
    return x.name == third ? -1 : y.name == third ? 1 : 0
  })

  const second = 'Trial course'
  freeCourses?.sort(function (x, y) {
    return x.name == second ? -1 : y.name == second ? 1 : 0
  })

  const first = 'CDI Course Materials Tour'
  freeCourses?.sort(function (x, y) {
    return x.name == first ? -1 : y.name == first ? 1 : 0
  })

  const [matches, setMatches] = useState(window.matchMedia('(min-width: 1024px)').matches)

  useEffect(() => {
    window.matchMedia('(min-width: 1024px)').addEventListener('change', (e) => setMatches(e.matches))
  }, [])

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login')
    }
  }, [isAuthenticated])

  const firstContent = (
    <>
      <ProfileCard className="shepherd-profile" user={user} />
      {!user?.primaryCompany?.isEventAreaDisabled && isUserLoaded && (
        <EventsSection header="Upcoming events">
          <EventsList events={events || []} />
        </EventsSection>
      )}
    </>
  )

  const secondContent = (
    <>
      <StyledSectionYourCourses className="shepherd-your-courses" header="Your Courses" link="" clickLink={() => history.push('/shop')}>
        {loading && (
          <>
            <LoaderWrapper>
              <ScaleLoader color="#23CC7B" loading={loading} height={50} />
              <LoadingText>Loading your courses...</LoadingText>
            </LoaderWrapper>
          </>
        )}

        {!loading && (freemiumUser || enterpriseUser || !enrollments) && !RMITUser && freeCourses && freeCourses?.length > 0 && (
          <Section smallHeader header="Free courses" withTopBorder>
            <AditionalCoursesContainer>
              {freeCourses?.map((course: Course) => (
                <StyledEnrollmentCard key={course.id} course={course} />
              ))}
            </AditionalCoursesContainer>
          </Section>
        )}

        {!loading && allCDICourses?.length > 0 && (
          <Section smallHeader header="CDI Courses" withTopBorder>
            <AditionalCoursesContainer>
              {allCDICourses?.map((course) => (
                <StyledEnrollmentCard key={course.id} course={course} />
              ))}
            </AditionalCoursesContainer>
          </Section>
        )}

        {!loading && enterpriseUser && !RMITUser && (
          <Section smallHeader header="Enterprise products" withTopBorder>
            <AditionalCoursesContainer>
              {enterpriseProducts?.map((course) => (
                <StyledEnrollmentCard key={course.id} course={course} />
              ))}
            </AditionalCoursesContainer>
          </Section>
        )}

        {!loading && additionalCourses && additionalCourses?.length > 0 && (
          <Section smallHeader header="Additional courses & content" withTopBorder>
            <AditionalCoursesContainer>
              {additionalCourses?.map((course) => (
                <StyledEnrollmentCard key={course.id} course={course} />
              ))}
            </AditionalCoursesContainer>
          </Section>
        )}

        {!loading && ((!freemiumUser && !enterpriseUser) || RMITUser) && freeCourses && freeCourses?.length > 0 && (
          <Section smallHeader header="Free courses" withTopBorder>
            <AditionalCoursesContainer>
              {freeCourses?.map((course: Course) => (
                <StyledEnrollmentCard key={course.id} course={course} />
              ))}
            </AditionalCoursesContainer>
          </Section>
        )}
      </StyledSectionYourCourses>
    </>
  )

  return (
    <AppPage>
      <Helmet>
        <meta property="og:title" content="Conversational Design Institute Home" />
        <meta property="og:description" content="Your personal homepage. Welcome to your conversational lounge." />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content="english" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={process.env.REACT_APP_CDI_LOGO_URL} />
      </Helmet>

      {isModalAdditionalInfo && isUserLoaded && showModal && isGoogleUser && (
        <InfoWrapper onClick={handleParentClick}>
          <AdditionalContent closeModal={closeModal} />
        </InfoWrapper>
      )}
      <AppTop inverted={true}>
        {isAuthenticated && (
          <>
            <Text style="headline" color="primary2" marginBottom={4}>
              {`Welcome back ${user?.full_name?.split(' ')[0] || ''}!`}
            </Text>
            <Text style="heading2" color="primary1">
              This is your personal dashboard
            </Text>
          </>
        )}
        {!isAuthenticated && <LoginFirst />}
      </AppTop>

      {isAuthenticated && (
        <StyledAppContainer>
          {matches && (
            <DesktopContent>
              <LeftPart>{secondContent}</LeftPart>
              <RightPart>{firstContent}</RightPart>
            </DesktopContent>
          )}
          {!matches && (
            <MobileContent>
              {firstContent}
              {secondContent}
            </MobileContent>
          )}
        </StyledAppContainer>
      )}
    </AppPage>
  )
}

const InfoWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #00000050;
  color: #fff;
  padding: 20px;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  z-index: 1000;
`

const LoaderWrapper = styled.div`
  text-align: center;
`

const LoadingText = styled(Text)`
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
`

const StyledAppContainer = styled(AppContainer)`
  margin-top: 30px;
  ${mqFrom.L`
    margin-top: 24px;
  `}
`

const DesktopContent = styled.div`
  display: none;
  justify-content: space-between;
  gap: 60px;
  ${mqFrom.L`
    display: flex;
  `}
`

const LeftPart = styled.div`
  width: 700px;
  flex-direction: column;
  display: flex;
`

const RightPart = styled.div`
  display: flex;
  width: 370px;
  flex-direction: column;
`

const MobileContent = styled.div`
  max-width: 540px;
  margin: 0 auto;
  ${mqFrom.L`
    display: none;
  `}
`

const StyledSectionYourCourses = styled(Section)`
  margin-top: 35px;

  ${mqFrom.L`
    margin-top: 23px;
  `}
`

const EventsSection = styled(Section)`
  margin-top: 70px;
`

const AditionalCoursesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
`

const StyledEnrollmentCard = styled(EnrollmentCard)`
  width: 208px;
  height: 400px;
`

export default HomePage
