import { CHANGE_PASSWORD } from '../../constants/api';
import strapi from '../../helpers/strapi.helper';

export default async (currentPassword: string, newPassword: string) =>
  await strapi.request('POST', CHANGE_PASSWORD, {
    data: {
      currentPassword: currentPassword,
      newPassword: newPassword
    },
  })
