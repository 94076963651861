import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import SearchBar from './Forms/SearchBar'
import colors from '../styles/colors'
import { applyTextStyle } from '../styles/typography'
import Logo from '../images/logo.svg'
import { useLocation } from 'react-router-dom'
import Icon from './icon/Icon'
import Text from './Text'
import { mqFrom } from '../styles/responsive'
import { slowTransition } from '../styles/animations'
import { useAppDispatch, useAppState } from '../context/AppContext'
import DropdownSelect from './DropdownSelect'
import strapi from '../helpers/strapi.helper'
import Avatar, { AvatarSize } from './Avatar'
import Button, { ButtonVariant } from './Button'
import getNewStripePortalService from '../context/services/stripe/getNewStripePortal.service'
import SettingsForm from './Forms/SettingsForm'
import { USERS } from '../constants/api'
import openLink from '../helpers/openLink.helper'
import formatSlug from '../helpers/formatSlug.helper'

interface IProps {
  className?: string
  logoLink?: string
}

type OpenedPages = 'dashboard' | 'shop' | 'forum' | 'jobboard' | 'login' | 'members' | undefined

type SearchCategories = 'Users' | 'Jobs' | 'Courses'

type ProfileHeaders = 'Profile' | 'Logout' | 'Support' | 'Subscription' | 'Tour' | 'Settings' | 'Members'

const searchCategoriesStore: SearchCategories[] = ['Jobs', 'Courses', 'Users']

const Navigation: React.FC<IProps> = ({ className, logoLink }) => {
  const appState = useAppState()
  const isAuthenticated = !!appState.userAuthToken
  const courses = appState.courses
  const jobs = appState.jobs
  const user = appState.user
  const { isUserLoaded } = appState.loadedState
  const appDispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const [openedPage, setOpenedPage] = useState<OpenedPages>()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  useState<boolean>(false)
  const [isProfileDropdownVisible, setIsProfileDropdownVisible] = useState<boolean>(false)
  const [isMobileProfileDropdownVisible, setIsMobileProfileDropdownVisible] = useState<boolean>(false)
  const [filteredUsers, setFilteredUsers] = useState<User[]>([])
  const [filteredJobs, setFilteredJobs] = useState<Job[]>([])
  const [filteredCourses, setFilteredCourses] = useState<Course[]>([])
  const isOnLanding = location.pathname === '/'
  const profileHeaders: ProfileHeaders[] = ['Profile', 'Support', 'Settings', 'Logout']
  const isUserSubscribed = isAuthenticated && appState.user && appState.user.stripe_billing_portal ? true : false
  if (isUserSubscribed) {
    profileHeaders.push('Subscription')
  }
  if (isAuthenticated) {
    profileHeaders.push('Tour')
  }
  const [searchCategories, setSearchCategories] = useState<SearchCategories[]>(searchCategoriesStore)

  // for the desktop nav underlines
  useEffect(() => {
    const path = location.pathname
    if (path.startsWith('/shop')) {
      setOpenedPage('shop')
    } else if (path.startsWith('/dashboard')) {
      setOpenedPage('dashboard')
    } else if (path.startsWith('/jobboard')) {
      setOpenedPage('jobboard')
    } else if (path.startsWith('/members')) {
      setOpenedPage('members')
    } else {
      setOpenedPage(undefined)
    }
  }, [location.pathname])

  const openSupport = () => {
    const newWindow = window.open(process.env.REACT_APP_SUPPORT_URL, '_self')
    if (newWindow) newWindow.opener = null
  }

  const openSubscription = async () => {
    if (isAuthenticated && appState.user && appState.user.stripe_billing_portal) {
      const response: any = await getNewStripePortalService()
      if (response.stripe_billing_portal) {
        const newWindow = window.open(response.stripe_billing_portal, '_self')
        if (newWindow) newWindow.opener = null
      }
    }
  }

  const clickProfileHeader = (index: number) => {
    setIsMobileMenuOpen(false)
    if (profileHeaders[index] === 'Profile') {
      history.push(`/profile/${user?.id}`)
    } else if (profileHeaders[index] === 'Support') {
      openSupport()
    } else if (profileHeaders[index] === 'Subscription') {
      openSubscription()
    } else if (profileHeaders[index] === 'Tour') {
      history.push('/dashboard')
      appDispatch({
        type: 'updateShouldOpenTour',
        payload: true,
      })
    } else if (profileHeaders[index] === 'Logout') {
      strapi.logout()
      appDispatch({
        type: 'updateUserToken',
        payload: undefined,
      })
      appDispatch({
        type: 'updateUser',
        payload: undefined,
      })
      history.push('/login')
    } else if (profileHeaders[index] === 'Settings') {
      appDispatch({
        type: 'updateModalWindow',
        payload: {
          hideActions: true,
          content: <SettingsForm></SettingsForm>,
        },
      })
    } else if (profileHeaders[index] === 'Members') {
      history.push('/members')
    }
  }

  const searchBar = <SearchBar value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

  useEffect(() => {
    setFilteredJobs(
      jobs
        ?.filter((job) => {
          if (searchTerm === '') {
            return job
          } else if (job.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return job
          }
        })
        .slice(0, 25) || []
    )
    setFilteredCourses(
      courses
        ?.filter((course) => {
          if (searchTerm === '') {
            return course
          } else if (course.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return course
          }
        })
        .slice(0, 25) || []
    )

    if (isAuthenticated && searchTerm !== '') {
      // eslint-disable-next-line
      ;(async () => {
        usersRes =
          ((await strapi.request('get', USERS + `?_limit=25&_where[0][public_setting]=true&_where[1][full_name_contains]=${searchTerm}`)) as User[]) || []
        setFilteredUsers(usersRes)
      })()
    } else {
      setFilteredUsers([])
    }
    let usersRes: User[] = []
  }, [searchTerm])

  useEffect(() => {
    if (user?.primaryCompany) {
      setSearchCategories([
        ...searchCategoriesStore
          .filter((c) => (user.primaryCompany?.isMemberAreaDisabled ? c !== 'Users' : true))
          .filter((c) => (user.primaryCompany?.isShopDisabled ? c !== 'Courses' : true))
          .filter((c) => (user.primaryCompany?.isJobboardDisabled ? c !== 'Jobs' : true)),
      ])
    }
  }, [user?.primaryCompany])

  useEffect(() => {
    if (!isAuthenticated) {
      setSearchCategories([...searchCategoriesStore.filter((c) => c !== 'Users')])
    }
  }, [isAuthenticated])

  return (
    <NavBar className={className}>
      <Menu navbarColor={user?.primaryCompany?.navbarColor || colors.primary3} className="shepherd-navigation">
        <NavContentContainer isOnLanding={isOnLanding} id="testx">
          <a href={logoLink} target="_blank" rel="noreferrer">
            {user?.primaryCompany?.company_logo_url && isUserLoaded && (
                <LogoImg src={user?.primaryCompany?.company_logo_url || Logo}></LogoImg>
            )}
            {!user?.primaryCompany && isUserLoaded && <LogoImg src={Logo}></LogoImg>}
          </a>
          {!isOnLanding && <SearchBarWrapperDesktop>{searchBar}</SearchBarWrapperDesktop>}
          <NavButtonsDesktop>
            {(isAuthenticated || isOnLanding) && (
              <DesktopNavLink
                isOnLanding={isOnLanding}
                selected={openedPage === 'dashboard'}
                to={isAuthenticated ? '/dashboard' : '/login'}
                onClick={() => {
                  if (!isAuthenticated && isOnLanding) {
                    window.dataLayer.push({
                      event: 'Login clicked',
                    })
                  }
                }}
              >
                {isAuthenticated ? 'Dashboard' : 'Login'}
              </DesktopNavLink>
            )}
            {!isOnLanding && (
              <>
                {!user?.primaryCompany?.isShopDisabled && isUserLoaded && (
                  <DesktopNavLink
                    onClick={() => {
                      window.dataLayer.push({
                        event: 'Shop clicked',
                      })
                    }}
                    selected={openedPage === 'shop'}
                    to="/shop"
                  >
                    Shop
                  </DesktopNavLink>
                )}
                {!user?.primaryCompany?.isForumDisabled && isUserLoaded && (
                  <DesktopNavLink selected={openedPage === 'forum'} onClick={() => openLink(process.env.REACT_APP_COMMUNITY_URL)} to={location.pathname}>
                    Forum
                  </DesktopNavLink>
                )}
                {isAuthenticated && !user?.primaryCompany?.isMemberAreaDisabled && isUserLoaded && (
                  <DesktopNavLink selected={openedPage === 'members'} to="/members">
                    Members
                  </DesktopNavLink>
                )}
              </>
            )}
            {!user?.primaryCompany?.isJobboardDisabled && isUserLoaded && (
              <DesktopNavLink isOnLanding={isOnLanding} selected={openedPage === 'jobboard'} to="/jobboard">
                Jobs
              </DesktopNavLink>
            )}
            {isAuthenticated && !isOnLanding && (
              <Profile onClick={() => setIsProfileDropdownVisible(!isProfileDropdownVisible)} mobileMenuOpen={false}>
                <AvatarStyled user={user} size={AvatarSize.Small} />
                <DropdownSelect
                  textStyle="label8"
                  headers={profileHeaders}
                  setActiveHeader={(index) => clickProfileHeader(index)}
                  isVisible={isProfileDropdownVisible}
                  top={45}
                  onOutsideClick={() => setIsProfileDropdownVisible(false)}
                ></DropdownSelect>
              </Profile>
            )}
            {!isAuthenticated && (
              <ToLoginButton
                icon="Arrow"
                iconColor={colors.neutralWhite}
                caption={!isOnLanding ? 'Login' : 'Sign up free'}
                onClick={() => {
                  history.push(!isOnLanding ? '/login' : '/register')
                  if (isOnLanding) {
                    window.dataLayer.push({
                      event: 'Sign up free clicked',
                    })
                  }
                }}
                textStyle="label9"
                iconSize={15}
              />
            )}
          </NavButtonsDesktop>
          <NavButtonsMobile onClick={() => setIsMobileMenuOpen(true)}>
            <Text style="label5" color="neutralWhite" marginRight={15}>
              Menu
            </Text>
            <Icon type="Menu" size={24} color={colors.neutralWhite} />
          </NavButtonsMobile>
        </NavContentContainer>
      </Menu>
      {!isOnLanding && (
        <MobileSearchBarContainer>
          <SearchBarWrapperMobile>{searchBar}</SearchBarWrapperMobile>
        </MobileSearchBarContainer>
      )}
      <MobileMenu navbarColor={user?.primaryCompany?.mobileNavbarColor || colors.tertiary2} open={isMobileMenuOpen}>
        <MobileMenuTop>
          <a href={logoLink} target="_blank" rel="noreferrer">
            <LogoImg
              src={user?.primaryCompany?.company_logo_url || Logo}
            ></LogoImg>
          </a>
          <NavButtonsMobile onClick={() => setIsMobileMenuOpen(false)}>
            <Text style="label5" color="primary1" marginRight={15}>
              Menu
            </Text>
            <Icon type="Menu" size={24} color={colors.primary1} />
          </NavButtonsMobile>
        </MobileMenuTop>
        {(isAuthenticated || isOnLanding) && (
          <MobileNavItem
            onClick={() => {
              if (!isAuthenticated && isOnLanding) {
                window.dataLayer.push({
                  event: 'Login clicked',
                })
              }
              setIsMobileMenuOpen(false)
            }}
            to={isAuthenticated ? '/dashboard' : '/login'}
          >
            <Text style="label5" color="primary1">
              {isAuthenticated ? 'Dashboard' : 'Login'}
            </Text>
          </MobileNavItem>
        )}
        {!isOnLanding && isUserLoaded && (
          <>
            {!user?.primaryCompany?.isShopDisabled && (
              <MobileNavItem
                onClick={() => {
                  setIsMobileMenuOpen(false)
                  window.dataLayer.push({
                    event: 'Shop clicked',
                  })
                }}
                to="/shop"
              >
                <Text style="label5" color="primary1">
                  Shop
                </Text>
              </MobileNavItem>
            )}

            <MobileNavItem
              onClick={() => {
                setIsMobileMenuOpen(false)
                openLink(process.env.REACT_APP_COMMUNITY_URL)
              }}
              to={location.pathname}
            >
              <Text style="label5" color="primary1">
                Forum
              </Text>
            </MobileNavItem>
            {isAuthenticated && !user?.primaryCompany?.isMemberAreaDisabled && isUserLoaded && (
              <MobileNavItem onClick={() => setIsMobileMenuOpen(false)} to="/members">
                <Text style="label5" color="primary1">
                  Members
                </Text>
              </MobileNavItem>
            )}
          </>
        )}
        {!user?.primaryCompany?.isJobboardDisabled && isUserLoaded && (
          <MobileNavItem onClick={() => setIsMobileMenuOpen(false)} to="/jobboard">
            <Text style="label5" color="primary1">
              Jobs
            </Text>
          </MobileNavItem>
        )}

        {isAuthenticated && !isOnLanding && (
          <ProfileMobile onClick={() => setIsMobileProfileDropdownVisible(!isProfileDropdownVisible)}>
            <Profile mobileMenuOpen={true}>
              <AvatarStyled user={user} size={AvatarSize.ExtraSmall} />
              <DropdownSelect
                textStyle="label8"
                headers={profileHeaders}
                setActiveHeader={(index) => clickProfileHeader(index)}
                isVisible={isMobileProfileDropdownVisible}
                top={32}
                right={-90}
                onOutsideClick={() => setIsMobileProfileDropdownVisible(false)}
              ></DropdownSelect>
            </Profile>
          </ProfileMobile>
        )}
        {!isAuthenticated && (
          <ToLoginButtonMobile
            caption={!isOnLanding ? 'Login' : 'Sign up free'}
            variant={ButtonVariant.Secondary}
            icon="Arrow"
            iconSize={15}
            iconColor={colors.neutralWhite}
            onClick={() => {
              setIsMobileMenuOpen(false)
              history.push(!isOnLanding ? '/login' : '/register')
            }}
          />
        )}
      </MobileMenu>
      {!isOnLanding && (
        <SearchPopupContainer open={searchTerm !== ''}>
          <SearchbarContainer>
            <SearchbarWrapper>{searchBar}</SearchbarWrapper>
          </SearchbarContainer>
          <SerachCategories>
            <StyledCloseIcon onClick={() => setSearchTerm('')} type="Close" color={colors.tertiary1} size={24} />
            {searchCategories.map((category, index) => (
              <Category key={index + category}>
                <Text style="heading1" forceStyle="mobile" marginBottom={9.5} color="tertiary2">
                  {category === 'Users' ? 'People' : category}
                </Text>
                <SeparatingLine />
                {category === 'Jobs' && (
                  <CategoryField>
                    {filteredJobs.length > 0 &&
                      filteredJobs.map((job, index) => (
                        <StyledSearchLink key={job.name + index} onClick={() => setSearchTerm('')} to={`/jobboard/${job.slug || formatSlug(job)}`}>
                          {job.name}
                        </StyledSearchLink>
                      ))}
                    {filteredJobs.length === 0 && (
                      <Text style="heading2" forceStyle="mobile" color="neutralWhite">
                        No results
                      </Text>
                    )}
                  </CategoryField>
                )}
                {category === 'Courses' && (
                  <CategoryField>
                    {filteredCourses.length > 0 &&
                      filteredCourses.map((course, index) => (
                        <StyledSearchLink key={course.name + index} onClick={() => setSearchTerm('')} to={`/shop/${course.name}`}>
                          {course.name}
                        </StyledSearchLink>
                      ))}
                    {filteredCourses.length === 0 && (
                      <Text style="heading2" forceStyle="mobile" color="neutralWhite">
                        No results
                      </Text>
                    )}
                  </CategoryField>
                )}
                {category === 'Users' && (
                  <CategoryField>
                    {filteredUsers.length > 0 &&
                      filteredUsers.map((user, index) => (
                        <StyledSearchLink key={user.email || '' + index} onClick={() => setSearchTerm('')} to={`/profile/${user.id}`}>
                          {user.full_name}
                        </StyledSearchLink>
                      ))}
                    {filteredUsers.length === 0 && (
                      <Text style="heading2" forceStyle="mobile" color="neutralWhite">
                        No results
                      </Text>
                    )}
                  </CategoryField>
                )}
              </Category>
            ))}
          </SerachCategories>
        </SearchPopupContainer>
      )}
    </NavBar>
  )
}

const NavBar = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 300;
`
const Menu = styled.div<{ navbarColor: string }>`
  z-index: 90;
  position: fixed;
  padding: 26px 15px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  background-color: ${({ navbarColor }) => navbarColor};

  ${mqFrom.XL`
    padding: 26px 40px;
  `}
`

const NavContentContainer = styled.div<{ isOnLanding?: boolean }>`
  max-width: ${({ isOnLanding }) => (isOnLanding ? 1170 : 1110)}px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`

const NavButtonsDesktop = styled.div`
  display: none;
  ${mqFrom.M`
    display: flex;
  `}
`

const NavButtonsMobile = styled.div`
  display: flex;
  align-items: center;
  ${mqFrom.M`
    display: none;
  `}
`

const DesktopNavLink = styled(Link)<{ isOnLanding?: boolean; selected?: boolean }>`
  ${({ isOnLanding }) => applyTextStyle(isOnLanding ? 'label2' : 'label5')}
  ${applyTextStyle('label5')}
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: ${({ isOnLanding }) => (isOnLanding ? colors.neutralWhite : colors.secondary3)};
  cursor: pointer;
  position: relative;

  ${({ selected }) => {
    if (selected) {
      return css`
        color: ${colors.neutralWhite};
        &:after {
          content: '';
          position: absolute;
          width: calc(100% - 30px);
          height: 2px;
          background-color: ${colors.tertiary1};
          bottom: 0px;
          left: 0;
          right: 0;
          margin: auto;
        }
      `
    }
  }}
`

const LogoImg = styled.img`
  cursor: pointer;
  max-width: 183.17px;
  height: 42px;
`

const Profile = styled.div<{ mobileMenuOpen: boolean }>`
  display: flex;
  padding-left: ${({ mobileMenuOpen }) => (mobileMenuOpen ? '0' : '15')}px;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: fit-content;
`

const AvatarStyled = styled(Avatar)`
  margin-right: 8px;
`

const SearchBarWrapperDesktop = styled.div`
  position: relative;
  z-index: 130;
  width: 350px;
  display: none;

  ${mqFrom.L`
    display: block;
  `}
`

const MobileSearchBarContainer = styled.div`
  position: fixed;
  display: flex;
  padding: 15px 0;
  top: 100px;
  left: 0;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  background-color: ${colors.primary1};
  justify-content: center;

  ${mqFrom.L`
    display: none;
  `}
`

const SearchBarWrapperMobile = styled.div`
  width: 300px;

  ${mqFrom.S`
    width: 350px;
  `}
`

const MobileMenu = styled.div<{ open: boolean; navbarColor: string }>`
  z-index: 100;
  position: fixed;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  padding: 26px 15px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ navbarColor }) => navbarColor};
  transition: opacity ${slowTransition};
  /* opacity: ${(props: { open: boolean }) => (props.open ? '1' : '0')}; */
`

const MobileMenuTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 39px;
`

const MobileNavItem = styled(Link)`
  padding: 20px 0 10px 0;
  border-bottom: 1px solid ${colors.primary1};
`

const ProfileMobile = styled.div`
  cursor: pointer;
  padding: 20px 0 10px 0;
  border-bottom: 1px solid ${colors.primary1};
`

const SearchPopupContainer = styled.div<{ open: boolean }>`
  z-index: 120;
  position: fixed;
  display: ${(props: { open: boolean }) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  padding: 26px 15px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${colors.primary5};
`

const SearchbarContainer = styled.div`
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: 70px;
  padding: 15px;
  box-sizing: border-box;
  background-color: ${colors.primary1};
  justify-content: center;

  ${mqFrom.L`
    padding: 26px 15px;  
    top: 0;
    height: 100px;
    left: 0;
  `}
`

const SearchbarWrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`

const SerachCategories = styled.div`
  max-width: 1140px;
  width: 100%;
  margin-top: 170px;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;

  ${mqFrom.L`
    margin-top: 100px;
  `}
`

const Category = styled.div`
  :not(:last-child) {
    margin-bottom: 35px;
  }
`

const SeparatingLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.backgroundPrimary};
  margin-bottom: 33px;
`

const StyledSearchLink = styled(Link)`
  ${applyTextStyle('heading2', 'mobile')}
  :not(:last-child) {
    margin-bottom: 25px;
  }
`

const CategoryField = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCloseIcon = styled(Icon)`
  position: absolute;
  z-index: 150;
  right: 22px;
  top: 38px;
  cursor: pointer;

  ${mqFrom.L`
    right: 38px;
  `}
`

const ToLoginButton = styled(Button)`
  display: none;
  margin-left: 60px;
  height: 48px;
  ${mqFrom.S`
    display: block;
  `}
`

const ToLoginButtonMobile = styled(Button)`
  width: 150px;
  margin-top: 10px;
`

export default Navigation
