import React from 'react'
import styled, { css } from 'styled-components'
import { Color } from '../common/models/colors'
import { TextStyleType } from '../common/models/typography'
import colors from '../styles/colors'
import Text from './Text'

interface IProps {
  className?: string
  caption: string
  onClick?: (event?: React.MouseEvent) => void
  color?: Color
  withUnderline?: boolean
  textStyle?: TextStyleType
}

const Link: React.FC<IProps> = ({ className, caption, onClick, color, withUnderline, textStyle }) => {
  return (
    <StyledLink className={className} onClick={onClick} withUnderline={withUnderline}>
      <Text
        color={color ? color : 'primary1'}
        style={textStyle ? textStyle : 'label2'}
        forceStyle="mobile"
        marginRight={withUnderline ? 0 : 4}
        marginBottom={withUnderline ? 6 : 0}
      >
        {caption}
      </Text>
    </StyledLink>
  )
}

const StyledLink = styled.div<{ withUnderline?: boolean }>`
  cursor: pointer;
  max-height: 28px;
  ${({ withUnderline }) => {
    if (withUnderline) {
      return css`
        padding-bottom: 4px;
        border-bottom: 2px solid ${colors.tertiary1};
      `
    }
  }}
`

export default Link
