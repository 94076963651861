import React, { useEffect } from 'react'
import styled from 'styled-components'
import { countries } from '../../helpers/countries'
import * as Yup from 'yup'
import { Form, Formik, useFormikContext } from 'formik'
import FormikInput from './formControls/formik/FormikInput'
import FormikDropdown from './formControls/formik/FormikDropdown'
import { mqFrom } from '../../styles/responsive'
import Button from '../Button'
import { Workplace } from '../../enums/workplace.enum'
import workplaceText from '../../helpers/workplace.helper'

export interface IJobDetailsForm {
  name: string
  job_description_short: string
  job_workload: number
  job_location: string
  workplace: Workplace
  job_application_apply_link: string
  job_country: string
}

interface IProps {
  onSubmit: (values: IJobDetailsForm) => void
  initialValues?: IJobDetailsForm
  triggerSubmit?: boolean
  onSubmitBtnClick?: () => void
}

const JobDetailsForm: React.FC<IProps> = ({ onSubmit, initialValues, triggerSubmit, onSubmitBtnClick }) => {
  const inputListWorkload = [
    { name: 'Full time (1FTE)', value: 1 },
    { name: 'Part time (0.9FTE)', value: 0.9 },
    { name: 'Part time (0.8FTE)', value: 0.8 },
    { name: 'Part time (0.7FTE)', value: 0.7 },
    { name: 'Part time (0.6FTE)', value: 0.6 },
    { name: 'Part time (0.5FTE)', value: 0.5 },
    { name: 'Part time (0.4FTE)', value: 0.4 },
    { name: 'Part time (0.3FTE)', value: 0.3 },
    { name: 'Part time (0.2FTE)', value: 0.2 },
    { name: 'Part time (0.1FTE)', value: 0.1 },
    { name: 'Flex (0FTE)', value: 0.0 },
  ]

  const jobDetailsInitialValues: IJobDetailsForm = {
    name: '',
    job_description_short: '',
    job_workload: 1,
    job_location: '',
    workplace: Workplace.onsite,
    job_application_apply_link: '',
    job_country: countries[0],
  }

  const jobDetailsValidationSchema = Yup.object({
    name: Yup.string().required('Required'),
    job_description_short: Yup.string(),
    // .max(140, 'There is a maximum of 140 characters').required('Required'),
    job_workload: Yup.number().required('Required'),
    job_location: Yup.string().required('Required'),
    workplace: Yup.string().required('Required'),
    job_application_apply_link: Yup.string()
      .required('Required')
      .matches(/^((http|https|ftp):\/\/)/, 'Enter a correct url (start with https://)'),
    job_country: Yup.string().required('Required'),
  })

  const OutsideSubmitTrigger = () => {
    const { submitForm } = useFormikContext()
    useEffect(() => {
      if (triggerSubmit) {
        submitForm()
      }
    }, [triggerSubmit])
    return null
  }

  return (
    <Formik initialValues={initialValues ? initialValues : jobDetailsInitialValues} validationSchema={jobDetailsValidationSchema} onSubmit={onSubmit}>
      {({ submitForm }) => (
        <Form>
          <FormikInput name="name" placeholder="Enter a job title" label="Job Title" marginBottom={16} />
          {/* <FormikTextArea
            name="job_description_short"
            placeholder="Enter a job summary"
            label="Job Short Description"
            marginBottom={16}
            maxCharsCounter={140}
          /> */}
          <TwoInputsContainer>
            <FormikDropdown name="job_workload" label="Job Workload" marginBottom={16}>
              {inputListWorkload.map((load, index) => (
                <option key={index} value={load.value}>
                  {load.name}
                </option>
              ))}
            </FormikDropdown>
            <FormikDropdown name="workplace" label="Workplace" marginBottom={16}>
              {(Object.keys(Workplace) as Array<keyof typeof Workplace>).map((workplace, index) => (
                <option key={index} value={workplace}>
                  {workplaceText(workplace).substring(1, workplaceText(workplace).length - 1)}
                </option>
              ))}
            </FormikDropdown>
          </TwoInputsContainer>
          <TwoInputsContainer>
            <FormikDropdown name="job_country" label="Job Country" marginBottom={16}>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </FormikDropdown>
            <FormikInput name="job_location" placeholder="Enter a city" label="Job Location" marginBottom={16} />
          </TwoInputsContainer>
          <FormikInput name="job_application_apply_link" marginBottom={18} placeholder="Enter a application Link" label="Application Link" />
          <ButtonsContainer>
            <Button
              caption="Next"
              type="submit"
              onClick={() => {
                submitForm()
                onSubmitBtnClick ? onSubmitBtnClick() : undefined
              }}
            />
          </ButtonsContainer>
          <OutsideSubmitTrigger />
        </Form>
      )}
    </Formik>
  )
}

const TwoInputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mqFrom.S`
    flex-direction: row;
    gap: 30px;
  `}
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 40px;
`

export default JobDetailsForm
