import React from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Text from './Text'
import openLink from '../helpers/openLink.helper'
dayjs.extend(relativeTime)

interface IProps {
  className?: string
  events: CdiEvent[]
}

const EventsList: React.FC<IProps> = ({ className, events }) => {
  return (
    <Wrapper className={className}>
      {events.length > 0 &&
        events.map((event, index) => (
          <Container key={index}>
            <Text marginBottom={5} style="heading1" forceStyle="mobile" onClick={() => openLink(event.link)} isCursorPointer>
              {event.title}
            </Text>
            <Text style="label3">{event.event_start_date}</Text>
          </Container>
        ))}
      {events.length === 0 && (
        <Text marginBottom={5} style="heading1" forceStyle="mobile">
          Currently there are no upcoming events.
        </Text>
      )}
    </Wrapper>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  :first-child {
    border-top: 1px solid ${colors.secondary6};
  }
  border-bottom: 1px solid ${colors.secondary6};
`

const Wrapper = styled.div`
  width: 100%;
`

export default EventsList
