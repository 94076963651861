import React from 'react'
import { useField } from 'formik'
import Checkbox, {IProps} from '../Checkbox'

const FormikCheckbox = (
  props: Omit<IProps, 'errorMessage' | 'onChange' | 'value' | 'meta'> & {
    name: string
  }
) => {
  const [field, meta] = useField(props)

  return <Checkbox {...props} {...field} meta={meta} />
}

export default FormikCheckbox
