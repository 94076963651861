import React from 'react'
import { useField } from 'formik'
import TextArea, { IProps } from '../TextArea'

const FormikTextArea = (
  props: Omit<IProps, 'errorMessage' | 'onChange' | 'value' | 'meta'> & {
    name: string
  }
) => {
  const [field, meta] = useField(props)

  return <TextArea {...props} {...field} meta={meta} />
}

export default FormikTextArea
