import React from 'react'
import styled, { css } from 'styled-components'
import { Color } from '../common/models/colors'
import { TextStyleType } from '../common/models/typography'
import colors from '../styles/colors'
import { applyTextStyle } from '../styles/typography'
import Icon from './icon/Icon'
import { IconType } from './icon/IconType'
import Loader from './Loader'

// Types

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export type ButtonVariantType = 'primary' | 'secondary' | 'tertiary'

export interface IProps {
  disabled?: boolean
  caption?: string | React.ReactNode
  variant?: ButtonVariantType
  color?: Color
  textColor?: Color
  className?: string
  onClick?: (event?: React.MouseEvent) => void
  showLoader?: boolean
  icon?: IconType
  iconColor?: string
  iconSize?: number
  textStyle?: TextStyleType
  iconOnLeft?: boolean
  type?: 'button' | 'submit'
  justifyInnerContent?: 'center' | 'space-between'
}

const Button = (props: IProps) => {
  const variant = props.variant ? props.variant : ButtonVariant.Primary

  const caption = props.caption ?? ''
  const color: Color = props.color
    ? props.color
    : variant === ButtonVariant.Primary
    ? 'tertiary1'
    : variant === ButtonVariant.Secondary
    ? 'primary3'
    : 'transparent'

  const textColor: Color = props.textColor ? props.textColor : 'neutralWhite'

  const iconSize = props.iconSize ? props.iconSize : 24
  const iconColor = props.iconColor || colors.primary2
  const textStyle = props.textStyle || 'label4'
  const type = props.type || 'button'
  const justifyInnerContent = props.justifyInnerContent || 'center'

  return (
    <StyledButton
      onClick={props.onClick}
      className={props.className}
      disabled={props.disabled}
      color={color}
      type={type}
      textColor={textColor}
      showLoader={props.showLoader || false}
      variant={variant}
      justifyInnerContent={justifyInnerContent}
    >
      {props.icon !== undefined && props.iconOnLeft && <StyledIcon isOnLeft size={iconSize} color={iconColor} type={props.icon} />}
      {!!caption && <ButtonLabel textStyle={textStyle}>{caption}</ButtonLabel>}
      {props.icon !== undefined && !props.iconOnLeft && <StyledIcon size={iconSize} color={iconColor} type={props.icon} />}
      {props.showLoader && <Loader />}
    </StyledButton>
  )
}

export const StyledButton = styled.button<{
  color: Color
  textColor: Color
  showLoader: boolean
  variant: ButtonVariantType
  justifyInnerContent: string
}>`
  position: relative;
  align-items: center;
  justify-content: ${({justifyInnerContent}) => justifyInnerContent};
  display: flex;
  flex-shrink: 0;
  padding: 10px 25px;
  outline: none;
  outline: none;
  border: ${({ variant }) => (variant === ButtonVariant.Tertiary ? `1px solid ${colors.secondary6}` : 'none')};
  cursor: pointer;
  &:disabled {
    cursor: default;
    pointer-events: none;
    background-color: ${colors.secondary2};
    color: ${colors.secondary1};
    label {
      cursor: default;
      visibility: ${({ showLoader }) => (showLoader ? 'hidden' : 'unset')};
    }
  }
  ${(props) =>
    css`
      background-color: ${colors[props.color]};
      color: ${colors[props.textColor]};
    `}
`

const ButtonLabel = styled.label<{ textStyle: TextStyleType }>`
  cursor: pointer;
  ${({ textStyle }) => applyTextStyle(textStyle)};
  flex-wrap: nowrap;
`

const StyledIcon = styled(Icon)<{ isOnLeft?: boolean }>`
  margin: ${({ isOnLeft }) => (isOnLeft ? '0 10px 0 0' : '0 0 0 10px')};
`

export default Button
