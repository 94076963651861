import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import strapi from '../helpers/strapi.helper'
import { useAppState } from '../context/AppContext'
import { SSO } from '../constants/api'

const TakeCourse: React.FC = () => {
  const history = useHistory()
  const appState = useAppState()
  const isAuthenticated = !!appState.userAuthToken

  const params: { slug: string } = useParams()
  console.log(params.slug)

  if (isAuthenticated) {
    if (params.slug) {
      useEffect(() => {
        // eslint-disable-next-line
        ;(async () => {
          openLink(await courseLink(params.slug))
        })()
      }, [])
    } else {
      history.push('/login')
    }
  }

  if (!isAuthenticated) {
    history.push('/login')
  }

  return <></>
}

export default TakeCourse

const courseLink = async (slug: string) => {
  const ssoLink = await getSSOToken()
  const link = `${process.env.REACT_APP_ACADEMY_URL}courses/take/${slug}`
  const endLink = `${ssoLink}&return_to=${link}`
  return endLink
}

const openLink = (link?: string) => {
  if (!link) return
  window.location.replace(link)
}

const getSSOToken = async () => {
  const link: any = await strapi.request('get', SSO)
  return link.ssoLink
}
