import React, { useContext, useEffect } from 'react'
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import '../helpers/shepard.css'
import { useAppDispatch, useAppState } from '../context/AppContext'

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
}

const steps: any = [
  {
    id: 'intro',
    attachTo: { element: '.shepherd-navigation', on: 'bottom' },
    buttons: [
      {
        text: 'Finish',
        type: 'cancel',
      },
      {
        text: 'Next',
        type: 'next',
      },
    ],
    scrollTo: { behavior: 'smooth', block: 'center' },
    cancelIcon: {
      enabled: true,
    },
    title: 'Welcome to Conversation Design Institute',
    text: [
      "Welcome to your personal homepage. Here you'll find your courses and certificates, and you will have access to your profile page, community forums, and job boards.",
    ],
    when: {
      show: () => {
        console.log('show step')
      },
      hide: () => {
        console.log('hide step')
      },
    },
  },
  {
    id: 'profile',
    attachTo: { element: '.shepherd-profile', on: 'top' },
    buttons: [
      {
        text: 'Finish',
        type: 'cancel',
      },
      {
        text: 'Back',
        type: 'back',
      },
      {
        text: 'Next',
        type: 'next',
      },
    ],
    scrollTo: { behavior: 'smooth', block: 'center' },
    cancelIcon: {
      enabled: true,
    },
    title: 'This is your personal overview',
    text: ['This is a summary of your profile. To edit or share your full profile, just click here'],
    when: {
      show: () => {
        console.log('show step')
      },
      hide: () => {
        console.log('hide step')
      },
    },
  },
  {
    id: 'certificates',
    attachTo: { element: '.shepherd-certificates', on: 'top' },
    buttons: [
      {
        text: 'Finish',
        type: 'cancel',
      },
      {
        text: 'Back',
        type: 'back',
      },
      {
        text: 'Next',
        type: 'next',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Your certificates',
    text: ['Your certification will appear here automatically.'],
    when: {
      show: () => {
        console.log('show step')
      },
      hide: () => {
        console.log('hide step')
      },
    },
  },
  {
    id: 'exam-request-button',
    attachTo: { element: '.shepherd-new-certificate', on: 'top' },
    buttons: [
      {
        text: 'Finish',
        type: 'cancel',
      },
      {
        text: 'Back',
        type: 'back',
      },
      {
        text: 'Next',
        type: 'next',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'New certificate',
    text: ['Here you request the exam for a new certificate.'],
    when: {
      show: () => {
        console.log('show step')
      },
      hide: () => {
        console.log('hide step')
      },
    },
  },
  {
    id: 'your-courses',
    attachTo: { element: '.shepherd-your-courses', on: 'top' },
    buttons: [
      {
        text: 'Finish',
        type: 'cancel',
      },
      {
        text: 'Back',
        type: 'back',
      },
      {
        text: 'Next',
        type: 'next',
      },
    ],
    scrollTo: { behavior: 'smooth', block: 'center' },
    cancelIcon: {
      enabled: true,
    },
    title: 'Your courses',
    text: ['These are the courses you are currently enrolled in. Just click here to start learning.'],
    when: {
      show: () => {
        console.log('show step')
      },
      hide: () => {
        console.log('hide step')
      },
    },
  },
  {
    id: 'recommended-courses',
    attachTo: { element: '.shepherd-recommended-courses', on: 'top' },
    buttons: [
      {
        text: 'Finish',
        type: 'cancel',
      },
      {
        text: 'Back',
        type: 'back',
      },
    ],
    scrollTo: { behavior: 'smooth', block: 'center' },
    cancelIcon: {
      enabled: true,
    },
    title: 'Other courses',
    text: ['Here you find the overview of other courses that might help you on your learning journey.'],
    when: {
      show: () => {
        console.log('show step')
      },
      hide: () => {
        console.log('hide step')
      },
    },
  },
  // {
  //   id: 'community-forums',
  //   attachTo: { element: '.shepherd-community-forums', on: 'top' },
  //   buttons: [
  //     {
  //       text: 'Finish',
  //       type: 'cancel',
  //     },
  //     {
  //       text: 'Back',
  //       type: 'back',
  //     },
  //     {
  //       text: 'Next',
  //       type: 'next',
  //     },
  //   ],
  //   scrollTo: { behavior: 'smooth', block: 'center' },
  //   cancelIcon: {
  //     enabled: true,
  //   },
  //   title: 'Community forums',
  //   text: [
  //     'The community forums are the best way to interact with the community. You can talk about a variety of topics and send in your questions for the regular office hours.',
  //   ],
  //   when: {
  //     show: () => {
  //       console.log('show step')
  //     },
  //     hide: () => {
  //       console.log('hide step')
  //     },
  //   },
  // },
  // {
  //   id: 'jobboard',
  //   attachTo: { element: '.shepherd-jobboard', on: 'top' },
  //   buttons: [
  //     {
  //       text: 'Finish',
  //       type: 'cancel',
  //     },
  //     {
  //       text: 'Back',
  //       type: 'back',
  //     },
  //   ],
  //   scrollTo: { behavior: 'smooth', block: 'center' },
  //   cancelIcon: {
  //     enabled: true,
  //   },
  //   title: 'Job board',
  //   text: ["Careers are ever evolving. To find your next job or hire, please use the job board. It's the heart of the Conversational AI industry"],
  //   when: {
  //     show: () => {
  //       console.log('show step')
  //     },
  //     hide: () => {
  //       console.log('hide step')
  //     },
  //   },
  // },
]

function StartTourButton() {
  const { shouldOpenTour } = useAppState()
  const appDispatch = useAppDispatch()
  const tour = useContext(ShepherdTourContext)

  useEffect(() => {
    if (shouldOpenTour) {
      appDispatch({
        type: 'updateShouldOpenTour',
        payload: false,
      })
      tour?.start()
    }
  }, [shouldOpenTour])

  return <></>
}

const Tour: React.FC = () => {
  return (
    <ShepherdTour steps={steps} tourOptions={tourOptions}>
      <StartTourButton />
    </ShepherdTour>
  )
}

export default Tour
