import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../styles/colors'
import Icon from './icon/Icon'
import { IconType } from './icon/IconType'
import Text from './Text'

interface IProps {
  className?: string
  caption: string
  onClick?: (event?: React.MouseEvent) => void
  iconSize?: number
  iconType?: IconType
}

const LinkWithArrow: React.FC<IProps> = ({
  className,
  caption,
  onClick,
  iconType,
  iconSize,
}) => {
  return (
    <StyledLink className={className} onClick={onClick}>
      <Text
        color="primary1"
        style="heading1"
        forceStyle="mobile"
        marginRight={4}
      >
        {caption}
      </Text>
      <StyledIcon
        size={iconSize ? iconSize : 24}
        color={colors.tertiary1}
        type={iconType ? iconType : 'ArrowDown'}
        hasIconType={iconType ? true : false}
      />
    </StyledLink>
  )
}

const StyledLink = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
`
const StyledIcon = styled(Icon)<{ hasIconType: boolean }>`
  ${({ hasIconType }) => {
    if (!hasIconType) {
      return css`
        transform: rotate(270deg);
      `
    }
  }}
`

export default LinkWithArrow
