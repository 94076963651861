import Cookies from 'universal-cookie/es6'

export const getCookie = (name: string): string | undefined => {
  const cookies = new Cookies()
  return cookies.get(name)
}

export const deleteCookie = (name: string) => {
  const cookies = new Cookies()
  cookies.remove(name)
}

export const createCookie = (key: string, value: string) => {
  const cookies = new Cookies()
  cookies.set(key, value, { path: '/', maxAge: 60 * 60 * 24 * 30 })
}
