import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import colors from '../../styles/colors'
import { mqFrom } from '../../styles/responsive'
import Icon from '../icon/Icon'
import Section, { SectionTop } from '../Section'
import Checkbox from './formControls/Checkbox'

export interface IFilter {
  header: string
  fields: string[]
  selectOnlyOne: boolean
}
interface IProps {
  filters: IFilter[]
  passValues: (values: boolean[][]) => void
}

const SearchBar: React.FC<IProps> = ({ filters, passValues }) => {
  const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(window.matchMedia('(min-width: 1024px)').matches)
  const [isFilterOpen, setIsFilterOpen] = useState<boolean[]>(filters.map(() => false))
  const defaultValues = filters.map((filter) => filter.fields.map(() => false))
  const [values, setValues] = useState<boolean[][]>(defaultValues)

  useEffect(() => {
    values.forEach((val) => {
      if (val.length === 0) {
        setValues(defaultValues)
        passValues(defaultValues)
      }
    })
  }, [filters])

  const handleFormValues = (isChecked: boolean, filterIndex: number, fieldIndex: number, selectOnlyOne: boolean) => {
    if (selectOnlyOne) {
      values[filterIndex] = [...defaultValues[filterIndex]]
    }
    values[filterIndex][fieldIndex] = isChecked
    setValues([...values])
    passValues([...values])
  }

  return (
    <FiltersContainer>
      <FiltersSection
        marginBottomAfterLine={areFiltersOpen ? 0 : 17}
        withTopBorder={areFiltersOpen ? true : false}
        header="Filters"
        forceStyleHeader="mobile"
        customAction={<StyledIcon onClick={() => setAreFiltersOpen(!areFiltersOpen)} type="FilterList" size={24} color={colors.primary2} />}
      ></FiltersSection>
      {areFiltersOpen && (
        <>
          {filters.map((filter, filterIndex) => (
            <FilterItemSection
              key={filterIndex}
              marginBottomAfterLine={isFilterOpen[filterIndex] ? 24 : 17}
              header={filter.header}
              smallHeader
              withTopBorder
              customAction={
                <StyledFilterIcon
                  isSectionOpened={isFilterOpen[filterIndex]}
                  onClick={() => setIsFilterOpen(isFilterOpen.map((filter, index) => (filterIndex === index ? !filter : filter)))}
                  type="ArrowDown"
                  size={24}
                  color={colors.primary2}
                />
              }
            >
              {isFilterOpen[filterIndex] && (
                <FilterContainer>
                  {filter.fields.map((field, fieldIndex) => (
                    <Checkbox
                      key={fieldIndex}
                      checked={values[filterIndex][fieldIndex]}
                      label={field}
                      onChange={(e) => {
                        handleFormValues(e.target.checked, filterIndex, fieldIndex, filter.selectOnlyOne)
                      }}
                    />
                  ))}
                </FilterContainer>
              )}
            </FilterItemSection>
          ))}
        </>
      )}
    </FiltersContainer>
  )
}

const FiltersContainer = styled.div`
  width: 100%;
  min-width: 254.5px;
  ${mqFrom.L`
    width: 254.5px;
    margin-right: 30px;
  `}
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

const StyledFilterIcon = styled(Icon)<{ isSectionOpened: boolean }>`
  cursor: pointer;
  transition: transform 0.3s ease;

  ${({ isSectionOpened }) => {
    if (isSectionOpened) {
      return css`
        transform: rotate(180deg);
      `
    }
  }}
`

const FilterContainer = styled.div`
  padding-bottom: 21px;
  margin-bottom: 17px;
  border-bottom: 1px solid ${colors.secondary6};
`

const FilterItemSection = styled(Section)`
  ${SectionTop} {
    padding-bottom: 16px;
  }
`

const FiltersSection = styled(Section)`
  ${SectionTop} {
    padding-bottom: 43px;
  }
`

export default SearchBar
