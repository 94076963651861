import React from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import { applyTextStyle } from '../styles/typography'
import Input, { StyledInput } from './Forms/formControls/Input'
import Icon from './icon/Icon'

interface IProps {
  className?: string
  skills?: string[]
  isInEditMode?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void
  onAdd?: () => void
  onDelete?: (index: number) => void
}

const SkillsList: React.FC<IProps> = ({ className, skills, isInEditMode, onChange, onAdd, onDelete }) => {
  return (
    <div className={className}>
      {skills?.map((skill, index) => (
        <StyledSkill key={index}>
          <Skill isInEditMode={isInEditMode}>
            {!isInEditMode && <Label>{skill}</Label>}
            {isInEditMode && (
              <>
                <div key={skill}>
                  <InputSkills defaultValue={skill} onChange={(e) => (onChange ? onChange(e, index) : undefined)} placeholder="Enter skill"></InputSkills>
                </div>

                <StyledDeleteIcon color={colors.tertiary4} size={24} type="Close" onClick={() => (onDelete ? onDelete(index) : undefined)} />
              </>
            )}
          </Skill>
        </StyledSkill>
      ))}
      {isInEditMode && <StyledAddIcon onClick={onAdd} color={colors.tertiary1} size={24} type="Add" />}
    </div>
  )
}

const StyledSkill = styled.div`
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 12px;
`

const Skill = styled.div<{ isInEditMode?: boolean }>`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px 14px;
  max-height: 40px;
  margin-right: ${({ isInEditMode }) => (isInEditMode ? '-30px' : '0px')};
  background-color: ${({ isInEditMode }) => (isInEditMode ? '#fff' : colors.tertiary3)};
`

const Label = styled.label`
  ${applyTextStyle('label1')};
  color: ${colors.neutralWhite};
`

const InputSkills = styled(Input)`
  ${StyledInput} {
    background-color: white;
    border: 1px solid ${colors.primary1};
    max-height: 40px;
    margin-top: 8px;
    margin-left: -15px;
    max-width: 160px;
    padding-right: 30px;
    color: ${colors.primary1};
    ${applyTextStyle('label1')};
  }
`

const StyledAddIcon = styled(Icon)`
  margin-bottom: -5px;
  padding-top: 5px;
  cursor: pointer;
`

const StyledDeleteIcon = styled(Icon)`
  position: absolute;
  right: 35px;
  top: 0x;
  cursor: pointer;
`

export default SkillsList
