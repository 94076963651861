import React, { useEffect, useState } from 'react'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import Text from '../components/Text'
import styled from 'styled-components'
import Section from '../components/Section'
import Button from '../components/Button'
import * as Yup from 'yup'
import { useHistory } from 'react-router'
import { Form, Formik } from 'formik'
import FormikInput from '../components/Forms/formControls/formik/FormikInput'
import strapi from '../helpers/strapi.helper'
import { useAppDispatch, useAppState } from '../context/AppContext'

interface IForgotPasswordForm {
  email: string
}

const ForgotPasswordPage: React.FC = () => {
  const appDispatch = useAppDispatch()
  const history = useHistory()
  const appState = useAppState()
  const isAuthenticated = !!appState.userAuthToken
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard')
    }
  }, [isAuthenticated])

  const [forgotPasswordProgress, setForgotPasswordProgress] = useState<boolean>(false)

  const forgotPasswordSubmit = async (values: IForgotPasswordForm) => {
    setForgotPasswordProgress(true)
    try {
      await strapi.forgotPassword(values)
    } finally {
      appDispatch({
        type: 'updateModalWindow',
        payload: { content: `If there is an account with email address ${values.email}, a reset link has just been sent`, dismissText: 'ok' },
      })
      setForgotPasswordProgress(false)
    }
  }

  const forgotPasswordInitialValues: IForgotPasswordForm = {
    email: '',
  }
  const forgotPasswordValidationSchema = Yup.object({
    email: Yup.string().email('Enter a valid email').required('Required'),
  })

  return (
    <Formik initialValues={forgotPasswordInitialValues} validationSchema={forgotPasswordValidationSchema} onSubmit={forgotPasswordSubmit}>
      {({ submitForm }) => (
        <AppPage>
          <AppTop>
            <Text style="headline" color="neutralWhite" marginBottom={4}>
              Conversational Experience Courses
            </Text>
            <Text style="heading2" color="neutralWhite">
              Courses to get you started
            </Text>
          </AppTop>

          <StyledAppContainer>
            <Section header="Forgot password" forceStyleHeader="mobile" marginBottomAfterLine={57.5}>
              <Form>
                <FormikInput name="email" placeholder="Enter your email" label="Email" marginBottom={40} />
                <Actions>
                  <Text onClick={() => history.push('/login')} style="label7" color="primary1" isCursorPointer={true}>
                    Login
                  </Text>
                  <Button type='submit' disabled={forgotPasswordProgress} showLoader={forgotPasswordProgress} onClick={submitForm} caption="Send" />
                </Actions>
              </Form>
              <ToRegisterLink>
                <Text style="label5" color="secondary1" marginRight={5}>
                  Not a member yet?
                </Text>
                <Text style="label7" color="secondary1" isCursorPointer={true} onClick={() => history.push('/register')}>
                  Register!
                </Text>
              </ToRegisterLink>
            </Section>
          </StyledAppContainer>
        </AppPage>
      )}
    </Formik>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-top: 38px;
  max-width: 395px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`

const ToRegisterLink = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ForgotPasswordPage
