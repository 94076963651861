import React from 'react'
import { useField } from 'formik'
import Input, { IProps } from '../Input'

const FormikInput = (
  props: Omit<IProps, 'errorMessage' | 'onChange' | 'value' | 'meta'> & {
    name: string
  }
) => {
  const [field, meta] = useField(props)

  return <Input {...props} {...field} meta={meta} />
}

export default FormikInput
