import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useAppDispatch } from '../context/AppContext'
import openLink from '../helpers/openLink.helper'
import strapi from '../helpers/strapi.helper'
import colors from '../styles/colors'
import { applyTextStyle } from '../styles/typography'
import Google from '../images/google.svg'
import Facebook from '../images/facebook.svg'
import Cookies from 'js-cookie'
import socialUserService from '../context/services/socialUser.service'
import { submitToHubSpotFormEmailonly } from '../helpers/hubspot'

interface IProps {
  inverted?: boolean
}

const SocialButtons: React.FC<IProps> = ({ inverted }) => {
  const appDispatch = useAppDispatch()
  const history = useHistory()
  const loginWithAccessToken = async (accessToken: string) => {
    const response: any = await fetch(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessToken}`)
    const result = await response.json()
    let socialResponse
    if (result.email) {
      socialResponse = await strapi.authenticateProvider('google', accessToken).catch((e: any) => {
        console.log(e)
        appDispatch({
          type: 'updateModalWindow',
          payload: {
            content: e.message.id && e.message.id === 'Auth.form.error.email.taken' ? 'Email is already taken' : 'An error occured',
            dismissText: 'ok',
          },
        })
      })
    } else {
      socialResponse = await strapi.authenticateProvider('facebook', accessToken).catch((e: any) => {
        appDispatch({
          type: 'updateModalWindow',
          payload: {
            content: e.message.id && e.message.id === 'Auth.form.error.email.taken' ? 'Email is already taken' : 'An error occured',
            dismissText: 'ok',
          },
        })
      })
    }
    if (socialResponse && socialResponse.jwt) {
      const user = socialResponse.user as User
      if (!user.has_logged_once && user.id) {
        if (user.email) {
          const hubspotCookie = Cookies.get('hubspotutk')
          console.log(hubspotCookie)
          submitToHubSpotFormEmailonly(user.email, hubspotCookie)
        }

        await socialUserService().catch((socialError) => {
          console.log({ socialError })
        })
        appDispatch({
          type: 'updateShouldOpenTour',
          payload: true,
        })
      }
      appDispatch({
        type: 'updateUserToken',
        payload: socialResponse.jwt,
      })
      history.push('/dashboard')
      window.dataLayer.push({
        event: 'User signed in',
      })
    }
  }

  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const accessToken = query.get('access_token')

  useEffect(() => {
    if (accessToken) {
      loginWithAccessToken(accessToken)
    }
  }, [])

  return (
    <>
      <SocialButton inverted={inverted} isFacebook={false} onClick={() => openLink(process.env.REACT_APP_GOOGLE_URL, '_self')}>
        <SocialIcon inverted={inverted} src={Google} />
        Continue with Google
      </SocialButton>
      {/* <SocialButton inverted={inverted} isFacebook={true} onClick={() => openLink(process.env.REACT_APP_FACEBOOK_URL, '_self')}>
        <SocialIcon inverted={inverted} src={Facebook} />
        Continue with Facebook
      </SocialButton> */}
    </>
  )
}

const SocialButton = styled.button<{ isFacebook: boolean; inverted?: boolean }>`
  ${({ inverted }) =>
    inverted
      ? css`
          ${applyTextStyle('label8')};
          font-weight: 500 !important;
          text-align: left;
        `
      : css`
          ${applyTextStyle('label1')};
        `};

  width: 100%;
  padding: ${({ inverted }) => (inverted ? '18px 18px 18px 58px' : '10px 25px')};
  height: ${({ inverted }) => (inverted ? 60 : 48)}px;
  border: none;
  cursor: pointer;
  position: relative;
  background-color: ${colors.transparent};
  border: 1px solid ${({ inverted }) => (inverted ? colors.secondary6 : colors.tertiary1)};
  margin-bottom: 8px;
`

const SocialIcon = styled.img<{ inverted?: boolean }>`
  position: absolute;
  top: 12px;
  left: 30px;
  width: 24px;
  height: 24px;

  ${({ inverted }) =>
    inverted &&
    css`
      top: 18px;
      left: 18px;
    `}
`

export default SocialButtons
