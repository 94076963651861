import React from 'react'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import Text from '../components/Text'
import styled from 'styled-components'
import { mqFrom } from '../styles/responsive'
import JobView from '../components/JobView'
import { useLocation, useParams } from 'react-router-dom'
import { useAppState } from '../context/AppContext'
import { Helmet } from 'react-helmet'
import formatSlug from '../helpers/formatSlug.helper'

const JobPage: React.FC = () => {
  const appState = useAppState()
  const location = useLocation()
  const params: { slug: string } = useParams()
  const path = location.pathname
  const isPageHidden = path === '/jobboard/post-job'
  const job = appState?.jobs?.find((job) => job?.slug === params.slug || formatSlug(job) === params.slug)

  let jobMetaDescription = ''
  if (job && job.job_description) {
    const jobDescription = JSON.parse(job.job_description)
    jobDescription.blocks.forEach((level: any) => {
      jobMetaDescription += level.data.text + ' '
    })
  }

  const jobPostStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'JobPosting',
    title: job?.name,
    description: jobMetaDescription,
    image: job?.company.company_logo_url,
    hiringOrganization: {
      '@type': 'Organization',
      name: job?.company.name,
      sameAs: job?.company.company_url,
      logo: job?.company.company_logo_url,
    },
    datePosted: new Date(job?.job_application_post_date || 0).toISOString(),
  }

  return (
    <>
      {!isPageHidden && (
        <AppPage>
          <Helmet>
            <meta property="og:title" content={`Conversational Design Institute Job ${job?.name}`} />
            <meta property="og:description" content={jobMetaDescription.slice(0, 100)} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:locale" content="english" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={job?.company.company_logo_url} />
          </Helmet>
          <script type="application/ld+json">{JSON.stringify(jobPostStructuredData)}</script>
          <AppTop inverted={true}>
            <Text style="headline" color="primary1" marginBottom={4}>
              Conversational Jobs
            </Text>
            <Text style="heading2" color="primary1">
              Find a job that fits
            </Text>
          </AppTop>
          <StyledAppContainer>
            <JobView job={job} isDialog={false}></JobView>
          </StyledAppContainer>
        </AppPage>
      )}
    </>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-top: 40px;
  max-width: 915px;
  ${mqFrom.L`
    margin-top: 38px;
  `}
`

export default JobPage
