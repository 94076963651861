import React from 'react'
import { useField } from 'formik'
import Dropdown, { IProps } from '../Dropdown'

const FormikDropdown = (
  props: Omit<IProps, 'errorMessage' | 'onChange' | 'value' | 'meta'> & {
    name: string
  }
) => {
  const [field, meta] = useField(props)

  return (
    <Dropdown {...props} {...field} meta={meta}>
      {props.children}
    </Dropdown>
  )
}

export default FormikDropdown
