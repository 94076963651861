import React from 'react'
import styled from 'styled-components'
import { useAppDispatch } from '../context/AppContext'
import colors from '../styles/colors'
import Button from './Button'

export interface IProps {
  content: ModalWindow
}

const ModalWindow: React.FC<IProps> = ({ content }: IProps) => {
  const appDispatch = useAppDispatch()

  return (
    <DialogContainer>
      <Content>
        <>{content.content}</>
        {!content.hideActions && (
          <Actions hasOnSubmit={content.onSubmit ? true : false}>
            <Button
              caption={content.dismissText ? content.dismissText : 'Cancel'}
              onClick={() =>
                content.onDismiss
                  ? content.onDismiss
                  : appDispatch({
                      type: 'updateModalWindow',
                      payload: undefined,
                    })
              }
            />
            {content.onSubmit && <Button onClick={content.onSubmit} caption={content.submitText ? content.submitText : 'Save'} />}
          </Actions>
        )}
      </Content>
    </DialogContainer>
  )
}

const DialogContainer = styled.div`
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(1, 2, 28, 0.5);
  backdrop-filter: blur(15px);
  z-index: 1100;
  padding: 0 16px;
`

const Content = styled.div`
  position: relative;
  margin: 0 auto;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-height: 80vh;
  min-width: 280px;
  max-width: 550px;
  border-radius: 4px;
  background: ${colors.backgroundPrimary};
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 28px 23px;
  overflow-y: auto;
  text-align: left;
  font-size: 16px;
`

const Actions = styled.div<{ hasOnSubmit: boolean }>`
  display: flex;
  justify-content: ${({ hasOnSubmit }) => (hasOnSubmit ? 'space-between' : 'flex-end')};
  margin-top: 15px;
`

export default ModalWindow
