export const enterpriseProducts: Course[] = [
  {
    img_url: 'https://res.cloudinary.com/lente-digital/image/upload/v1670333801/course-cover-B2B-Shop-workshops_f3ubpm.png',
    name: 'Workshops',
    percentage_completed: 0,
    moduleHeader: false,
    owned: true,
    product: true,
    buy_link: 'https://www.conversationdesigninstitute.com/communications/enterprise-product-request-page',
  },
  {
    img_url: 'https://res.cloudinary.com/lente-digital/image/upload/v1670333801/course-cover-B2B-Shop-benchmarking_mnomfm.png',
    name: 'Benchmarking',
    percentage_completed: 0,
    moduleHeader: false,
    owned: true,
    product: true,
    buy_link: 'https://www.conversationdesigninstitute.com/communications/enterprise-product-request-page',
  },
  {
    img_url: 'https://res.cloudinary.com/lente-digital/image/upload/v1670333801/course-cover-B2B-Shop-assessment_ba0zxq.png',
    name: 'Assessments',
    percentage_completed: 0,
    moduleHeader: false,
    owned: true,
    product: true,
    buy_link: 'https://www.conversationdesigninstitute.com/communications/enterprise-product-request-page',
  },
]
