import React, { useEffect, useState } from 'react'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import Text from '../components/Text'
import styled from 'styled-components'
import Section from '../components/Section'
import Button from '../components/Button'
import { useHistory, useLocation } from 'react-router'
import { useAppDispatch, useAppState } from '../context/AppContext'
import strapi from '../helpers/strapi.helper'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormikInput from '../components/Forms/formControls/formik/FormikInput'
import editUserService from '../context/services/editUser.service'
import { Helmet } from 'react-helmet'
import SocialButtons from '../components/SocialButtons'
import colors from '../styles/colors'
import queryString from 'query-string'

interface ILoginForm {
  identifier: string
  password: string
}

const LoginPage: React.FC = () => {
  const appDispatch = useAppDispatch()
  const history = useHistory()
  const appState = useAppState()
  const location = useLocation()
  const isAuthenticated = !!appState.userAuthToken
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard')
    }
  }, [isAuthenticated])

  const [loginInProgress, setLoginInProgress] = useState<boolean>(false)
  const loginInitialValues: ILoginForm = { identifier: '', password: '' }
  const loginValidationSchema = Yup.object({
    identifier: Yup.string().email('Enter a valid email').required('Required'),
    password: Yup.string().required('Required'),
  })

  const login = async (values: ILoginForm) => {
    appDispatch({
      type: 'updateIsUserLoaded',
      payload: false,
    })
    setLoginInProgress(true)
    const loginResponse: any = await strapi.login({ ...values, identifier: values.identifier.toLowerCase() }).catch(async (e: any) => {
      if (e.message.id === 'Auth.form.error.confirmed') {
        await strapi.sendEmailConfirmation({ email: values.identifier.toLowerCase() })
        appDispatch({
          type: 'updateModalWindow',
          payload: {
            content: `Your account email is not confirmed.
          Account confirmation email has been sent to ${values.identifier.toLowerCase()}`,
            dismissText: 'ok',
          },
        })
      } else {
        appDispatch({
          type: 'updateModalWindow',
          payload: { content: e.message.message, dismissText: 'ok' },
        })
      }
    })

    if (loginResponse && loginResponse.jwt) {
      //console.log('here', loginResponse.user)

      if (!loginResponse.user.has_logged_once) {
        appDispatch({
          type: 'updateShouldOpenTour',
          payload: true,
        })
        await editUserService(loginResponse.user.id, { has_logged_once: true })
      }
      appDispatch({
        type: 'updateUserToken',
        payload: loginResponse.jwt,
      })
      history.push('/dashboard')
      window.dataLayer.push({
        event: 'User signed in',
      })
    }
    setLoginInProgress(false)
  }

  if (location.search && location.search !== '') {
    const { password, username } = queryString.parse(location.search)
    if (password && username) {
      login({ identifier: decodeURIComponent(String(username)), password: String(password) })
      location.search = ''
    }
  }

  return (
    <Formik initialValues={loginInitialValues} validationSchema={loginValidationSchema} onSubmit={login}>
      {({ submitForm }) => (
        <AppPage>
          <Helmet>
            <meta property="og:title" content="Conversational Design Institute Login" />
            <meta property="og:description" content="Conversation Design Institute. Login to get started." />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:locale" content="english" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={process.env.REACT_APP_CDI_LOGO_URL} />
          </Helmet>
          <AppTop>
            <Text style="headline" color="neutralWhite" marginBottom={4}>
              Conversation Design Institute
            </Text>
            <Text style="heading2" color="neutralWhite">
              Login to get started
            </Text>
          </AppTop>
          <StyledAppContainer>
            <Section header="Login" forceStyleHeader="mobile" marginBottomAfterLine={57.5}>
              <Form>
                <FormikInput name="identifier" placeholder="Enter your email" label="Email" marginBottom={16} />
                <FormikInput name="password" placeholder="Enter your password" label="Password" marginBottom={40} type="password" />
                <Actions>
                  <Text onClick={() => history.push('/forgot-password')} style="label7" color="primary1" isCursorPointer={true}>
                    Forgot password?
                  </Text>
                  <Button type="submit" disabled={loginInProgress} showLoader={loginInProgress} onClick={submitForm} caption="Login" />
                </Actions>
              </Form>
              <SocialButtons />
              <ToRegisterLink>
                <Text style="label5" color="secondary1" marginRight={5}>
                  Not a member yet?
                </Text>
                <Text style="label7" color="secondary1" isCursorPointer={true} onClick={() => history.push('/register')}>
                  Register!
                </Text>
              </ToRegisterLink>
            </Section>
            <AlertTextWrapper>
              <Text style="label12" color="primary1">
                If you previously had a CDI account before 2022, your credentials have been updated. If you experience problems signing in, please check your
                email for your new credentials or try to reset your password.
              </Text>
            </AlertTextWrapper>
          </StyledAppContainer>
        </AppPage>
      )}
    </Formik>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-top: 38px;
  max-width: 395px;
`

const AlertTextWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  margin-top: 30px;
  /* box-shadow: 0px 3px 12px ${colors.shadow}; */
  background-color: ${colors.backgroundPrimary};
  border: 1px solid ${colors.secondary2};
  margin-bottom: 20px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`

const ToRegisterLink = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default LoginPage
