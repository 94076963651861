import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { mqFrom } from '../styles/responsive'

interface IProps {
  className?: string
  children: React.ReactNode
}
const AppPage: React.FC<IProps> = ({ className, children }) => {
  const location = useLocation()
  return (
    <Wrapper isOnLandingPage={location.pathname === '/'} className={className}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isOnLandingPage: boolean }>`
  position: relative;
  display: block;
  overflow: auto;
  padding-bottom: 160px;
  padding-top: ${({ isOnLandingPage }) => (isOnLandingPage ? 100 : 170)}px;

  ${mqFrom.L`
    padding-top: 100px;
  `}
`

export default AppPage
