import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconLock = (props: IProps) => (
  <svg
    viewBox="0 0 48 48"
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h48v48h-48z" fill="none" />
    <path
      fill={props.color}
      d="M36 16h-2v-4c0-5.52-4.48-10-10-10s-10 4.48-10 10v4h-2c-2.21 0-4 1.79-4 4v20c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4v-20c0-2.21-1.79-4-4-4zm-12 18c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm6.2-18h-12.4v-4c0-3.42 2.78-6.2 6.2-6.2 3.42 0 6.2 2.78 6.2 6.2v4z"
    />
  </svg>
)

export default IconLock
