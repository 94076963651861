import React from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import CookieConsent from 'react-cookie-consent'
import AppContainer from './AppContainer'
import Text from './Text'
import { mqFrom } from '../styles/responsive'
import { applyTextStyle } from '../styles/typography'

const CookieBanner: React.FC = () => {
  const buttonStyle = {
    padding: '10px 25px',
    backgroundColor: colors.tertiary1,
    color: colors.neutralWhite,
    margin: '0',
    fontFamily: 'GT America Trial',
    marginBottom: '30px',
    cursor: 'pointer',
  }

  const declineButtonStyle = {
    position: 'relative',
    color: colors.neutralWhite,
    padding: '0',
    margin: '0',
    fontFamily: 'GT America Trial',
    marginBottom: '30px',
    cursor: 'pointer',
    background: colors.transparent,
  }

  const contentStyle = { margin: 0, flex: 1 }

  const style = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    background: colors.primary1,
    margin: '0',
  }

  return (
    <Wrapper>
      <CookieConsent
        expires={365}
        style={style}
        contentStyle={contentStyle}
        buttonText="Allow all cookies"
        buttonStyle={buttonStyle}
        declineButtonText="Use necessary cookies only"
        declineButtonStyle={declineButtonStyle}
        buttonWrapperClasses="buttons-wrapper"
        enableDeclineButton
        onAccept={() => {
          const hsButton = document.getElementById('hs-eu-confirmation-button')
          hsButton?.click()
        }}
        onDecline={() => {
          const hsButton = document.getElementById('hs-eu-decline-button')
          hsButton?.click()
        }}
      >
        <StyledAppContainer>
          <Text style="heading1" color="neutralWhite" marginBottom={20}>
            This website uses cookies
          </Text>
          <Text style="label1" color="neutralWhite" marginBottom={20}>
            We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use
            of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that
            they’ve collected from your use of their services.
          </Text>
        </StyledAppContainer>
      </CookieConsent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .buttons-wrapper {
    ${applyTextStyle('label1')};
    align-items: center;
    padding: 0 15px;
    margin: 0 auto;
    max-width: 1140px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column-reverse;
    gap: 0px;
    ${mqFrom.S`
      gap: 50px;
      flex-direction: row;
    `}

    #rcc-decline-button {
      &:after {
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        background: ${colors.tertiary1};
      }
    }
  }
`

const StyledAppContainer = styled(AppContainer)`
  margin-top: 30px;
`

export default CookieBanner
