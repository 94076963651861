import React, { useEffect, useState } from 'react'
import AppPage from '../components/AppPage'
import AppContainer from '../components/AppContainer'
import AppTop from '../components/AppTop'
import Text from '../components/Text'
import styled from 'styled-components'
import { mqFrom } from '../styles/responsive'
import MultiSections from '../components/MultiSections'
import Button, { ButtonVariant } from '../components/Button'
import colors from '../styles/colors'
import SkillsContainer from '../components/SkillsContainer'
import { countries } from '../helpers/countries'
import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../helpers/tools.js'
import 'react-datepicker/dist/react-datepicker.css'
import { applyTextStyle } from '../styles/typography'
import { interfaces } from '../common/models/interfaces'
import { technologies } from '../common/models/technologies'
import { certificates } from '../common/models/certificates'
import JobView from '../components/JobView'
import JobDetailsForm, { IJobDetailsForm } from '../components/Forms/JobDetailsForm'
import JobCompanyForm, { IJobCompanyForm } from '../components/Forms/JobCompanyForm'
import createJob from '../context/services/createJob.service'
import createCompany from '../context/services/company/createCompany.service'
import Checkbox from '../components/Forms/formControls/Checkbox'
import { useAppDispatch, useAppState } from '../context/AppContext'
import { useHistory } from 'react-router-dom'
import setupStripeService from '../context/services/stripe/setupStripe.service'
import formatSlug from '../helpers/formatSlug.helper'

export type PaymentType = 'One-time' | 'Monthly' | 'Unlimited'

const PostJobPage: React.FC = () => {
  const appState = useAppState()
  const appDispatch = useAppDispatch()
  const user = appState.user
  const isAuthenticated = !!appState.userAuthToken
  const userHasUnlimitedSubscription = user?.unlimited_subscription
  const history = useHistory()
  const sections = ['Job details', 'Company', 'Requirements', 'Description', 'Preview', 'Payment']

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login')
    }
  }, [isAuthenticated])

  const placeholderEditorText = {
    time: 1639061648418,
    blocks: [
      {
        id: 'I2nZ2V80la',
        type: 'header',
        data: {
          text: 'This is your job title',
          level: 2,
        },
      },
      {
        id: 'YcHd6AvzHY',
        type: 'paragraph',
        data: {
          text: 'This is the first paragraph of your job listing. You can add additional paragraphs by pressing the plus icon.',
        },
      },
      {
        id: 'bmgbyHCdIZ',
        type: 'paragraph',
        data: {
          text: 'You can also style your text, by pressing on it. You can make elements <b>bold</b>, <i>italic, </i>add <code class="inline-code">code examples</code><i>&nbsp;</i>or <mark class="cdx-marker">mark</mark> text. <i>W</i>e also support adding <a href="http://www.conversationdesigninsitute.com">links</a> to websites.',
        },
      },
      {
        id: 'RW0XHAEsFX',
        type: 'header',
        data: {
          text: 'You can also create sub headers for you job listing',
          level: 3,
        },
      },
    ],
    version: '2.22.2',
  }

  const [jobData, setJobData] = useState<Job>({
    name: '',
    job_description_short: '',
    job_description: placeholderEditorText,
    job_workload: 1,
    job_application_post_date: new Date(),
    job_application_end_date: new Date(),
    job_location: '',
    workplace: 'onsite',
    job_application_apply_link: '',
    job_country: countries[0],
    required_certificates: certificates,
    required_interfaces: interfaces,
    required_technologies: technologies,
    payment_status: 'pending',
    company: {
      name: '',
      company_logo_url: '',
      company_url: '',
      isShopDisabled: false,
      isMemberAreaDisabled: false,
      isJobboardDisabled: false
    },
  })

  const navigateTabs = async (increment = false) => {
    if (currentSection === 3) {
      await handleSave()
    }
    if (increment) {
      window.dataLayer.push({
        event: `Step ${currentSection + 1} completed`,
      })
    }

    increment ? setCurrentSection(currentSection + 1) : setCurrentSection(currentSection - 1)
  }

  const [currentSection, setCurrentSection] = useState<number>(0)
  const [lastClickedSection, setLastClickedSection] = useState<number>(0)

  const [paymentProgress, setPaymentProgress] = useState<boolean>(false)

  const paymentTypes: { type: PaymentType; text: string }[] = [
    { type: 'One-time', text: 'One-time payment of $149' },
    { type: 'Monthly', text: 'Monthly subscription of $99' },
    { type: 'Unlimited', text: 'Unlimited job posting for $ 399 per month' },
  ]
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType | undefined>(undefined)

  const getForm = () => {
    const section = sections[currentSection]

    if (section === 'Job details') {
      return JobDetails
    } else if (section === 'Company') {
      return CompanyInfoForm
    } else if (section === 'Requirements') {
      return RequirementsForm
    } else if (section === 'Description') {
      return JobDescription
    } else if (section === 'Preview') {
      return PreviewForm
    } else if (section === 'Payment') {
      return PaymentForm
    }
  }

  const [shouldSubmitJobDetails, setShouldSubmitJobDetails] = useState<boolean>(false)

  const saveJobDetails = (values: IJobDetailsForm) => {
    if (jobData.name === '' || jobData.company.name === '' || !shouldSubmitJobDetails) {
      navigateTabs(true)
      window.dataLayer.push({
        event: 'Step 1 completed',
      })
    } else {
      setCurrentSection(lastClickedSection)
    }
    setJobData({ ...jobData, ...values })
    setShouldSubmitJobDetails(false)
  }

  const JobDetails = (
    <JobDetailsForm
      onSubmitBtnClick={() => setShouldSubmitJobDetails(false)}
      triggerSubmit={shouldSubmitJobDetails}
      initialValues={jobData as IJobDetailsForm}
      onSubmit={saveJobDetails}
    />
  )

  const [shouldSubmitCompany, setShouldSubmitCompany] = useState<boolean>(false)

  const saveJobCompany = (values: IJobCompanyForm) => {
    window.dataLayer.push({
      event: 'Step 2 completed',
    })
    setJobData({ ...jobData, company: values })
    if (!shouldSubmitCompany) {
      navigateTabs(true)
    } else {
      setCurrentSection(lastClickedSection)
    }
    setShouldSubmitCompany(false)
  }

  const CompanyInfoForm = (
    <JobCompanyForm
      onSubmitBtnClick={() => setShouldSubmitCompany(false)}
      triggerSubmit={shouldSubmitCompany}
      initialValues={jobData.company}
      onSubmit={saveJobCompany}
      back={() => navigateTabs(false)}
    />
  )

  const ReactEditorJS = createReactEditorJS()
  const editorJS: any = React.useRef(null)
  const handleInitialize = React.useCallback((instance) => {
    editorJS.current = instance
  }, [])

  const handleSave = async () => {
    const savedData = await editorJS.current.save()
    setJobData({ ...jobData, job_description: savedData })
    window.dataLayer.push({
      event: 'Step 3 completed',
    })
  }

  const JobDescription = (
    <>
      <InputWrapper>
        <Text marginBottom={12} style="label5" color="primary1">
          Job Description
        </Text>
        <EditorWrapper>
          <ReactEditorJS defaultValue={jobData.job_description} tools={EDITOR_JS_TOOLS} onInitialize={handleInitialize} />
        </EditorWrapper>
      </InputWrapper>
    </>
  )

  const RequirementsForm = (
    <>
      <SkillsContainer selecting={true} />
    </>
  )
  const PreviewForm = (
    <>
      <PreviewWrapper>
        <JobView job={jobData} hideApplyLink={true} border={true}></JobView>
      </PreviewWrapper>
    </>
  )

  const saveJobToServer = async () => {
    setPaymentProgress(true)

    try {
      //TODO CHECK IF COMPANY EXISTS
      const createCompanyRes = (await createCompany(jobData.company as Company)) as Company
      console.log('company created', createCompanyRes)
      if (createCompanyRes && createCompanyRes.id) {
        try {
          const result: any = await createJob({
            ...jobData,
            user: user,
            job_description: JSON.stringify(jobData.job_description),
            company: createCompanyRes.id,
            payment_status: userHasUnlimitedSubscription ? 'payed' : 'pending',
            slug: formatSlug(jobData),
          })
          return result.id
        } catch (error) {
          console.log(error)
        } finally {
          setPaymentProgress(false)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setPaymentProgress(false)
      if (userHasUnlimitedSubscription) {
        appDispatch({
          type: 'updateShouldLoadJobs',
          payload: true,
        })
        history.push('/jobboard')
      }
    }
  }

  const openPayment = async () => {
    const jobId = await saveJobToServer()

    if (!jobId) {
      alert('Job creation failed, please try again or contact us at jobs@conversationdesigninstitute.com')
      return
    }

    console.log('job created', jobId)

    if (!userHasUnlimitedSubscription) {
      let newWindow: Window | null = null
      setPaymentProgress(true)
      if (selectedPaymentType === 'One-time') {
        newWindow = window.open(process.env.REACT_APP_SHOPIFY_LINK + `&checkout[email]=${user?.email}`, '_self')
      } else if (selectedPaymentType) {
        const response: any = await setupStripeService(selectedPaymentType, jobId)
        if (response.url) {
          newWindow = window.open(response.url, '_self')
        }
      }
      if (newWindow) newWindow.opener = null
    }
    window.dataLayer.push({
      event: 'Payment completed',
    })
  }

  //TODO GET ACTUAL AMOUNT FROM STRIPE
  const PaymentForm = (
    <PaymentWrapper>
      <Text marginBottom={15} style="label5" color="primary1">
        {`If you're ready to finalize and post your job, press the pay button below. You will be redirected to our payment portal where you can complete the
        payment. If the payments is succesfull, your job will be posted.`}
      </Text>
      {!userHasUnlimitedSubscription &&
        paymentTypes.map((paymentType, index) => (
          <Checkbox
            key={paymentType.type + index}
            checked={selectedPaymentType === paymentType.type}
            label={paymentType.text}
            onChange={(e) => setSelectedPaymentType(e.target.checked === true ? paymentType.type : undefined)}
          />
        ))}
    </PaymentWrapper>
  )

  const onHeaderClick = async (index: number) => {
    setLastClickedSection(index)
    if (currentSection === 0) {
      setShouldSubmitJobDetails(true)
    } else if (currentSection === 1) {
      setShouldSubmitCompany(true)
    } else if (currentSection === 3) {
      await handleSave()
      setCurrentSection(index)
    } else {
      setCurrentSection(index)
    }
  }

  return (
    <AppPage>
      <AppTop inverted={true}>
        <Text style="headline" color="primary1" marginBottom={4}>
          Post a Job
        </Text>
        <Text style="heading2" color="primary1">
          Five easy steps to post a job
        </Text>
      </AppTop>
      <StyledAppContainer>
        <MultiSections activeHeader={currentSection} onHeaderClick={(index) => onHeaderClick(index)} headers={sections} align={true}>
          {getForm()}
          <ButtonsContainer>
            {currentSection !== 0 && currentSection !== 1 && (
              <Button caption="Previous" variant={ButtonVariant.Secondary} onClick={() => navigateTabs(false)} />
            )}
            {currentSection !== 0 && currentSection !== 1 && (
              <Button
                disabled={currentSection === 5 && (paymentProgress || (!selectedPaymentType && !userHasUnlimitedSubscription))}
                showLoader={currentSection === 5 && paymentProgress}
                caption={currentSection === 5 ? (userHasUnlimitedSubscription ? 'Post Job' : 'Pay and Post Job') : 'Next'}
                onClick={() => (currentSection === 5 ? openPayment() : navigateTabs(true))}
              />
            )}
          </ButtonsContainer>
        </MultiSections>
      </StyledAppContainer>
    </AppPage>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-top: 40px;
  max-width: 760px;

  ${mqFrom.L`
    margin-top: 38px;
  `}
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 40px;
`

const PreviewWrapper = styled.div`
  .ce-block__content {
    max-width: unset;
  }
`

const InputWrapper = styled.div<{ marginBottom?: number }>`
  position: relative;
  display: block;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  width: 100%;
`

const EditorWrapper = styled.div<{ marginBottom?: number }>`
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  background-color: ${colors.neutralWhite};
  color: ${colors.primary1};
  min-height: 600px;
  ${applyTextStyle('heading4')}
  a {
    color: ${colors.primary1};
  }
`

const PaymentWrapper = styled.div``

export default PostJobPage
