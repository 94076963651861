import aitrainer from '../../images/certificates/aitrainer.svg'
import aitrainerLarge from '../../images/certificates/aitrainer.png'
import designer from '../../images/certificates/designer.svg'
import designerLarge from '../../images/certificates/designer.png'
import copywriter from '../../images/certificates/copywriter.svg'
import copywriterLarge from '../../images/certificates/copywriter.png'

export const certificates: Certificate[] = [
  {
    name: 'AI Trainer',
    img: aitrainer,
    imgLarge: aitrainerLarge,
    active: false,
    url: 'https://www.conversationdesigninstitute.com/courses/ai-trainer-certificate.html',
    certificate_price: '999',
    usps: [
      { id: '1', name: 'Industry-recognized' },
      { id: '2', name: 'Access to the CDI alumni network' },
      { id: '3', name: 'Exclusive alumni perks' },
    ],
  },
  {
    name: 'Conversation designer',
    img: designer,
    imgLarge: designerLarge,
    active: false,
    url: 'https://www.conversationdesigninstitute.com/courses/conversation-designer-certificate.html',
    certificate_price: '999',
    usps: [
      { id: '1', name: 'Industry-recognized' },
      { id: '2', name: 'Access to the CDI alumni network' },
      { id: '3', name: 'Exclusive alumni perks' },
    ],
  },
  {
    name: 'Conversational copywriter',
    img: copywriter,
    imgLarge: copywriterLarge,
    active: false,
    url: 'https://www.conversationdesigninstitute.com/courses/conversational-copywriter-certificate.html',
    certificate_price: '999',
    usps: [
      { id: '1', name: 'Industry-recognized' },
      { id: '2', name: 'Access to the CDI alumni network' },
      { id: '3', name: 'Exclusive alumni perks' },
    ],
  },
]
