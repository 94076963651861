import React from 'react'
import styled from 'styled-components'
import { ColSpanAmount } from '../common/models/grid'
import colors from '../styles/colors'
import { mqFrom } from '../styles/responsive'
import CourseCard from './cards/CourseCard'
import GridCol from './grid/GridCol'
import GridRow from './grid/GridRow'

interface IProps {
  className?: string
  courses?: Course[]
  xsColSpan?: ColSpanAmount
  mColSpan?: ColSpanAmount
  lColSpan?: ColSpanAmount
  small?: boolean
  all?: boolean
}

const CoursesList: React.FC<IProps> = ({ className, courses, xsColSpan, mColSpan, lColSpan, small, all }) => {
  return (
    <Wrapper className={className}>
      {!all && (
        <Courses>
          {small &&
            !all &&
            courses
              ?.filter((course) => course.enabled !== false)
              .map((course, index) => (
                <GridColStyledSmall l={lColSpan ? lColSpan : undefined} m={mColSpan ? mColSpan : undefined} xs={xsColSpan ? xsColSpan : undefined} key={index}>
                  <CourseCard course={course}></CourseCard>
                </GridColStyledSmall>
              ))}
          {!small &&
            !all &&
            courses
              ?.filter((course) => course.enabled !== false)
              .map((course, index) => (
                <GridColStyled l={lColSpan ? lColSpan : undefined} m={mColSpan ? mColSpan : undefined} xs={xsColSpan ? xsColSpan : undefined} key={index}>
                  <CourseCard course={course}></CourseCard>
                </GridColStyled>
              ))}
        </Courses>
      )}

      {all && (
        <CoursesGrid>
          {all &&
            courses
              ?.filter((course) => course.enabled !== false)
              .map((course, index) => (
                <GridColStyledGrid l={lColSpan ? lColSpan : undefined} m={mColSpan ? mColSpan : undefined} xs={xsColSpan ? xsColSpan : undefined} key={index}>
                  <CourseCard course={course}></CourseCard>
                </GridColStyledGrid>
              ))}
        </CoursesGrid>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
`

const Courses = styled(GridRow)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  min-height: 520px;

  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.transparent};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.transparent};
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.transparent};
  }
`

const CoursesGrid = styled(GridRow)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  ${mqFrom.M`
   flex-wrap: wrap;
  `}
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  min-height: 520px;

  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.transparent};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.transparent};
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.transparent};
  }
`

const GridColStyled = styled(GridCol)`
  max-width: 284px;
`

const GridColStyledSmall = styled(GridCol)`
  max-width: 243px;
`

const GridColStyledGrid = styled(GridCol)`
  margin-bottom: 50px;
`

export default CoursesList
