import React, { useReducer, createContext, useContext } from 'react'
import CookieBanner from '../components/CookieBanner'
import ModalWindow from '../components/ModalWindow'
import { getCookie } from '../helpers/cookie.helper'
import GlobalStateDataFetch from './GlobalStateDataFetch'

// Types
export interface ILoadingState {
  shouldLoadUser: boolean
  shouldLoadJobs: boolean
}

export interface ILoadedState {
  isUserLoaded: boolean
}

export type State = {
  loadingState: ILoadingState
  userAuthToken: string | undefined
  user: User | undefined
  courses: Course[] | undefined
  modalWindow: ModalWindow | undefined
  jobs: Job[] | undefined
  enrollements: Enrollement[] | undefined
  enrollmentsLoading: boolean
  posts: Post[] | undefined
  shouldOpenTour: boolean | undefined
  events: CdiEvent[] | undefined
  notifications: Notification[] | undefined
  loadedState: ILoadedState
}

export const DEFAULT_APP_STATE: State = {
  loadingState: {
    shouldLoadUser: false,
    shouldLoadJobs: true,
  },
  userAuthToken: getCookie('strapi_jwt') || undefined,
  user: undefined,
  courses: undefined,
  modalWindow: undefined,
  jobs: [],
  enrollements: [],
  enrollmentsLoading: true,
  posts: [],
  shouldOpenTour: false,
  events: [],
  notifications: [],
  loadedState: {
    isUserLoaded: false
  }
}

export type updateUserTokenAction = {
  type: 'updateUserToken'
  payload?: string
}

export type updateUserAction = {
  type: 'updateUser'
  payload?: User
}

export type updateCoursesAction = {
  type: 'updateCourses'
  payload?: Course[]
}

export type updateShouldLoadUserAction = {
  type: 'updateShouldLoadUser'
  payload: boolean
}

export type updateShouldLoadJobsAction = {
  type: 'updateShouldLoadJobs'
  payload: boolean
}

export type updateEnrollmentsLoadingAction = {
  type: 'updateEnrollmentsLoading'
  payload: boolean
}

export type updateModalWindowAction = {
  type: 'updateModalWindow'
  payload?: ModalWindow
}

export type updateJobsAction = {
  type: 'updateJobs'
  payload?: Job[]
}

export type updateEnrollement = {
  type: 'updateEnrollments'
  payload?: Enrollement[]
}

export type updateNotifications = {
  type: 'updateNotifications'
  payload?: Notification[]
}

export type updatePosts = {
  type: 'updatePosts'
  payload?: Post[]
}

export type updateShouldOpenTourAction = {
  type: 'updateShouldOpenTour'
  payload?: boolean
}

export type updateEventsAction = {
  type: 'updateEvents'
  payload?: CdiEvent[]
}

export type updateIsUserLoadedAction = {
  type: 'updateIsUserLoaded'
  payload: boolean
}

export type Action =
  | updateUserTokenAction
  | updateUserAction
  | updateCoursesAction
  | updateShouldLoadUserAction
  | updateShouldLoadJobsAction
  | updateEnrollmentsLoadingAction
  | updateModalWindowAction
  | updateJobsAction
  | updateEnrollement
  | updateNotifications
  | updatePosts
  | updateShouldOpenTourAction
  | updateEventsAction
  | updateIsUserLoadedAction

export type Dispatch = (action: Action) => void

// Contexts
export const AppStateCtx = createContext<State>({ ...DEFAULT_APP_STATE })

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AppDispatchCtx = createContext<Dispatch>((_: Action) => {
  return
})

// Reducer
export const appReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'updateUserToken': {
      return {
        ...state,
        userAuthToken: action.payload,
      }
    }
    case 'updateUser': {
      return {
        ...state,
        user: action.payload,
      }
    }
    case 'updateCourses': {
      return {
        ...state,
        courses: action.payload,
      }
    }
    case 'updateModalWindow': {
      return {
        ...state,
        modalWindow: action.payload,
      }
    }
    case 'updateShouldLoadUser': {
      return {
        ...state,
        loadingState: { ...state.loadingState, shouldLoadUser: action.payload },
      }
    }
    case 'updateShouldLoadJobs': {
      return {
        ...state,
        loadingState: { ...state.loadingState, shouldLoadJobs: action.payload },
      }
    }
    case 'updateEnrollmentsLoading': {
      return {
        ...state,
        enrollmentsLoading: action.payload,
      }
    }
    case 'updateJobs': {
      return {
        ...state,
        jobs: action.payload,
      }
    }
    case 'updateEnrollments': {
      return {
        ...state,
        enrollements: action.payload,
      }
    }
    case 'updateNotifications': {
      return {
        ...state,
        notifications: action.payload,
      }
    }
    case 'updatePosts': {
      return {
        ...state,
        posts: action.payload,
      }
    }
    case 'updateShouldOpenTour': {
      return {
        ...state,
        shouldOpenTour: action.payload,
      }
    }
    case 'updateEvents': {
      return {
        ...state,
        events: action.payload,
      }
    }
    case 'updateIsUserLoaded': {
      return {
        ...state,
        loadedState: { ...state.loadedState, isUserLoaded: action.payload },
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

// Hooks
export const useAppState = () => {
  const context = useContext(AppStateCtx)

  if (!context) {
    throw new Error('useAppState must be used within a AppProvider')
  }

  return context
}

export const useAppDispatch = () => {
  const context = useContext(AppDispatchCtx)

  if (!context) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }

  return context
}

export const ContextTemplate: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, { ...DEFAULT_APP_STATE })

  return (
    <AppStateCtx.Provider value={state}>
      <AppDispatchCtx.Provider value={dispatch}>
        <CookieBanner />
        <GlobalStateDataFetch />
        {state.modalWindow && <ModalWindow content={state.modalWindow} />}
        <>{children}</>
      </AppDispatchCtx.Provider>
    </AppStateCtx.Provider>
  )
}
