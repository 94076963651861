import React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/colors'
import { applyTextStyle } from '../../../styles/typography'
import Text from '../../Text'
import Icon from '../../icon/Icon'
import { FormControlError } from './FormControlError'
import { FormControlProps } from '../../../common/models/FormControlProps'

export type InputType = 'text' | 'password'

export interface IProps extends FormControlProps {
  children?: React.ReactNode
  withBorder?: boolean
}

const Dropdown: React.FC<IProps> = ({ className, label, onChange, marginBottom, children, withBorder, ...props }) => {
  const errorMessage = props.meta && props.meta.error && props.meta.touched ? props.meta.error : props.errorMessage
  return (
    <DropdownWrapper className={className} marginBottom={marginBottom}>
      {label && (
        <Text marginBottom={12} style="label5" color="primary1">
          {label}
        </Text>
      )}
      <DropdownInnerWrapper>
        <StyledIconDropdown {...props} type="ArrowDown" color={colors.tertiary1} size={24} />
        <StyledSelect {...props} withBorder={withBorder} onChange={(e) => (onChange ? onChange(e) : undefined)}>
          {children}
        </StyledSelect>
        <FormControlError>{errorMessage}</FormControlError>
      </DropdownInnerWrapper>
    </DropdownWrapper>
  )
}

const DropdownWrapper = styled.div<{ marginBottom?: number }>`
  position: relative;
  display: block;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  width: 100%;
`

const DropdownInnerWrapper = styled.div`
  position: relative;
`

export const StyledSelect = styled.select<{ withBorder?: boolean }>`
  display: block;
  width: 100%;
  background-color: ${colors.neutralWhite};
  outline: 0;
  border: ${({ withBorder }) => (withBorder ? `1px solid ${colors.primary1}` : 'none')};
  padding: 15px 19px;
  ${applyTextStyle('label4')};
  color: ${colors.secondary1};
  appearance: none;
`

export const StyledIconDropdown = styled(Icon)`
  position: absolute;
  right: 12px;
  top: 15px;
  pointer-events: none;
`

export default Dropdown
